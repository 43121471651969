import { Tabs, Tooltip } from 'antd';
import React from 'react';
import { PaymentList } from './PaymentList';
import { PageTitle } from 'components/_common/PageTitle';
import { PaymentOverview } from './PaymentOverview';

export const Payments = () => {
    return (
        <>
            <PageTitle>Payments</PageTitle>
            <Tabs 
                defaultActiveKey="1" 
                type="card"
                size='small'
                className='blue-tabs'
                tabBarStyle={{ marginBottom: 0, marginLeft: 20}}
                items={[
                {  
                    key: '1',
                    label: <Tooltip placement="topLeft" title='A list of all approved and running orders with their payment status'>Overview</Tooltip>,
                    children: <PaymentOverview />
                },
                {  
                    key: '2',
                    label: <Tooltip placement="topLeft" title='A list of all payments made.'>List</Tooltip>,
                    children: <PaymentList />
                }
            ]} />
        </>
    )
}