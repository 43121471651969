import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Space, Table, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { PageTitle } from 'components/_common/PageTitle';
import { CategoryTreeMenu } from 'components/_common/CategoryTreeMenu';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter';

interface State {
    selectedParentCategoryId?: string
}

export const ProductCategoryList = () => {
   
    const [state, setState] = useState<State>({
        selectedParentCategoryId: undefined
    })

    const categoryFetcher = MultiFetcher('productcategory')
    const parentCategoryFetcher = MultiFetcher('productcategory')

    const initialize = useCallback(() => {
        parentCategoryFetcher.get({ })
        setState({ selectedParentCategoryId: undefined })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        initialize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        categoryFetcher.get({ queryParams: state.selectedParentCategoryId ? `parentCategoryId=${state.selectedParentCategoryId}` : `rootCategoriesOnly=true` })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const onParentCategoryChange = (value: string) => {
        setState(prevState => ({ ...prevState, selectedParentCategoryId: value }));
    }

    const deleteItem = async (id: number) => {
        categoryFetcher.del(id).then(async (success) => {
            if (success === true) {
                initialize()
                message.info(`Category was deleted.`)
            }
        })
    }

    return (
        <>
            <PageTitle>Product Categories</PageTitle>

            <Layout>
                <Sider width='25%' style={{ backgroundColor: '#F5F5F5' }}>
                    <CategoryTreeMenu categories={parentCategoryFetcher.data} loading={parentCategoryFetcher.isLoading} onChange={onParentCategoryChange}  />
                </Sider>
                <Content style={{ marginLeft: '20px', width: '75%'}}>

                <Table
                    loading={categoryFetcher.isLoading}
                    rowKey={'id'}
                    size='small'
                    pagination={{ defaultPageSize: 12, size: 'default' }}
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: (a:any,b:any) => a.name.localeCompare(b.name),
                            ...ColumnTextFilter('name')
                        },
                        {
                            title: 'Preferred Supplier',
                            dataIndex: 'preferredSupplierName',
                            key: 'preferredsupplier',
                            sorter: (a:any,b:any) => a.preferredSupplierName?.localeCompare(b.preferredSupplierName),
                            ...ColumnTextFilter('preferredSupplierName')
                        },
                        {
                            key: 'actions',
                            align: 'right',
                            title: () => <TableRowButton buttontype='Add' url='/productcategory/edit/' entity='Category' />,
                            render: (record: any) =>
                                <div>
                                    <Space>
                                        <TableRowButton buttontype='Edit' recordid={record.id} url={`/productcategory/edit`} entity='Category' />
                                        <TableRowButton buttontype='Delete' recordid={record.id} onClick={deleteItem} entity='Category' hasconfirm />
                                    </Space>
                                </div>
                        }
                    ]}
                    dataSource={categoryFetcher.data} 
                    style={{ paddingBottom: '10px'}} 
                    className='table-striped-rows' />
                </Content>
            </Layout>

        </>
    )
}