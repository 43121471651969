import { Form, Input, message } from 'antd';
import { LengthInput } from 'components/_common/LengthInput';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { IsChangedOuterContext } from 'components/_common/OuterModal';
import { PageLoader } from 'components/_common/PageLoader';
import React, { useContext, useEffect } from 'react';

interface Props {
    boxId?: number
    onClose(): void
    onRefreshAndSelect(boxId?: number): void
}

export const BoxDetails = (props: Props) => {
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    const fetcher = MultiFetcher('box');   

    const onSubmit = async (data: any) => {
        const submitAsync = async (data: any):Promise<number> => {
            return props.boxId ? await fetcher.put(data) : fetcher.post(data);
        }

        await submitAsync(data).then((result) => {
            //console.log(`result: [${result}]`)
            if (result > 0) { 
                props.onClose()
                props.onRefreshAndSelect(result)
                message.info(`Box was ${props.boxId ? 'updated' : 'created'}.`)
            }
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (props.boxId) fetcher.get({ id: props.boxId.toString() })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <PageLoader loading={fetcher.isLoading}>
            <Form 
                form={form} 
                initialValues={fetcher.data}
                onFinish={onSubmit} 
                onValuesChange={() => setIsChanged(true)} 
                layout='horizontal'
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={{ padding: '10px'}}>
                    <Form.Item name='id' hidden={true} />
                    <Form.Item label="Length" name="length" rules={[{ required: true, message: 'Length is required.' }]}>
                        <LengthInput />
                    </Form.Item>
                    <Form.Item label="Width" name="width" dependencies={['length']} rules={[
                        { required: true, message: 'Width is required.' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('length') >= value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Width should not be larger than Length!'));
                            },
                          }),
                        ]}>
                        <LengthInput />
                    </Form.Item>
                    <Form.Item label="Height" name="height" rules={[{ required: true, message: 'Height is required.' }]}>
                        <LengthInput />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                        <Input maxLength={50} />
                    </Form.Item>
                    <button ref={submitRef} type="submit" className='hide' />
            </Form>
        </PageLoader>
    )
}