import React, { useContext, useEffect } from 'react'
import { Col, Form, Row } from 'antd'
import { CustomSelect } from 'components/_common/CustomSelect';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import _ from 'lodash';
import { IsChangedOuterContext } from 'components/_common/OuterModal';

interface Props {
    onClose(): void
    onFinish(value: any): void
}

export const GenerateVariations = (props: Props) => {   
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    const sizeFetcher = MultiFetcher('productsize')
    const threadTypeFetcher = MultiFetcher('productthreadtype')
    const tempFetcher = MultiFetcher('producttemperature')

    useEffect(() => {
        window.scrollTo(0, 0)

        sizeFetcher.get({})
        threadTypeFetcher.get({})
        tempFetcher.get({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = (value: any) => {
        //console.log(`value: ${JSON.stringify(value)}`)
        const variations: { }[] = []
        
        _.forEach(value.size1Ids ?? [null], (size1Id: any) => {
            _.forEach(value.size2Ids ?? [null], (size2Id: any) => {
                _.forEach(value.threadtype1Ids ?? [null], (threadType1Id: any) => {
                    _.forEach(value.threadtype2Ids ?? [null], (threadType2Id: any) => {
                        _.forEach(value.temperatureIds ?? [null], (temperatureId: any) => {
                            variations.push({ size1Id, size2Id, threadType1Id, threadType2Id, temperatureId })
                        })
                    })
                })
            })
        })

        props.onFinish(variations)
    }

    return (
        <Form 
            layout='horizontal'
            labelCol={{ span: 6, xl: 7}}
            wrapperCol={{ span: 18, xl: 15}}
            style={{ padding: '10px'}}
            form={form}
            onFinish={onSubmit}
            onValuesChange={() => setIsChanged(true)} >
                <Row>
                    <Col xl={12} span={24}>
                        <Form.Item label="Sizes1 (mm)" name="size1Ids">
                            <CustomSelect data={sizeFetcher.data} loading={sizeFetcher.isLoading} mode='multiple' displayfieldname='sizeInMm' style={{ width: 150 }} />
                        </Form.Item>
                    </Col>
                    <Col xl={12} span={24}>
                        <Form.Item label="Sizes2 (mm)" name="size2Ids">
                            <CustomSelect data={sizeFetcher.data} loading={sizeFetcher.isLoading} mode='multiple' displayfieldname='sizeInMm' style={{ width: 150 }} />
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row>
                <Col xl={12} span={24}>
                        <Form.Item label="ThreadTypes1" name="threadtype1Ids">
                        <CustomSelect data={threadTypeFetcher.data} loading={threadTypeFetcher.isLoading} mode='multiple' style={{ width: 150 }} />
                        </Form.Item>
                    </Col>
                    <Col xl={12} span={24}>
                        <Form.Item label="ThreadTypes2" name="threadtype2Ids">
                        <CustomSelect data={threadTypeFetcher.data} loading={threadTypeFetcher.isLoading} mode='multiple' style={{ width: 150 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} span={24}>
                        <Form.Item label="Temperature" name="temperatureIds" >
                        <CustomSelect data={tempFetcher.data} loading={tempFetcher.isLoading} mode='multiple' style={{ width: 150 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <button ref={submitRef} type="submit" className='hide' />
        </Form>
    )
}