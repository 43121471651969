import React, { RefObject, useEffect, useRef, useState } from 'react'
import { Modal, ModalProps } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CustomButton, CustomButtonProps } from './CustomButtons';

interface InnerModalProps extends ModalProps {
    fullScreen?: boolean
    minWidth?: number
    onClose(): void
    saveButtonProps?: CustomButtonProps
    closeButtonProps?: CustomButtonProps
}

export const IsChangedInnerContext = React.createContext({
    isChanged: false,
    setIsChanged: (value: boolean) => {},
    submitRef: {} as RefObject<HTMLButtonElement>
  })

export const InnerModal = (props: InnerModalProps) => {
    const [isChanged, setIsChanged] = useState(false)
    const submitRef = useRef<HTMLButtonElement>(null)

    const { confirm } = Modal

    useEffect(() => {
        props.open && setIsChanged(false)
    }, [props.open])

    const onConfirmCancel = () => {
        confirm({
            title: "Warning",
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <div style={{ marginBottom: 10}}>Are you sure you want to close the input form?</div>
                    <span><b>Your changes will be discarded!</b></span>
                    <br/>
                </>),
            onOk() { props.onClose() },
            onCancel() { return },
            okText: 'Yes',
            cancelText: 'No',
            wrapClassName: 'z-index-9999'
        })
    }

    const onTryClose = () => isChanged ? onConfirmCancel() : props.onClose()

    return props.open ?
        <Modal
            {...props}
            title={props.title}
            onCancel={onTryClose}
            maskClosable={false}
            destroyOnClose
            //afterOpenChange={() => setIsChanged(false)}
            width={props.fullScreen === true ? 'calc(100% - 80px)' : props.width}
            style={props.fullScreen === true ? { top: 40, bottom: 40, minWidth: props.minWidth } : props.style}
            footer={
                <>
                    <CustomButton 
                        {...props.closeButtonProps} 
                        buttontype='Close' 
                        onClick={onTryClose} />
                    <CustomButton 
                        {...props.saveButtonProps} 
                        buttontype={props.saveButtonProps?.buttontype ?? 'Save'} 
                        onClick={() => submitRef.current?.click()} 
                        children={props.saveButtonProps?.children} 
                        disabled={props.saveButtonProps?.disabled ?? !isChanged}/>
                </>
            }
            >
                <IsChangedInnerContext.Provider value={{ isChanged, setIsChanged, submitRef }}>
                    <div style={{ maxHeight: 'calc(100vh - 250px)', overflow: 'auto', paddingRight: 5}}>
                        {props.children}
                    </div>
                </IsChangedInnerContext.Provider>
        </Modal>
        : <></>
} 