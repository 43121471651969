import React, { useEffect } from 'react';
import { Space, Table, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { PageTitle } from 'components/_common/PageTitle';
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter';

export const UserList = () => {
    const fetcher = MultiFetcher('user');   

    useEffect(() => {
        window.scrollTo(0, 0);
        fetcher.get({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteItem = async (id: number) => {
        fetcher.del(id).then(async (success) => {
            if (success === true) {
                await fetcher.get({})
                message.info(`User was deleted.`)
            }
        })
    }

    return (
        <>
            <PageTitle>Users</PageTitle>

            <Table
                loading={fetcher.isLoading}
                rowKey={'id'}
                size='small'
                pagination={{ defaultPageSize: 12, size: 'default' }}
                columns={[
                    {
                        title: 'Username',
                        dataIndex: 'username',
                        key: 'username',
                        sorter: (a:any,b:any) => a.username.localeCompare(b.username),
                        ...ColumnTextFilter('username')
                    },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                        sorter: (a:any,b:any) => a.email.localeCompare(b.email),
                        ...ColumnTextFilter('email')
                    },
                    {
                        title: 'Role',
                        dataIndex: 'role',
                        key: 'role',
                        sorter: (a:any,b:any) => a.role?.localeCompare(b.role),
                        ...ColumnTextFilter('role')
                    },
                    {
                        title: 'Customer',
                        dataIndex: 'customerName',
                        key: 'customerName',
                        sorter: (a:any,b:any) => a.customerName?.localeCompare(b.customerName),
                        ...ColumnTextFilter('customerName')
                    },
                    {
                        title: 'Supplier',
                        dataIndex: 'supplierName',
                        key: 'supplierName',
                        sorter: (a:any,b:any) => a.supplierName?.localeCompare(b.supplierName),
                        ...ColumnTextFilter('supplierName')
                    },
                    {
                        title: 'Is Locked out',
                        dataIndex: 'islockedout',
                        key: 'islockedout',
                        sorter: (a:any,b:any) => a.islockedout?.localeCompare(b.islockedout),
                        ...ColumnTextFilter('islockedout')
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        title: () => <TableRowButton buttontype='Add' url='/user/edit/' entity='User' />,
                        render: (record: any) =>
                            <div>
                                <Space>
                                    <TableRowButton buttontype='Edit' recordid={record.id} url={`/user/edit`} entity='User' />
                                    <TableRowButton buttontype='Delete' recordid={record.id} onClick={deleteItem} entity='User' hasconfirm />
                                </Space>
                            </div>
                    }

                ]}
                dataSource={fetcher.data} 
                style={{ paddingBottom: '10px'}} 
                className='table-striped-rows' /> 
        </> 
    )
}