/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Table } from 'antd'
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { FormatCurrency, FormatPercentage } from 'components/_common/Formatters';
import { numberCompare } from 'components/_common/Generic';
import { calculateCustomerPrice } from 'components/_common/Calculators';

interface Props {
   productId: number
}

export const VariationPrices = (props:Props) => {
    const variationFetcher = MultiFetcher('productVariation')
    
    useEffect(() => {
        window.scrollTo(0, 0)
        variationFetcher.get({ actionName: 'getAllWithPrices', queryParams: `productId=${props.productId}`})
    }, [])

    return (
        <Table
            dataSource={variationFetcher.data} 
            loading={variationFetcher.isLoading}
            rowKey='id'
            size='small'
            bordered
            pagination={false}
            rowClassName={(record: any, index) =>  index % 2 === 1 ? 'table-striped-row' :  ''}
            columns={[
                {
                    title: 'Part No',
                    dataIndex: 'partNumber',
                    key: 'partNumber',
                    sorter: (a:any,b:any) => a.partNumber?.localeCompare(b.partNumber, undefined, { numeric: true, sensitivity: 'base' }),
                },
                {
                    title: 'Drawing No',
                    dataIndex: 'drawingNumber',
                    key: 'drawingNumber',
                    sorter: (a:any,b:any) => a.drawingNumber?.localeCompare(b.drawingNumber, undefined, { numeric: true, sensitivity: 'base' }),
                },
                { 
                    title: 'Size (mm)',
                    children: [
                        {
                            title: '1',
                            dataIndex: 'size1InMm',
                            key: 'size1',
                            align: 'right',
                            sorter: (a:any,b:any) => numberCompare(a.size1InMm, b.size1InMm)
                        },
                        {
                            title: '2',
                            dataIndex: 'size2InMm',
                            key: 'size2',
                            align: 'right',
                            sorter: (a:any,b:any) => numberCompare(a.size2InMm, b.size2InMm)
                        },
                    ]
                },
                { 
                    title: 'ThreadType',
                    children: [
                        {
                            title: 1,
                            dataIndex: 'threadType1Name',
                            key: 'threadType1',
                            align: 'right',
                            sorter: (a:any,b:any) => (a.threadType1Name?.localeCompare(b.threadType1Name))
                        },
                        
                        {
                            title: 2,
                            dataIndex: 'threadType2Name',
                            key: 'threadType2',
                            align: 'right',
                            sorter: (a:any,b:any) => (a.threadType2Name?.localeCompare(b.threadType2Name))
                        },
                    ]
                },
                {
                    title: 'Temp.',
                    dataIndex: 'temperatureName',
                    key: 'temperature',
                    sorter: (a:any,b:any) => a.temperatureName?.localeCompare(b.temperatureName),
                    responsive: ['xl']
                },
                { 
                    title: 'Gupta Price',
                    children: [
                        {
                            title: 'w/o Setup',
                            dataIndex: 'guptaPriceWithoutSetup',
                            key: 'guptaPriceWithoutSetup',
                            align: 'right',
                            className: 'nowrap',
                            width: 75,
                            render: (value) => FormatCurrency(value)
                        },
                        {
                            title: 'w/ Setup',
                            dataIndex: 'guptaPriceWithSetup',
                            key: 'guptaPriceWithSetup',
                            align: 'right',
                            className: 'nowrap',
                            width: 75,
                            render: (value) => FormatCurrency(value)
                        },
                    ]
                },
                { 
                    title: 'Unit Price',
                    children: [
                    {
                        title: 'Supplier',
                        dataIndex: ['lastPrice', 'supplierUnitPrice'],
                        key: 'supplierUnitPrice',
                        align: 'right',
                        className: 'nowrap',
                        width: 75,
                        render: (value) => FormatCurrency(value)
                    },
                    {
                        title: 'Margin',
                        dataIndex: ['lastMargin', 'unitPriceMargin'],
                        key: 'unitPriceMargin',
                        align: 'right',
                        className: 'nowrap',
                        width: 85,
                        render: (value) => FormatPercentage(value)
                    },
                    {
                        title: 'Customer',
                        key: 'customerUnitPrice',
                        align: 'right',
                        className: 'nowrap',
                        width: 75,
                        render: (record) => record.lastPrice ? FormatCurrency(calculateCustomerPrice(record.lastPrice?.supplierUnitPrice, record.lastMargin?.unitPriceMargin)) : ''
                    }]
                },
                { 
                    title: 'Setup Cost',
                    children: [
                    {
                        title: 'Supplier',
                        dataIndex: ['lastPrice', 'supplierSetupCost'],
                        key: 'supplierSetupCost',
                        align: 'right',
                        className: 'nowrap',
                        width: 75,
                        render: (value) => FormatCurrency(value)
                    },
                    {
                        title: 'Margin',
                        dataIndex: ['lastMargin', 'setupCostMargin'],
                        key: 'setupCostMargin',
                        align: 'right',
                        className: 'nowrap',
                        width: 85,
                        render: (value) => FormatPercentage(value)
                    },
                    {
                        title: 'Customer',
                        key: 'customerSetupCost',
                        align: 'right',
                        className: 'nowrap',
                        width: 75,
                        render: (record) => record.lastPrice ? FormatCurrency(calculateCustomerPrice(record.lastPrice?.supplierSetupCost, record.lastMargin?.setupCostMargin)) : ''
                    }]
                },
                { 
                    title: 'Mold Cost',
                    children: [
                    {
                        title: 'Supplier',
                        dataIndex: ['lastPrice', 'supplierMoldCost'],
                        key: 'supplierMoldCost',
                        align: 'right',
                        className: 'nowrap',
                        width: 75,
                        render: (value) => FormatCurrency(value)
                    },
                    {
                        title: 'Margin',
                        dataIndex: ['lastMargin', 'moldCostMargin'],
                        key: 'moldCostMargin',
                        align: 'right',
                        className: 'nowrap',
                        width: 85,
                        render: (value) => FormatPercentage(value)
                    },
                    {
                        title: 'Customer',
                        key: 'customerMoldCost',
                        align: 'right',
                        className: 'nowrap',
                        width: 75,
                        render: (record) => record.lastPrice ? FormatCurrency(calculateCustomerPrice(record.lastPrice?.supplierMoldCost, record.lastMargin?.moldCostMargin)) : ''
                    }]
                }
            ]}
        />
    )
}