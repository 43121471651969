import React, { useContext, useEffect, useState } from 'react'
import { Col, Form, FormInstance, Input, InputNumber, Row, Switch, Table, Tooltip } from 'antd'
import { ExpandButton, TableRowButton } from 'components/_common/CustomButtons'
import { PriceHistory } from 'components/Pages/QuoteRequests/PriceHistory'
import * as _ from 'lodash';
import { FormatCurrency, FormatVariationProperties } from 'components/_common/Formatters'
import { Key } from 'antd/es/table/interface';
import { PageSubTitle } from 'components/_common/PageTitle';
import { ImageDownloadLink } from 'components/_common/ImageDownloadLink';
import { IsChangedOuterContext } from 'components/_common/OuterModal';
import { MoneyInput } from 'components/_common/MoneyInput';
import { InnerModal } from 'components/_common/InnerModal';
import { ColorDisplay } from 'components/_common/ColorDisplay';

interface Props {
    isLoading: boolean
    finish: boolean
    orderLines: any
    previousOrderLines: any
    form: FormInstance<any>
    supplierId: number
    supplierName: string
}

interface State {
    expandedKeys: Key[]
    showLast: boolean
    showEllipsis: boolean
    showHistoryModal: boolean
    selectedVariationId?: number
    selectedPartNumber?: string
}

export const QuoteRequestLineList = (props:Props) => {
    const [state, setState] = useState<State>({
        expandedKeys: [],
        showLast: true,
        showEllipsis: false,
        showHistoryModal: false,
        selectedVariationId: undefined,
        selectedPartNumber: undefined,
    })

    const { setIsChanged } = useContext(IsChangedOuterContext)

    useEffect(() => {
        //console.log(`resetting Form values...`)
        props.orderLines?.forEach(orderLine => {
            props.form.setFieldValue(`moq_${orderLine.id}`, orderLine.quotePrice?.minimumOrderQuantity)
            props.form.setFieldValue(`unitPrice_${orderLine.id}`, orderLine.quotePrice?.supplierUnitPrice)
            props.form.setFieldValue(`setupCost_${orderLine.id}`, orderLine.quotePrice?.supplierSetupCost)
            props.form.setFieldValue(`moldCost_${orderLine.id}`, orderLine.quotePrice?.supplierMoldCost)
        })
        setState(prevState => ({ ...prevState, expandedKeys: props.orderLines?.map(p => p.lineNumber) }))
    }, [props.form, props.orderLines])

    const onCopyAllPrevious = async() => {
        _.forEach(props.orderLines, orderLine => {
            const lastPrice = orderLine.productVariation.lastPrice

            props.form.setFieldValue(`moq_${orderLine.id}`, lastPrice?.minimumOrderQuantity)
            props.form.setFieldValue(`unitPrice_${orderLine.id}`, lastPrice?.supplierUnitPrice)
            props.form.setFieldValue(`setupCost_${orderLine.id}`, lastPrice?.supplierSetupCost)
            props.form.setFieldValue(`moldCost_${orderLine.id}`, lastPrice?.supplierMoldCost)
        })
        setIsChanged(true)
    }

    const onCopyRowPrevious = async(id: number) => {
        const orderLine = _.find(props.orderLines, p => p.id === id)
        const lastPrice = orderLine.productVariation.lastPrice

        props.form.setFieldValue(`moq_${id}`, lastPrice?.minimumOrderQuantity)
        props.form.setFieldValue(`unitPrice_${id}`, lastPrice?.supplierUnitPrice)
        props.form.setFieldValue(`setupCost_${id}`, lastPrice?.supplierSetupCost)
        props.form.setFieldValue(`moldCost_${id}`, lastPrice?.supplierMoldCost)

        setIsChanged(true)
    }

    const onCopyColumnPreviousMoQ = async() => {
        _.forEach(props.orderLines, orderLine => {
            const lastPrice = orderLine.productVariation.lastPrice
            props.form.setFieldValue(`moq_${orderLine.id}`, lastPrice?.minimumOrderQuantity)
        })
        setIsChanged(true)
    }

    const onCopyColumnPreviousUnitPrice = async() => {
        _.forEach(props.orderLines, orderLine => {
            const lastPrice = orderLine.productVariation.lastPrice
            props.form.setFieldValue(`unitPrice_${orderLine.id}`, lastPrice?.supplierUnitPrice)
        })
        setIsChanged(true)
    }

    const onCopyColumnPreviousSetupCost = async() => {
        _.forEach(props.orderLines, orderLine => {
            const lastPrice = orderLine.productVariation.lastPrice
            props.form.setFieldValue(`setupCost_${orderLine.id}`, lastPrice?.supplierSetupCost)
        })
        setIsChanged(true)
    }

    const onCopyColumnPreviousMoldCost = async() => {
        _.forEach(props.orderLines, orderLine => {
            const lastPrice = orderLine.productVariation.lastPrice
            props.form.setFieldValue(`moldCost_${orderLine.id}`, lastPrice?.supplierMoldCost)
        })
        setIsChanged(true)
    }

    const showVariationHistory = (variationId: number, partNumber: string) => {
        setState(prevState => ({ ...prevState, showHistoryModal: true, selectedVariationId: variationId, selectedPartNumber: partNumber }))
    }

    const getLastQuantity = (record: any) => {
        if ('totalQuantity' in record){
            // OrderLine
            const lastOrderLine = _.find(props.previousOrderLines, p => p.productVariationId === record.productVariationId)
            return lastOrderLine?.totalQuantity
        } else {
            // OrderLineDetail
            const orderLine = _.find(props.orderLines, p => p.id === record.orderLineId)
            const lastOrderLine = _.find(props.previousOrderLines, p => p.productVariationId === orderLine.productVariationId)
            const lastOrderLineDetail = _.find(lastOrderLine?.orderLineDetails, p => p.productColorId === record.productColorId)
            return lastOrderLineDetail?.quantity
        }
    }

    const isDifferentFromLastQty = (record: any) => {
        const lastQuantity = getLastQuantity(record)
        return 'totalQuantity' in record 
            ? record.totalQuantity !== lastQuantity
            : record.quantity !== lastQuantity
    }

    const onToggleExpanded = (lineNumber: number) => {
        const expandedKeys = state.expandedKeys.slice(0)

        if(expandedKeys.includes(lineNumber)){
            var index = expandedKeys.indexOf(lineNumber)
            expandedKeys.splice(index, 1)
        } else {
            expandedKeys.push(lineNumber)
        }

        setState(prevState => ({ ...prevState, expandedKeys }))
    }

    const onToggleAllExpanded = () => {
        let expandedKeys = state.expandedKeys.slice(0)

        if(expandedKeys.length > 0){
            expandedKeys = []
        } else {
            expandedKeys = props.orderLines?.map(p => p.lineNumber)
        }

        setState(prevState => ({ ...prevState, expandedKeys }))
    }

    return (
        <>
            <Row>
                <Col xxl={18} xl={16} span={12}>
                    <PageSubTitle>Order Lines</PageSubTitle>
                </Col>
                <Col xxl={3} xl={4} span={6}>
                    <div style={{ position: 'absolute', bottom: 0, right: 5 }}>
                        <Form.Item label="Show Last Values" name='showLast' style={{ margin: 5}}>
                            <Switch size='small' checked={state.showLast} title='Show Last Values' onChange={() => setState(prevState => ({ ...prevState, showLast: !state.showLast }))} />
                        </Form.Item>
                    </div>
                </Col>
                <Col xxl={3} xl={4} span={6}>
                    <div style={{ position: 'absolute', bottom: 0, right: 5 }}>
                        <Form.Item label="Compressed Text" name='showEllipsis' style={{ margin: 5}}>
                            <Switch size='small' checked={state.showEllipsis} title='Show Elliipsis' onChange={() => setState(prevState => ({ ...prevState, showEllipsis: !state.showEllipsis }))} />
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            <Table
                loading={props.isLoading}
                rowKey={record => record.lineNumber ?? `color_${record.id}`}
                size='small'  
                style={{ width: 'auto', paddingBottom: '10px'}}
                bordered
                pagination={false}
                expandable={{
                    expandRowByClick: false,
                    childrenColumnName: 'orderLineDetails',
                    expandedRowKeys: state.expandedKeys,
                    showExpandColumn: false
                }}
                columns={[
                    {
                        title: <div style={{ }}>
                                    <ExpandButton expanded={state.expandedKeys?.length > 0} onClick={() => onToggleAllExpanded()} />
                                </div>,
                        key: "expand",
                        render: (_, record) => 'totalQuantity' in record && 
                            <ExpandButton expanded={state.expandedKeys?.includes(record.lineNumber)} onClick={() => onToggleExpanded(record.lineNumber)} />,
                        width: 35,
                        className: 'nowrap'                  },
                    {
                        title: '#',
                        dataIndex: 'lineNumber',
                        key: 'linenumber',
                        //align: 'right',
                        width: 35,
                        className: 'nowrap'
                    },
                    {
                        title: 'Product',
                        dataIndex: ['productVariation', 'productName'],
                        key: 'product',
                        ellipsis: state.showEllipsis ? { showTitle: false} : false,
                        render: (value) => state.showEllipsis ? <Tooltip placement="topLeft" title={value}>{value}</Tooltip> : value,
                        //width: '13%',
                    },
                    {
                        title: 'Part Number',
                        dataIndex: ['productVariation', 'partNumber'],
                        key: 'partNumber',
                        ellipsis: state.showEllipsis ? { showTitle: false} : false,
                        render: (value) => state.showEllipsis ? <Tooltip placement="topLeft" title={value}>{value}</Tooltip> : value,
                        //width: '13%'
                    },
                    {
                        title: 'Drawing Number',
                        key: 'drawingNumber',
                        //width: '13%',
                        render: (text, record, index) => {
                            if (record.productVariation?.technicalDrawingImage)
                                return (
                                    <ImageDownloadLink imageId={record.productVariation.technicalDrawingImage.imageId} fileName={record.productVariation.technicalDrawingImage.fileName} drawingNumber={record.productVariation.drawingNumber}/>
                                )
                            else
                                return record.productVariation?.drawingNumber
                        },
                    },
                    {
                        title: 'Properties',
                        key: 'properties',
                        ellipsis: state.showEllipsis ? { showTitle: false} : false,
                        render: (value) => state.showEllipsis ? <Tooltip placement="topLeft" title={FormatVariationProperties(value)}>{FormatVariationProperties(value)}</Tooltip> : FormatVariationProperties(value),
                        //width: '15%',
                    },
                    ...((state.expandedKeys?.length > 0) ?
                    [{

                        title: 'Color',
                        dataIndex: 'productColor',
                        key: 'color',
                        className: 'nowrap',
                        width: 50,
                        render: (value) => <ColorDisplay productColor={value} />
                    }] : []),
                    {
                        title: <Tooltip title='Quantity'>Qty</Tooltip>,
                        key: 'quantity',
                        align: 'center',
                        className: 'nowrap',
                        //width: 105,
                        render: (record: any) => 
                            <div onClick={e => e.stopPropagation()}>
                                {/* <div style={{ margin: 5, textAlign: 'right', padding: 2, border: '0px solid silver' }}>{'totalQuantity' in record ? record.totalQuantity : record.quantity}</div> */}
                                 <Input readOnly value={'totalQuantity' in record ? record.totalQuantity : record.quantity} style={{ width: 85, borderColor: 'transparent', backgroundColor: 'transparent', textAlign: 'right' }}/>
                                { state.showLast && (
                                    <div className={ isDifferentFromLastQty(record) ? 'table-highlight last' : 'last' } style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'right', width: 85}}>
                                        Last: <span style={{ fontWeight: 'bold', paddingRight: 12}}>{getLastQuantity(record)}</span>
                                    </div>
                                )
                                }
                            </div>,
                    },
                    {
                        title: <>
                                    <Tooltip title='Minimum Order Quantity'>MOQ</Tooltip>
                                    &nbsp;
                                    <TableRowButton buttontype='Copy' onClick={onCopyColumnPreviousMoQ} title='Copy last MOQ for ALL orderlines' type='link'/>&nbsp;
                                    
                                </>
                                ,
                        dataIndex: ['quotePrice', 'minimumOrderQuantity'],
                        key: 'minimumOrderQuantity',
                        //width: 100,
                        align: 'center',
                        render: (value: any, record: any, index) => {
                            if (record.productVariationId) {
                                return (
                                    <div onClick={e => e.stopPropagation()}>
                                        <Form.Item name={`moq_${record.id}`} style={{ margin: 0}} >
                                            <InputNumber 
                                                style={{ width: '75px', margin: 0 }}     
                                                min={0}
                                                max={99999999}
                                                precision={0}
                                                controls={false}
                                                size='small'
                                            />
                                        </Form.Item>
                                        { state.showLast && 
                                            <div className='last'>
                                                Last: <span style={{ fontWeight: 'bold'}}>{record.productVariation.lastPrice?.minimumOrderQuantity}</span>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        }
                    },
                    {
                        title: <>
                            Unit Price&nbsp;
                            <TableRowButton buttontype='Copy' onClick={onCopyColumnPreviousUnitPrice} title='Copy last Unit Price for ALL orderlines' type='link'/>&nbsp;
                            </>,
                        dataIndex: ['quotePrice', 'supplierUnitPrice'],
                        key: 'price',
                        //width: 140,
                        align: 'center',
                        render: (value: any, record: any, index) => {
                            if (record.productVariationId) {
                                return (
                                    <div onClick={e => e.stopPropagation()}>
                                        <Form.Item name={`unitPrice_${record.id}`} rules={ props.finish ? [{ required: true, message: 'Price is required.' }] : []} style={{ margin: 0}}>
                                            <MoneyInput />
                                        </Form.Item>
                                        { state.showLast && 
                                            <div className='last'>
                                                Last: <span style={{ fontWeight: 'bold'}}>{FormatCurrency(record.productVariation.lastPrice?.supplierUnitPrice)}</span>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        }
                    },
                    {
                        title: <>
                                Setup Cost&nbsp;
                                <TableRowButton buttontype='Copy' onClick={onCopyColumnPreviousSetupCost} title='Copy last Setup Cost for ALL orderlines' type='link'/>
                                </>,
                        dataIndex: ['quotePrice', 'supplierSetupCost'],
                        key: 'setupCost',
                        //width: 140,
                        align: 'center',
                        render: (value: any, record: any, index) => {
                            if (record.productVariationId) {
                                return (
                                    <div onClick={e => e.stopPropagation()}>
                                        <Form.Item name={`setupCost_${record.id}`} style={{ margin: 0}}>
                                            <MoneyInput />
                                        </Form.Item>
                                        { state.showLast && 
                                            <div className='last'>
                                                Last: <span style={{ fontWeight: 'bold'}}>{FormatCurrency(record.productVariation.lastPrice?.supplierSetupCost)}</span>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        }
                    },
                    {
                        title: <>
                                Mold Cost&nbsp;
                                <TableRowButton buttontype='Copy' onClick={onCopyColumnPreviousMoldCost} title='Copy last Mold Cost for ALL orderlines' type='link'/>
                                </>,
                        dataIndex: ['quotePrice', 'supplierMoldCost'],
                        key: 'moldCost',
                        //width: 140,
                        align: 'center',
                        render: (value: any, record: any, index) => {
                            if (record.productVariationId) {
                                return (
                                    <div onClick={e => e.stopPropagation()}>
                                        <Form.Item name={`moldCost_${record.id}`} style={{ margin: 0}}>
                                            <MoneyInput />
                                        </Form.Item>
                                        { state.showLast && 
                                            <div className='last'>
                                                Last: <span style={{ fontWeight: 'bold'}}>{FormatCurrency(record.productVariation.lastPrice?.supplierMoldCost)}</span>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        }
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        width: 75,
                        title: () => 
                            <Row>
                                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                                    <TableRowButton buttontype='Copy' onClick={onCopyAllPrevious} title='Copy ALL last data for ALL orderlines' />
                                    
                                </Col>
                                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                                    <TableRowButton 
                                        buttontype='Clear' 
                                        onClick={() => {
                                            props.form.resetFields()
                                            setIsChanged(true)
                                        }} 
                                        title='Clear all data for ALL orderlines' 
                                        hasconfirm 
                                        confirmmessage='Are you sure you want to clear all data?' />
                                </Col>
                            </Row>,
                        render: (text, record, index: number) =>
                            ( record.productVariationId &&
                                <Row>
                                    <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                                        <TableRowButton buttontype='Copy' onClick={() => onCopyRowPrevious(record.id)} title='Copy last data for this orderLine' />
                                    </Col>
                                    <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                                        <TableRowButton buttontype='History' onClick={() => showVariationHistory(record.productVariation.id, record.productVariation.partNumber)} title='View Price/cost History' />
                                    </Col>
                                </Row>
                            ),
                    }
                ]}
                rowClassName={(record: any) => (!record.orderId && record.quantity === 0 && 'hide') || ''}
                dataSource={props.orderLines}
                className='table-striped-rows'
            />

            <InnerModal
                title="Price/Cost History" 
                open={state.showHistoryModal} 
                width={800} 
                onClose={() => setState(prevState => ({ ...prevState, showHistoryModal: false }))} 
                saveButtonProps={{ buttontype: 'Ok', style: { padding: 0, display: 'none' }}}>
                <PriceHistory 
                    supplierId={props.supplierId} 
                    variationId={state.selectedVariationId ?? 0} 
                    supplierName={props.supplierName}
                    partNumber={state.selectedPartNumber ?? ''} 
                    onClose={() => setState(prevState => ({ ...prevState, showHistoryModal: false }))} />
            </InnerModal>
        </>       
    )
}