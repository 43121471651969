import React, { useEffect, useRef, useState } from 'react'
import { Modal, Space, Table, message } from 'antd'
import { MultiFetcher } from 'components/_common/MultiFetcher'
import { CustomButton, TableRowButton } from 'components/_common/CustomButtons'
import { InnerModal } from 'components/_common/InnerModal'
import { ShippingBatchDetails } from './ShippingBatchDetails'
import { FormatDate } from 'components/_common/Formatters'
import { ContentType } from 'components/_common/enums/ContentType'
import { useReactToPrint } from 'react-to-print'

interface State {
    showDetailsModal: boolean
    showDocumentHtmlModal: boolean
    documentTitle?:string
    documentHtml?: string
    batchId?: number
    batchNr?: number
    readonly: boolean
}

interface Props {
    orderId: number
    orderNr?: string
    readonly: boolean
    onClose(): void
    onRefresh(): void
}

export const ShippingBatchList = (props: Props) => {
    const [state, setState] = useState<State>({
        showDetailsModal: false,
        showDocumentHtmlModal: false,
        documentTitle: undefined,
        documentHtml: undefined,
        batchId: undefined,
        readonly: false
    })   

    const refPrintableArea = useRef(null)

    const batchFetcher = MultiFetcher('shippingBatch')
    const getBatches = () => batchFetcher.get({ queryParams: `orderId=${props.orderId}`})

    useEffect(() => {
        window.scrollTo(0, 0)
        getBatches()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDelete = (id: number, batchNumber: number) => {
        batchFetcher.del(id).then(async (success) => {
            if (success === true) {
                onRefresh()
                message.info(`Batch ${batchNumber} was deleted.`)
            }
        })
    }

    const onRefresh = () => {
        getBatches()
        props.onRefresh()
    }

    const closeDetailsModal = () => setState(prevState => ({ ...prevState, showDetailsModal: false, batchId: undefined, readonly: false }))

    //== Packing List ==
    const documentFetcher = MultiFetcher('document')

    const closeDocumentHtmlModal = () => setState(prevState => ({ ...prevState, showDocumentHtmlModal: false, batchId: undefined, batchNr: undefined }))

    const showPackingList = (batchId: number, batchNr: number) => {
        documentFetcher.get({ actionName: 'PackingListHtml', queryParams: `batchId=${batchId}`, contentType: ContentType.Text }).then((response) => {
            //console.log(`response: ${response}`)
            setState(prevState => ({ ...prevState, batchId, batchNr, documentTitle: 'Packing List', documentHtml: response, showDocumentHtmlModal: true }))
        })
    }

    const showBoxLabels = (batchId: number, batchNr: number) => {
        documentFetcher.get({ actionName: 'BoxLabelsHtml', queryParams: `batchId=${batchId}`, contentType: ContentType.Text }).then((response) => {
            //console.log(`response: ${response}`)
            setState(prevState => ({ ...prevState, batchId, batchNr, documentTitle: 'Box Labels', documentHtml: response, showDocumentHtmlModal: true }))
        })
    }

    const printDocumentHtml = useReactToPrint({
        content: () => refPrintableArea.current
    })

    return (
        <>               
            <Table
                loading={batchFetcher.isLoading}
                dataSource={batchFetcher.data} 
                className='table-striped-rows' 
                rowKey={'id'}
                size='small'
                pagination={false}
                columns={[
                    {
                        title: '#',
                        dataIndex: 'batchNumber',
                        key: 'batchNumber',
                        width: 30
                    },
                    {
                        title: 'Status',
                        dataIndex: 'statusName',
                        key: 'statusName'
                    },
                    {
                        title: 'Shipping Date',
                        dataIndex: 'shippingDate',
                        key: 'shippingDate',
                        render: (value: string) => <div>{FormatDate(value)}</div>,
                    },
                    {
                        title: 'Shipping Reference',
                        dataIndex: 'shippingReference',
                        key: 'shippingReference'
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        title: () => !props.readonly && <TableRowButton buttontype='Add' onClick={() => setState(prevState => ({ ...prevState, batchId: undefined, showDetailsModal: true }))} entity='Batch' />,
                        render: (record: any) =>
                            <div>
                                { props.readonly
                                ?
                                <TableRowButton buttontype='View' onClick={() => setState(prevState => ({ ...prevState, batchId: record.id, showDetailsModal: true, readonly: true }))} title='View Batch'/>
                                : 
                                    <Space>
                                        <TableRowButton buttontype='Profile' onClick={() => showPackingList(record.id, record.batchNumber)} title='Show Packing List'/>
                                        <TableRowButton buttontype='Tags' onClick={() => showBoxLabels(record.id, record.batchNumber)} title='Show Box labels'/>
                                        <TableRowButton buttontype='Edit' onClick={() => setState(prevState => ({ ...prevState, batchId: record.id, showDetailsModal: true }))} title='Edit Batch'/>
                                        <TableRowButton buttontype='Delete' onClick={() => onDelete(record.id, record.batchNumber)} title='Delete Batch' hasconfirm confirmmessage='Are you sure you want to delete this Batch?'/>
                                    </Space>   
                                }
                            </div>
                    }
                ]} />

                <InnerModal
                    title={<div className='color-shipping'>{(props.readonly || state.readonly) ? "View Shipping Batch" : (state.batchId ? "Edit Shipping Batch" : "Create New Shipping Batch")} (Order {props.orderNr})</div>}
                    open={state.showDetailsModal} 
                    fullScreen
                    className='progress-modal'
                    onClose={closeDetailsModal}
                    saveButtonProps={{ buttontype: 'Save', style: { display: (props.readonly || state.readonly) ? 'none' : 'inline-block' }}} >
                        <ShippingBatchDetails 
                            orderId={props.orderId}
                            batchId={state.batchId ?? 0} 
                            readonly={props.readonly || state.readonly}
                            onClose={closeDetailsModal} 
                            onRefresh={() => onRefresh()}/>
                </InnerModal>

                <Modal title={<div className='color-shipping'>{state.documentTitle} for {props.orderNr} - Batch {state.batchNr}</div>}
                    open={state.showDocumentHtmlModal} 
                    width={1440}
                    className='progress-modal'
                    onCancel={() => closeDocumentHtmlModal()}
                    maskClosable={false}
                    destroyOnClose
                    footer={
                        <>
                            <CustomButton 
                                buttontype='Close' 
                                onClick={closeDocumentHtmlModal} />
                            <CustomButton 
                                buttontype='Print'
                                onClick={printDocumentHtml} />
                        </>
                    }>
                        <div style={{ border: '1px solid silver', maxHeight: 'calc(80vh - 70px)', overflow: 'auto' }}>
                            <div ref={refPrintableArea} dangerouslySetInnerHTML={{ __html: state.documentHtml ?? ''}} />
                        </div>
                </Modal>
        </>
    )
}