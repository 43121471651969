import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { CustomerDetails } from './components/Pages/CRM/CustomerDetails';
import { CustomerList } from "./components/Pages/CRM/CustomerList";
import { ContactList } from "./components/Pages/CRM/ContactList";
import { Home } from "./components/Home";
import { ContactDetails } from './components/Pages/CRM/ContactDetails';
import { SupplierList } from './components/Pages/CRM/SupplierList';
import { SupplierDetails } from './components/Pages/CRM/SupplierDetails';
import { ProductList } from 'components/Pages/Products/ProductList';
import { ProductSizeList } from 'components/Pages/Base/ProductSizeList';
import { ProductSizeDetails } from 'components/Pages/Base/ProductSizeDetails';
import { ProductThreadTypeList } from 'components/Pages/Base/ProductThreadTypeList';
import { ProductThreadTypeDetails } from 'components/Pages/Base/ProductThreadTypeDetails';
import { ProductColorList } from 'components/Pages/Base/ProductColorList';
import { ProductColorDetails } from 'components/Pages/Base/ProductColorDetails';
import { ProductTemperatureList } from 'components/Pages/Base/ProductTemperatureList';
import { ProductTemperatureDetails } from 'components/Pages/Base/ProductTemperatureDetails';
import { ProductCategoryList } from 'components/Pages/Base/ProductCategoryList';
import { ProductCategoryDetails } from 'components/Pages/Base/ProductCategoryDetails';
import { ProductDetails } from 'components/Pages/Products/ProductDetails';
import { CountryList } from 'components/Pages/Base/CountryList';
import { CountryDetails } from 'components/Pages/Base/CountryDetails';
import { OrderList } from 'components/Pages/Orders/OrderList';
import { UserList } from 'components/Pages/Security/UserList';
import { UserDetails } from 'components/Pages/Security/UserDetails';
import { ChangePassword } from 'components/Pages/Security/ChangePassword';
import { ContactRoleDetails } from 'components/Pages/CRM/ContactRoleDetails';
import { ContactRoleList } from 'components/Pages/CRM/ContactRoleList';
import { QuoteRequestList } from 'components/Pages/QuoteRequests/QuoteRequestList';
import { Unauthorized } from 'components/Pages/Security/Unauthorized';
import { PricedQuoteList } from 'components/Pages/PricedQuotes/PricedQuoteList';
import { UserRole } from 'components/_common/UserState';
import { PriceList } from 'components/Pages/Prices/PriceList';
import { AuditLogList } from 'components/Pages/AuditLog/AuditLogList';
import { BoxList } from 'components/Pages/Base/BoxList';
import { Payments } from 'components/Pages/Payments/Payments';

const AppRoutes = [
  { index: true, requireAuth: true, element: <Home /> },
  { path: '/auditlog/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <AuditLogList /> },
  { path: '/box/list', requireAuth: true, userRole: UserRole.SupplierUser, element: <BoxList /> },
  { path: '/customer/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <CustomerList /> },
  { path: '/customer/edit/:id?', requireAuth: true, userRole: UserRole.SuperAdmin, element: <CustomerDetails /> },
  { path: '/contact/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ContactList /> },
  { path: '/contact/edit/:id?', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ContactDetails /> },
  { path: '/contactrole/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ContactRoleList /> },
  { path: '/contactrole/edit/:id?', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ContactRoleDetails /> },
  { path: '/country/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <CountryList /> },
  { path: '/country/edit/:id?', requireAuth: true, userRole: UserRole.SuperAdmin, element: <CountryDetails /> },
  { path: '/supplier/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <SupplierList /> },
  { path: '/supplier/edit/:id?', requireAuth: true, userRole: UserRole.SuperAdmin, element: <SupplierDetails /> },
  { path: '/order/list', requireAuth: true,  userRole: UserRole.CustomerUser, element: <OrderList /> },
  { path: '/payment/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <Payments /> },
  { path: '/price/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <PriceList /> },
  { path: '/productcategory/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ProductCategoryList /> },
  { path: '/productcategory/edit/:id?', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ProductCategoryDetails /> },
  { path: '/productsize/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ProductSizeList /> },
  { path: '/productsize/edit/:id?', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ProductSizeDetails /> },
  { path: '/productthreadtype/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ProductThreadTypeList /> },
  { path: '/productthreadtype/edit/:id?', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ProductThreadTypeDetails /> },
  { path: '/productcolor/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ProductColorList /> },
  { path: '/productcolor/edit/:id?', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ProductColorDetails /> },
  { path: '/producttemperature/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ProductTemperatureList /> },
  { path: '/producttemperature/edit/:id?', requireAuth: true, userRole: UserRole.SuperAdmin, element: <ProductTemperatureDetails /> },
  { path: '/product/list', requireAuth: true, userRole: UserRole.CustomerUser, element: <ProductList /> },
  { path: '/product/edit/:id?', requireAuth: true, userRole: UserRole.CustomerUser, element: <ProductDetails /> },
  { path: '/quoterequest/list', requireAuth: true, userRole: UserRole.SupplierUser, element: <QuoteRequestList /> },
  { path: '/pricedquote/list', requireAuth: true, userRole: UserRole.SuperAdmin, element: <PricedQuoteList /> },
  { path: '/user/list', requireAuth: true,userRole: UserRole.SuperAdmin, element: <UserList /> },
  { path: '/user/edit/:id?', requireAuth: true, userRole: UserRole.SuperAdmin, element: <UserDetails /> },
  { path: '/changepassword', requireAuth: true, element: <ChangePassword /> },
  { path: '/unauthorized', requireAuth: false, element: <Unauthorized /> },
  ...ApiAuthorizationRoutes
];

export default AppRoutes;
