import { Descriptions, Table, Form, Input, message } from 'antd'
import DescriptionsItem from 'antd/es/descriptions/Item'
import { IsChangedOuterContext } from 'components/_common/OuterModal'
import { FormatDateTime, FormatCurrency } from 'components/_common/Formatters'
import { ImageFromId } from 'components/_common/ImageFromId'
import { MultiFetcher } from 'components/_common/MultiFetcher'
import { PageLoader } from 'components/_common/PageLoader'
import { Title } from 'components/_common/PageTitle'
import React, { useContext, useEffect } from 'react'

interface Props {
    orderId: number
    onClose(): void
    onRefresh(): void
}

export const VerifyPayment = (props: Props) => {
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    const paymentFetcher = MultiFetcher('payment')

    useEffect(() => {
        paymentFetcher.get({ 
            actionName: 'GetLatestForOrder', 
            queryParams: `orderId=${props.orderId}`
        }).then((payment) => {
            
            if (!payment.id){
                props.onClose()
                message.error('Could not find a payment to verify.')
            }
        })        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Submit = async (data: any) => {
        const submitAsync = async (data: any):Promise<number> => await paymentFetcher.put(data)
        data.isVerified = true
        //console.log(`data: [${JSON.stringify(data)}]`)

        await submitAsync(data).then((result) => {
            if (result > 0) {
                props.onClose()
                message.info('Payment was approved.')
                props.onRefresh()
            }
        })
    }

    return (
        <PageLoader loading={paymentFetcher.isLoading}>
            { paymentFetcher.data?.id &&
                <>
                    <Descriptions bordered size='middle' 
                        labelStyle={{ width: '140px', minWidth: '50px', maxWidth: '165px', whiteSpace: 'nowrap' }}
                        contentStyle={{ minWidth: 125 }}
                        column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 4, xxl: 4 }}>
                        <DescriptionsItem label='Date' style={{whiteSpace: 'nowrap'}}>{FormatDateTime(paymentFetcher.data.paymentDate)} </DescriptionsItem>
                        <DescriptionsItem label='Reference'>
                            <Form initialValues={paymentFetcher.data} form={form} onFinish={Submit} onValuesChange={() => setIsChanged(true)} >
                                <Form.Item name='id' hidden={true}><Input/></Form.Item>
                                <Form.Item name="reference" style={{margin: 0}}>
                                    <Input style={{minWidth: 80, maxWidth: 225}} maxLength={25}/>
                                </Form.Item>
                                <button ref={submitRef} type="submit" className='hide' />
                            </Form> 
                        </DescriptionsItem>
                        <DescriptionsItem label='Amount'>{FormatCurrency(paymentFetcher.data.amount)} </DescriptionsItem>
                    </Descriptions>

                    <br/>
                    <Title className='h6'>Orders</Title>

                    <Table
                        dataSource={paymentFetcher.data?.paymentOrders} 
                        loading={paymentFetcher.isLoading}
                        rowKey={'id'}
                        size='small'
                        pagination={{ defaultPageSize: 12, size: 'default' }}
                        columns={[
                            {
                                title: 'Order Nr.',
                                dataIndex: 'customerOrderNumber',
                                key: 'orderNr',
                            },
                            {
                                title: 'Term',
                                dataIndex: 'paymentTermName',
                                key: 'paymentTermName',
                            },
                            {
                                title: 'Amount',
                                dataIndex: 'amount',
                                key: 'amount',
                                render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>
                            },
                        ]}
                        style={{ paddingBottom: '10px'}} 
                        className='table-striped-rows' /> 
                        
                        <div>
                            Proof: {paymentFetcher.data.imageFileName} <br/>
                            <ImageFromId imageId={paymentFetcher.data.imageId} preview={false} style={{width: '100%'}} hasZoom/>
                        </div>
                </>
            }
        </PageLoader>
    )
} 