import React, { useContext, useEffect } from 'react'
import { Col, Form, Input, InputNumber, Row } from 'antd'
import { CustomSelect } from 'components/_common/CustomSelect';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import * as _ from 'lodash';
import { CategoryTreeSelect } from 'components/_common/CategoryTreeSelect';
import { IsChangedInnerContext } from 'components/_common/InnerModal';

interface Props {
    orderLine: any,
    onClose(): void
    onSave(value: any): void
}

export const OrderLineDetails = (props: Props) => {  
    const { setIsChanged, submitRef } = useContext(IsChangedInnerContext)
    const [form] = Form.useForm()

    const categoryFetcher = MultiFetcher('productCategory')
    const productFetcher = MultiFetcher('product')
    const productVariationFetcher = MultiFetcher('productvariation')
    const productColorFetcher = MultiFetcher('productcolor')
    
    useEffect(() => {
        window.scrollTo(0, 0)

        form.resetFields()
        //console.log(`orderLine.Id: [${props.orderLine.id}]`)

        categoryFetcher.get({})
        productColorFetcher.get({})

        if (props.orderLine) 
            if (props.orderLine.productVariation) {
                productFetcher.get({})
                productVariationFetcher.get({ queryParams: `productId=${props.orderLine.productVariation.productId}` })
            }
            if (props.orderLine.orderLineDetails){
                _.forEach(props.orderLine.orderLineDetails, (orderLineDetail) => {
                    form.setFieldValue(`quantity_${orderLineDetail.productColorId}`, orderLineDetail.quantity)
                })
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onCategoryChange = (value:string) => {
        productFetcher.get({ queryParams: `categoryId=${value}` }).then(() => {
            productVariationFetcher.setData([])
            form.setFieldValue(['productVariation', 'productId'], undefined)
            form.setFieldValue('productVariationId', undefined)
        })
    }

    const onProductChange = (value:string) => {
        productVariationFetcher.get({ queryParams: `productId=${value}` }).then(() => {
            form.setFieldValue('productVariationId', undefined)
        })
    }

    const validateQuantities = () => {
        let total = 0
        _.forEach(productColorFetcher.data, (color: any) => {
            const quantity = form.getFieldValue(`quantity_${color.id}`)
            total += quantity ?? 0
        })
        return total > 0
    }

    const onSubmit = (orderLine:any) => {
        orderLine.orderLineDetails = []
        orderLine.productVariation = _.find(productVariationFetcher.data, p => p.id === orderLine.productVariationId) //-- so properties and partnumber can be shown in OrderLinesList
        //console.log(`found productVariation to add: [${JSON.stringify(orderLine.productVariation)}]`)
        
        _.forEach(productColorFetcher.data, (color: any) => {
            const quantity = form.getFieldValue(`quantity_${color.id}`)
            const foundOrderLineDetail = _.find(props.orderLine.orderLineDetails ?? [], p => p.productColorId === color.id)

            if (foundOrderLineDetail) {
                foundOrderLineDetail.quantity = quantity ?? 0
                orderLine.orderLineDetails.push(foundOrderLineDetail)
            } else {
                orderLine.orderLineDetails.push({
                    orderLineId: orderLine.id,
                    productColorId: color.id,
                    productColor: color,
                    quantity: quantity ?? 0
                })
            }
        })

        orderLine.totalQuantity = _.sumBy(orderLine.orderLineDetails, (p:any) => p.quantity)

        props.onSave(orderLine)
    }

    return (
        <Form 
            layout='horizontal'
            labelCol={{ span: 6, xl: 7}}
            wrapperCol={{ span: 18, xl: 15}}
            initialValues={props.orderLine}
            style={{ padding: '10px'}}
            form={form}
            onValuesChange={() => setIsChanged(true)}
            onFinish={onSubmit}>
            <Form.Item name='id' hidden={true}><Input/></Form.Item>
            <Form.Item name='orderRevisionId' hidden={true}><Input/></Form.Item>
            <Form.Item name="lineNumber" hidden={true}><Input /></Form.Item>
            <Form.Item name="price" hidden={true}><Input /></Form.Item>
            <Row>
                <Col span={24}>
                    <Form.Item label="Category" name={['productVariation', 'productCategoryId']} rules={[{ required: true, message: 'Category is required.' }]}>
                        <CategoryTreeSelect categories={categoryFetcher.data} loading={categoryFetcher.isLoading} onChange={onCategoryChange} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xl={24} span={24}>
                    <Form.Item label="Product" name={['productVariation', 'productId']} rules={[{ required: true, message: 'Product is required.' }]}>
                        <CustomSelect data={productFetcher.data} loading={productFetcher.isLoading} required  onChange={onProductChange} style={{ width: 400 }}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xl={24} span={24}>
                    <Form.Item label="Variation" name="productVariationId" rules={[{ required: true, message: 'Variation is required.' }]}>
                        <CustomSelect data={productVariationFetcher.data} loading={productVariationFetcher.isLoading} displayfieldname='displayName'  style={{ width: 400 }} required />
                    </Form.Item>
                </Col>
            </Row>
            {productColorFetcher.data?.map((color:any) => (
                <Row key={`row_${color.id}`}>
                    <Col xl={24} span={24}>
                        <Form.Item label={`${color.name}`} name={`quantity_${color.id}`}>
                            <InputNumber min={0} max={99999999} controls={false}/>
                        </Form.Item>
                    </Col>
                </Row>
            ))}
            <Col span={24} offset={6} lg={{ offset: 7}} >
                <Form.Item 
                    name="quantityCheck" 
                    style={{ marginTop: '-30px'}}
                    rules={[({ getFieldValue }) => ({
                        validator(_, value) {
                            if (validateQuantities()) 
                                return Promise.resolve();
                            return Promise.reject(new Error('You should enter a quantity.'));
                        },
                        })]}>
                </Form.Item>
            </Col>
            <button ref={submitRef} type="submit" className='hide' />
        </Form>
    )
}