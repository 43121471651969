import React, { useEffect } from 'react';
import { Table } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { FormatCurrency, FormatDateTime } from 'components/_common/Formatters';
import { dateCompare } from 'components/_common/Generic';

interface Props {
    orderId: any
}

export const PaymentsViewer = (props: Props) => {
    const paymentFetcher = MultiFetcher('payment')

    useEffect(() => {
        paymentFetcher.get({ 
            actionName: 'GetAllForOrder', 
            queryParams: `orderId=${props.orderId}`
        })        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Table
            loading={paymentFetcher.isLoading}
            dataSource={paymentFetcher.data}
            rowKey={record => record.lineNumber ?? `color_${record.productColorId}`}
            size='small'
            pagination={false}
            className='table-striped-rows' 
            style={{ width: 'auto', paddingBottom: '10px'}} 
            columns={[
                {
                    title: 'Date',
                    dataIndex: 'paymentDate',
                    key: 'paymentDate',
                    className: 'nowrap',
                    render: (value: string) => <div>{FormatDateTime(value)}</div>,
                    sorter: (a: any, b: any) => dateCompare(a.paymentDate, b.paymentDate),
                },
                {
                    title: 'Reference',
                    dataIndex: 'reference',
                    key: 'reference',
                    sorter: (a:any,b:any) => a.reference.localeCompare(b.reference),
                },
                {
                    title: 'Payment Term',
                    key: 'paymentTerm',
                    render: (record) => record.paymentOrders?.find(p => p.orderId === props.orderId).paymentTermName,
                },
                {
                    title: 'Amount',
                    key: 'amount',
                    align: 'right',
                    render: (record) => <div className='nowrap'>{FormatCurrency(record.paymentOrders?.find(p => p.orderId === props.orderId).amount)}</div>
                },
            ]}           
        />
    )
}