import React from 'react'
import { InputNumber, InputNumberProps } from 'antd'

export const LengthInput = (props: InputNumberProps) => {
    return (
        <InputNumber 
            {...props}
            addonAfter='cm'
            style={{ width: '110px' }}
            min={0}
            max={999999.9}
            precision={1}
            controls={false}
            size='middle'
        />
    )
}