import React from 'react'
import { Select, SelectProps } from 'antd'
import * as _ from 'lodash'

interface CustomSelectProps extends SelectProps {
    data: []
    displayfieldname?: string
    required?: boolean
    readOnly?: boolean
    addonafter?: React.ReactNode
}

export const CustomSelect = (props: CustomSelectProps) => {
    return (
        <div className='ant-input-number-group-wrapper css-dev-only-do-not-override-2i2tap css-2i2tap' style={{ width: '100%' }}>
            <div className='ant-input-number-wrapper ant-input-number-group css-dev-only-do-not-override-2i2tap css-2i2tap nowrap' style={{ width: '100%' }}>
                <Select 
                    {...props}
                    style={ props.style || {width: '100%'}}
                    className={props.readOnly ? 'readOnly' : props.className}
                    open={props.readOnly ? false : props.open} 
                    onChange={props.readOnly ? undefined :  props.onChange}  
                    allowClear={!props.readOnly && props.allowClear} 
                    showSearch={props.showSearch ?? true}
                    optionFilterProp="children" 
                    placeholder={props.required && 'Please select'}>
                    { props.required ?? <Select.Option value={undefined}>&nbsp;</Select.Option> }
                    { _.map(props.data, (record:any, index) => {
                        return <Select.Option key={`_${index}`} value={record.id}>{props.displayfieldname ? record[props.displayfieldname] : record.name}</Select.Option>
                    })}
                </Select>
                {props.addonafter &&
                    <div className="ant-input-number-group-addon">{props.addonafter}</div>
                }
            </div>
        </div>
    )
}