import React, { useContext, useEffect, useState } from 'react';
import { Space, Table, Tooltip } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { PageTitle } from 'components/_common/PageTitle';
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter';
import { UserContext } from 'components/AppLayout';
import { FormatDate, FormatDateTime } from 'components/_common/Formatters';
import { UserRole, UserState } from 'components/_common/UserState';
import { ColumnType } from 'antd/es/table';
import { dateCompare } from 'components/_common/Generic';
import { QuoteRequestDetails } from './QuoteRequestDetails';
import { OuterModal } from 'components/_common/OuterModal';

interface State {
    selectedOrderId?: number
}

export const QuoteRequestList = () => {
    const { isAuthorizedForRole } = useContext<UserState>(UserContext)

    const [state, setState] = useState<State>({
        selectedOrderId: undefined,
    })   

    const quoteFetcher = MultiFetcher('quoterequest');   

    useEffect(() => {
        window.scrollTo(0, 0)
        quoteFetcher.get({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setSelectedOrderId = (value?: number) => setState(prevState => ({ ...prevState, selectedOrderId: value }))

    const onRefresh = () =>  quoteFetcher.get({})

    return (
        <>
            <PageTitle>Quote Requests</PageTitle>
                
            <Table
                loading={quoteFetcher.isLoading}
                rowKey={'id'}
                size='small'
                pagination={{ defaultPageSize: 12, size: 'default' }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => setSelectedOrderId(record.id)
                    }
                }}
                style={{ paddingBottom: '10px', cursor: 'pointer'}}
                columns={[
                    {
                        title: 'Customer Order No.',
                        dataIndex: 'fullCustomerOrderNumber',
                        key: 'fullCustomerOrderNumber',
                        sorter: (a:any,b:any) => a.fullCustomerOrderNumber.localeCompare(b.fullCustomerOrderNumber),
                        ...ColumnTextFilter('fullCustomerOrderNumber'),
                        width: 150
                    },
                    ...isAuthorizedForRole(UserRole.SuperAdmin) ?
                    [{
                        title: 'Order No.', 
                        dataIndex: 'fullOrderNumber',
                        key: 'ordernumber',
                        sorter: (a:any,b:any) => a.fullOrderNumber.localeCompare(b.fullOrderNumber),
                        //...ColumnTextFilter('fullOrderNumber'), ==> BUG on refresh
                        responsive: ['xxl']
                    } as ColumnType<any>] : [],
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                        ellipsis: { showTitle: false },
                        render: (value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                        width: '25vw'
                    },
                    {
                        title: 'Contact',
                        dataIndex: 'contactName',
                        key: 'contact',
                        sorter: (a:any,b:any) => a.contactName.localeCompare(b.contactName),
                        //...ColumnTextFilter('contactName'),
                        responsive: ['xxl']
                    },
                    ...isAuthorizedForRole(UserRole.SuperAdmin) ?
                    [{
                        title: 'Supplier',
                        dataIndex: 'supplierName',
                        key: 'supplier',
                        sorter: (a:any,b:any) => a.supplierName.localeCompare(b.supplierName),
                        //...ColumnTextFilter('supplierName')
                    }] : [],
                    {
                        title: 'Target Date',
                        dataIndex: 'targetDate',
                        key: 'targetDate',
                        sorter: (a: any, b: any) => dateCompare(a.targetDate, b.targetDate),
                        render: (value: string) => <div>{FormatDate(value)}</div>,
                        responsive: ['xxl']
                    },
                    {
                        title: 'Requested On',
                        dataIndex: 'quoteRequestedOn',
                        key: 'quoteRequestedOn',
                        sorter: (a: any, b: any) => dateCompare(a.quoteRequestedOn, b.quoteRequestedOn),
                        render: (value: string) => <div>{FormatDateTime(value)}</div>,
                        responsive: ['lg']
                    },                    
                    {
                        key: 'actions',
                        align: 'right',
                        render: (record: any) =>
                            <div>
                                <Space>
                                    <TableRowButton buttontype='Edit' onClick={() => setSelectedOrderId(record.id)} title='Open'/>
                                </Space>
                            </div>
                    }

                ]}
                dataSource={quoteFetcher.data} 
                className='table-striped-rows' />

                <OuterModal
                    title="Quote Request" 
                    fullScreen
                    open={state.selectedOrderId !== undefined} 
                    onClose={() => setSelectedOrderId(undefined)} >
                        <QuoteRequestDetails id={state.selectedOrderId} onClose={() => setSelectedOrderId(undefined)} onRefresh={() => onRefresh()}/>
                </OuterModal>
        </>
    )
}