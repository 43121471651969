/* eslint-disable react-hooks/exhaustive-deps */
import { notification } from 'antd';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import authService from 'components/api-authorization/AuthorizeService'
import React from 'react';
import { ContentType } from './enums/ContentType';

interface State {
    isLoading: boolean
    data?: any
  }

export const MultiFetcher = (endpoint: string) => {
    //const basePath = `${process.env.REACT_APP_SERVER_URL}/${endpoint}/`;
    const basePath = `api/${endpoint}/`

    const [state, setState] = useState<State>({
        isLoading: false,
        data: undefined
      })

    const setData = (data: any) => {
        setState(prevState => ({ ...prevState, data: Object.assign({}, data) }))
    }

    const findDataItem = (id: number): any => {
        if (id) {
             const item = state.data?.filter((item:any) => item.id === id)
             //console.log(`item ${JSON.stringify(item)}`)
             return item[0]
        }
    }

    const showError = (method: string, error: string) => {
        notification['error']({
          message: 'Error',
          description: (
            <>
                An error occurred whilst executing {method}: 
                <br/>
                <br/>
                <b>{error}</b>
            </>)
        })
    };

    const handleError = useCallback(async (response: Response) =>  {
        if (!response.ok) {
            //console.log(`response.status: [${response.status}]`)
            if (response.status === 401){
                authService.signOut()
                return
            }

            // if (response.status === 409){
            //     throw Error
            // }


            let json
            try {
                json = await response.json()
            }
            catch(e)
            {
                // response is not JSON
                console.error(e)
                // eslint-disable-next-line no-throw-literal
                throw Error(`Non-specified error.`);
            }

            //console.log(`json: ${JSON.stringify(json)}`)
            throw Error(json.message || json.Message)
        }
        return response;
    }, [])

    interface CreateRequestOptionsParams {
        method: string
        useToken: boolean
        body?: any
        contentType?: ContentType,
        enableCache?: boolean
    }

    const createRequestOptions = useCallback(async (params: CreateRequestOptionsParams) : Promise<RequestInit> => {
        var headers: {[k: string]: string} = {}

        if (params.contentType === ContentType.Json || params.contentType === undefined) {
            headers['Content-Type'] = 'application/json'
        }

         if (params.useToken === true) {
             const token = await authService.getAccessToken()
             headers['Authorization'] = `Bearer ${token}`
         }

        if (!params.enableCache === true) {
            headers['Pragma'] = 'no-cache'
            headers['Cache-Control'] = 'private, no-cache, no-store, must-revalidate, max-age=0'
        }

       return  {
            method: params.method,
            headers: headers,
            body: params.body
        }
      
    }, [])

    const createUrl = useCallback(async (basePath: string, params: RouteParams) : Promise<string> => {
        let url = basePath

        if (params.id)
            url += `${params.id}`

        if (params.actionName)
            url += `${params.actionName}/`

        if (params.queryParams)
            url += `?${params.queryParams}`

        return url
    }, [])

    interface RouteParams {
        id?:string
        actionName?: string
        queryParams?: string
    }

    interface GetParams extends RouteParams {
        enableCache?: boolean
        onDataFetched?(data: any): void
        contentType?: ContentType
    }

    //== GET ==
    const get = useCallback(async (params: GetParams): Promise<any> => {

        setState(prevState => ({ ...prevState, isLoading: true }))

        const requestOptions = await createRequestOptions({
            method: 'GET', 
            useToken: true, 
            contentType: params.contentType, 
            enableCache: params.enableCache})

        const url = await createUrl(basePath, params)

        const response = await fetch(url, requestOptions)
            .then(handleError)
            .then(response => {
                switch (params.contentType) {
                    case ContentType.Json: return response?.json()
                    case ContentType.Text: return response?.text()
                    case ContentType.Blob: return response?.blob()
                    default: return response?.json()
                }
            })
            .then((response) => {
                //console.log(`getMultiple response: ${JSON.stringify(response)}`)
                setState(prevState => ({ ...prevState, data: response }))
                if (params.onDataFetched) response = params.onDataFetched(response)
                return response //TODO: remove
            })
            .catch((error:Error) => {
                showError('get', error.toString())
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isLoading: false }))
            })

        return response || null; // TODO: remove 

    }, [basePath, createRequestOptions, createUrl, handleError])

    //== POST ==
    const post = useCallback(async (data: any, actionName?: string, queryParams?: string): Promise<number> => {

        setState(prevState => ({ ...prevState, isLoading: true }))

        const url = await createUrl(basePath, { actionName, queryParams})

        const requestOptions = await createRequestOptions({method: 'POST', useToken: true, body: JSON.stringify(data), contentType: ContentType.Json })

        const response = await fetch(url, requestOptions)
            .then(handleError)
            .then(response => response?.json())
            .catch((error:Error) => {
                showError('post', error.message)
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isLoading: false }))
            })

        //console.log(`postSingle response: ${response}`)
        return response

    }, [basePath, createRequestOptions, handleError])

    //== PUT ==
    const put = useCallback(async (data: any, id?: string | number, actionName?: string, queryParams?: string): Promise<number> => {

        setState(prevState => ({ ...prevState, isLoading: true }))

        const url = await createUrl(basePath, { id: id?.toString(), actionName, queryParams})

        const requestOptions = await createRequestOptions({method: 'PUT', useToken: true, body: JSON.stringify(data), contentType: ContentType.Json })

        const response = await fetch(url, requestOptions)
            .then(handleError)
            .then(() => true)
            .catch((error:Error) => {
                showError('put', error.message);
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isLoading: false }));
            })

        //console.log(`putSingle response: ${response}`)
        return response ? 1 : 0

    }, [basePath, createRequestOptions, handleError])

    //== DELETE ==
    const del = useCallback(async (id: number): Promise<boolean> => {

        setState(prevState => ({ ...prevState, isLoading: true }))

        const url = `${basePath}${id}`

        const requestOptions = await createRequestOptions({method: 'DELETE', useToken: true, contentType: ContentType.Json })

        const response = await fetch(url, requestOptions)
            .then(handleError)
            .then(() => true)
            .catch((error:Error) => {
                showError('del', error.message);
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isLoading: false }))
            })

        //console.log(`delete response: [${response}]`)

        return response != null

    }, [basePath, createRequestOptions, handleError])

    //== POST File ==
    const postFile = useCallback(async (data: any, querystringParams?: { [key: string]: string }, id?: string): Promise<any> => {

        setState(prevState => ({ ...prevState, isLoading: true }))

        const formData = new FormData()
        formData.append('file', data)
    
        const requestOptions = await createRequestOptions({method: 'POST', useToken: true, body: formData, contentType: ContentType.Blob })

        let url = basePath + (id ? id : '')
        if (querystringParams) {
            const queryParams = _.map(querystringParams, (value, prop) => `${prop}=${value}`).join('&')
            url += '?' + queryParams;
            url = url.replace(/(https?:\/\/)|(\/)+/g, '$1$2')
        }

        const response = await fetch(url, requestOptions)
            .then(handleError)
            .then(response => response?.json())
            .catch((error:Error) => {
                showError('postFile', error.message)
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isLoading: false }))
            })

        return response || null

    }, [basePath, createRequestOptions, handleError])

    
    return {
        data: state.data,
        isLoading: state.isLoading,
        setData,
        findDataItem,
        get,
        post,
        put,
        del,
        postFile
      }
}