import { Button, Input, InputRef } from 'antd';
import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';

interface State {
    searchText?: string
}

export const ColumnTextFilter = (dataIndex: any) => {
    const [state, setState] = useState<State>({
        searchText: undefined,
    });

    let searchInput: InputRef | null | undefined = undefined
    
    const handleSearch = (selectedKeys: any, confirm: any) => {
      console.log('handle search...')
        confirm()
        setState(prevState => ({ ...prevState, searchText: selectedKeys[0] }))
    }
    
    const handleReset = (clearFilters:any, confirm: any) => {
      console.log('handle reset...')
        clearFilters()
        confirm()
        setState(prevState => ({ ...prevState, searchText: undefined }))
    }

    return {
          filterDropdown: (props: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => { searchInput = node; }}
              placeholder={`Search ${dataIndex}`}
              value={props.selectedKeys[0]}
              onChange={e => props.setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(props.selectedKeys, props.confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(props.selectedKeys, props.confirm)}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(props.clearFilters, props.confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, height: '30px !important', width: '30px !important' }} height='30px' width='30px' />,
        onFilter: (value: any, record: any) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: boolean) => {
          if (visible) {
            setTimeout(() => searchInput?.select());
          }
        },
        render: (text: any) => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffe469de', padding: 0 }}
            searchWords={[state.searchText ?? '']}
            autoEscape
            textToHighlight={text?.toString() ?? ''}
          />
        ),
      }
}