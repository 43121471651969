import React, { useEffect } from 'react';
import { Space, Table, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { PageTitle } from 'components/_common/PageTitle';
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter';

export const CountryList = () => {
    
    const fetcher = MultiFetcher('country');  

    useEffect(() => {
        window.scrollTo(0, 0);
        fetcher.get({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const deleteItem = async (id: number) => {
        fetcher.del(id).then(async (success) => {
            if (success === true) {
                await fetcher.get({})
                message.info(`Category was deleted.`)
            }
        })
    }

    return (
        <>
            <PageTitle>Countries</PageTitle>

            <Table
                loading={fetcher.isLoading}
                rowKey={'id'}
                size='small'
                pagination={{ defaultPageSize: 12, size: 'default' }}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: (a:any,b:any) => a.name.localeCompare(b.name),
                            ...ColumnTextFilter('name')
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        title: () => <TableRowButton buttontype='Add' url='/country/edit/' entity='Country' />,
                        render: (record: any) =>
                            <div>
                                <Space>
                                    <TableRowButton buttontype='Edit' recordid={record.id} url={`/country/edit`} entity='Country' />
                                    <TableRowButton buttontype='Delete' recordid={record.id} onClick={deleteItem} entity='Country' hasconfirm />
                                </Space>
                            </div>
                    }

                ]}
                dataSource={fetcher.data} 
                style={{ paddingBottom: '10px'}} 
                className='table-striped-rows' /> 
        </> 
    )
}