/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Descriptions, Table } from 'antd';
import { PageSubTitle } from 'components/_common/PageTitle';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { FormatCurrency, FormatDateTime } from 'components/_common/Formatters';
import { PageLoader } from 'components/_common/PageLoader';
import { ImageDownloadLink } from 'components/_common/ImageDownloadLink';

interface Props {
    paymentId: number
    onClose(): void
}

export const PaymentView = (props: Props) => {

    const paymentFetcher = MultiFetcher('payment')

    useEffect(() => {
        paymentFetcher.get({ id: props.paymentId.toString() })        
    }, [])

    return (
        <>
            <PageLoader loading={paymentFetcher.isLoading}>
                { paymentFetcher.data && 
                    <Descriptions bordered size='middle' 
                        labelStyle={{ width: '140px', minWidth: '110px', maxWidth: '165px', whiteSpace: 'nowrap' }}
                        contentStyle={{ minWidth: 150 }}
                        column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 3 }}>
                        <DescriptionsItem label='Date' style={{whiteSpace: 'nowrap'}}>{FormatDateTime(paymentFetcher.data.paymentDate)} </DescriptionsItem>
                        <DescriptionsItem label='Amount'>{FormatCurrency(paymentFetcher.data.amount)} </DescriptionsItem>
                        <DescriptionsItem label='Reference'>{paymentFetcher.data.reference} </DescriptionsItem>
                        <DescriptionsItem label='Proof'><ImageDownloadLink imageId={paymentFetcher.data.imageId} fileName={paymentFetcher.data.imageFileName} /></DescriptionsItem>
                    </Descriptions>
                }
            </PageLoader>

            <br/>

            <PageSubTitle>Orders</PageSubTitle>

            { paymentFetcher.data && 
                <Table
                    dataSource={paymentFetcher.data?.paymentOrders} 
                    loading={paymentFetcher.isLoading}
                    rowKey={'id'}
                    size='small'
                    pagination={{ defaultPageSize: 12, size: 'default' }}
                    columns={[
                        {
                            title: 'Customer OrderNr.',
                            dataIndex: 'fullCustomerOrderNumber',
                            key: 'orderNr',
                        },
                        {
                            title: 'Term',
                            dataIndex: 'paymentTermName',
                            key: 'paymentTermName',
                        },
                        {
                            title: 'Amount',
                            dataIndex: 'amount',
                            key: 'amount',
                            render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>
                        },
                    ]}
                    style={{ paddingBottom: '10px'}} 
                    className='table-striped-rows' /> 
            }
        </>
    )
}