import { Col, Form, Row } from 'antd';
import { IsChangedInnerContext } from 'components/_common/InnerModal';
import { PercentageInput } from 'components/_common/PercentageInput';
import React, { useContext } from 'react'


interface Props {
    onSave(value: number):void
    onClose(): void
}

export const EnterMarginForm = (props: Props) => {
    const { setIsChanged, submitRef } = useContext(IsChangedInnerContext)
    const [form] = Form.useForm()

    return (
        <Form form={form} onFinish={(values) => props.onSave(values.value)} onValuesChange={() => setIsChanged(true)} >
            <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center', padding: 25}}>
                    <Form.Item name={`value`} style={{ margin: 0 }} rules={[{ required: true, message: 'Value is required.' }]} >
                        <PercentageInput />
                    </Form.Item>
                </Col>
            </Row>
            <button ref={submitRef} type="submit" className='hide' />
        </Form>
    )
}