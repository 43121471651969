import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Col, DatePicker, Divider, Form, Input, Row, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import * as _ from 'lodash';
import { PageSubTitle } from 'components/_common/PageTitle';
import { OrderLines } from 'components/Pages/Orders/_orderDetails/OrderLines';
import { OrderEditMode } from './_common/OrderEditMode';
import { IsChangedOuterContext } from 'components/_common/OuterModal';
import { PageLoader } from 'components/_common/PageLoader';
import { CreateOrderNumberPrefix, FormatDate, FormatOrderNumber } from 'components/_common/Formatters';
import { Phase } from 'components/_common/enums/Phase';
import { QuoteStatus } from 'components/_common/enums/QuoteStatus';
import { UserRole, UserState } from 'components/_common/UserState';
import TextArea from 'antd/es/input/TextArea';
import { ContactSelect } from 'components/_common/ContactSelect';
import { CustomSelect } from 'components/_common/CustomSelect';
import dayjs from 'dayjs';
import { UserContext } from 'components/AppLayout';

interface State {
    orderLines: any,
    requestQuote: boolean
}

interface Props {
    orderId?: number
    editMode: OrderEditMode
    onClose(): void
    onSubmitted(): void
}

export const OrderDetails = (props: Props) => {
    const { isAuthorizedForRole, customerId } = useContext<UserState>(UserContext)
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    const [state, setState] = useState<State>({
        orderLines: [],
        requestQuote: false
    })

    const customerFetcher = MultiFetcher('customer')
    const supplierFetcher = MultiFetcher('supplier')
    const quoteStatusFetcher = MultiFetcher('enumValues/QuoteStatus')
    const deliveryTypeFetcher = MultiFetcher('enumValues/DeliveryType')
    const paymentTypeFetcher = MultiFetcher('enumValues/PaymentType')
    const orderFetcher = MultiFetcher('order')
    const orderFetcherForMaxOrderNr = MultiFetcher('order')
    const orderLineFetcher = MultiFetcher('orderline')

    const getOrderLines = async (orderRevisionId: string) => await orderLineFetcher.get({ queryParams: `orderRevisionId=${orderRevisionId}`})  

    useEffect(() => {
        window.scrollTo(0, 0)

        const promise1 = customerFetcher.get({})
        const promise2 = supplierFetcher.get({})
        const promise3 = quoteStatusFetcher.get({})
        const promise4 = deliveryTypeFetcher.get({})
        const promise5 = paymentTypeFetcher.get({})

        Promise.all([promise1, promise2, promise3, promise4, promise5]).then(([customers, suppliers, quoteStatuses, deliveryTypes, paymentTypes]) => {
            let newRevisionNumber = 0
            let newPhaseId = Phase.Quotation
            let newQuoteStatusId = QuoteStatus.New

            if (props.orderId) {
                orderFetcher.get({id: props.orderId.toString()}).then(order => {
                    getOrderLines(order.lastRevision.id).then(orderLines => {
                        if (props.editMode !== OrderEditMode.Edit){
                            _.forEach(orderLines, orderLine => {
                                orderLine.id = undefined
                                orderLine.orderRevisionId = undefined
                                orderLine.customerUnitPrice = undefined
                                orderLine.customerSetupCost = undefined
                                orderLine.customerMoldCost = undefined
                                _.forEach(orderLine.orderLineDetails, orderLineDetail => {
                                    orderLineDetail.id = undefined
                                    orderLineDetail.orderLineId = undefined
                                })
                                
                            })
                        }
                        setState(prevState => ({ ...prevState, orderLines }))

                        if (props.editMode === OrderEditMode.Edit) {
                            newQuoteStatusId = order.quoteStatusId
                            newPhaseId = order.phaseId
                            newRevisionNumber = order.lastRevision.revisionNumber
                        }
            
                        if (props.editMode === OrderEditMode.ReOrder){
                            form.setFieldValue('id', undefined)
                            onChangeCustomer(order.customerId, customers)
                        }
            
                        if (props.editMode === OrderEditMode.ReQuote){
                            newRevisionNumber = order.lastRevision.revisionNumber + 1
                        }
            
                        form.setFieldValue('orderNumberDisplay', FormatOrderNumber(order.orderNumberPrefix, order.orderNumber, newRevisionNumber))
                        form.setFieldValue('newRevisionNumber', newRevisionNumber)
                        form.setFieldValue('phaseId', newPhaseId)
                        form.setFieldValue('quoteStatusId', newQuoteStatusId)
                    })
                })
            } else {
                form.setFieldValue('newRevisionNumber', newRevisionNumber)
                form.setFieldValue('phaseId', newPhaseId)
                form.setFieldValue('quoteStatusId', newQuoteStatusId)
                form.setFieldValue('deliveryTypeId', 1)
                form.setFieldValue('paymentTypeId', 1)

                //if (props.editMode === OrderEditMode.Add && (userRole === UserRole.CustomerUser || userRole === UserRole.CustomerAdmin)){
                if (customerId)
                {
                    form.setFieldValue('customerId', customerId)
                    onChangeCustomer(customerId, customers)
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMaxOrderNr = async (customerId: string, year: number): Promise<number> => {
        const response = await orderFetcherForMaxOrderNr.get({ 
            actionName: 'GetMaxOrderNumber', 
            queryParams: `customerId=${customerId}&year=${year}`
        })

        return response.result ?? 0
    }

    const onChangeCustomer = async (customerId: number, customers?: []) => {
        const customer = _.find(customers ?? customerFetcher.data, { id: customerId})
        //console.log(`customerData: [${JSON.stringify(customers)}]`)
        //console.log(`found Customer with Id '${customerId}': [${JSON.stringify(customer)}] in data: [${JSON.stringify(customers)}]`)
        
        const currentYear = new Date().getFullYear()
        const prefix = CreateOrderNumberPrefix(customer.orderNumberPrefix, currentYear)   
        const maxOrderNumber = await getMaxOrderNr(`${customerId}`, currentYear)

        //console.log(`max ordernr: [${maxOrderNumber}]`)

        form.setFieldValue('orderNumberPrefix', prefix)
        form.setFieldValue('orderNumber', maxOrderNumber + 1)
        form.setFieldValue('orderNumberDisplay', FormatOrderNumber(prefix, maxOrderNumber + 1, 0))
    }

    const onChangeOrderLines = (orderLines: []) => {      
        setState(prevState => ({ ...prevState, orderLines }))
        setIsChanged(true)
    }
    
    const onSubmit = async (order: any) => {
        //console.log(`Submitting data: ${JSON.stringify(order)}`)

        //-- Set Revision
        let revision: any
        if (props.editMode === OrderEditMode.Edit){
            revision = form.getFieldValue('lastRevision')

        } else {
            revision = {
                orderId: form.getFieldValue('id'),
                revisionNumber: form.getFieldValue('newRevisionNumber')
            }
        }
        // filter out empty orderlinedetails
        const orderLines = state.orderLines
        orderLines.forEach(orderLine => {
            orderLine.OrderLineDetails = orderLine.orderLineDetails.filter(p => p.quantity > 0)
        })
        revision.orderLines = orderLines
        order.revisions = [revision]

        const queryParams = state.requestQuote ? `requestQuote=true` : ''

        const submitAsync = async (order: any):Promise<number> => {
            switch (props.editMode) {
                case OrderEditMode.Add:
                case OrderEditMode.ReOrder:
                    return orderFetcher.post(order, undefined, queryParams)
                case OrderEditMode.Edit:
                case OrderEditMode.ReQuote:
                    return orderFetcher.put(order, undefined, queryParams)
            }
        }

        await submitAsync(order).then((result) => {
            if (result > 0) { 
                props.onClose()
                message.info(`Order ${order.orderNumberDisplay} was ${props.editMode === OrderEditMode.Edit ? 'Updated' : 'Created'}${state.requestQuote ? ', and a Quote was requested.' : '.'}`)
                props.onSubmitted()
            }
        })
    }

    return (
        <>
            <PageLoader loading={orderFetcher.isLoading}>
                <Form 
                    layout='horizontal'
                    labelCol={{ span: 4, xl: 6}}
                    wrapperCol={{ span: 20, xl: 18}}
                    initialValues={orderFetcher.data}
                    style={{ padding: '10px'}}
                    form={form}
                    onFinish={onSubmit}
                    onValuesChange={() => setIsChanged(true)} 
                    onReset={() => form.resetFields()}>
                        <Form.Item label='id' name='id' hidden={true}><Input/></Form.Item>
                        <Form.Item label='orderNumber' name='orderNumber' hidden={true}><Input/></Form.Item>
                        <Form.Item label='orderNumberPrefix' name='orderNumberPrefix' hidden={true}><Input/></Form.Item>
                        <Form.Item label='lastRevision' name='lastRevision' hidden={true}><Input/></Form.Item>
                        <Form.Item label='newRevisionNumber' name='newRevisionNumber' hidden={true}><Input/></Form.Item>
                        <Form.Item label='phaseId' name='phaseId' hidden={true}><Input/></Form.Item>
                        <Row>
                            <Col xxl={11} span={24}>                        
                                <Form.Item label="Customer" name='customerId' rules={[{ required: true, message: 'Customer is required.' }]} >
                                    <CustomSelect data={customerFetcher.data} loading={customerFetcher.isLoading} required style={{ width: 400 }} placeholder='select a customer' onChange={(value) => onChangeCustomer(value)}
                                        disabled={props.editMode === OrderEditMode.ReQuote || orderFetcher.data?.lastRevision?.revisionNumber > 0} />
                                </Form.Item>
                            </Col>
                            <Col xxl={2} span={0} />
                            <Col xxl={11} span={24}>
                                <Form.Item label="Order Nr" name='orderNumberDisplay'>
                                    <Input style={{maxWidth: 150}} readOnly disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={11} span={24}>
                                <Form.Item label="Contact" name='contactId' rules={[{ required: true, message: 'Contact is required.' }]}>
                                    <ContactSelect style={{ maxWidth: '400px'}} /> 
                                </Form.Item>
                            </Col>
                            <Col xxl={2} span={0} />
                            <Form.Item name='phaseId' hidden={true}><Input/></Form.Item>
                            <Col xxl={11} span={24}>
                                <Form.Item label="Customer OrderNr" name='customerOrderNumber' rules={[{ required: true, message: 'Customer OrderNr. is required.' }]}>
                                    <Input style={{maxWidth: 250}} maxLength={25}/>
                                </Form.Item>       
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={11} span={24}>
                                <Form.Item label="Purchaser" name='purchaserId'>
                                    <ContactSelect style={{ maxWidth: '400px'}} rolename='Purchaser' /> 
                                </Form.Item>
                            </Col>
                            <Col xxl={2} span={0} />
                            <Col xxl={11} span={24}>
                                <Form.Item label="Quote Status" name='quoteStatusId' rules={[{ required: true, message: 'quote Status is required.' }]}>
                                    <CustomSelect data={quoteStatusFetcher.data} loading={quoteStatusFetcher.isLoading} required style={{ width: 200 }} placeholder='select a quote status'
                                        disabled={props.editMode === OrderEditMode.ReQuote || !isAuthorizedForRole(UserRole.SuperAdmin)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={11} span={24}>
                                <Form.Item label="Supplier" name='supplierId' rules={[{ required: true, message: 'Supplier is required.' }]}>
                                    <CustomSelect data={supplierFetcher.data} loading={supplierFetcher.isLoading} required style={{ width: 400 }} placeholder='select a supplier'
                                        disabled={props.editMode === OrderEditMode.ReQuote || orderFetcher.data?.lastRevision?.revisionNumber > 0} />
                                </Form.Item>
                            </Col>
                            <Col xxl={2} span={0} />
                            <Col xxl={11} span={24}>
                                <Form.Item label="Target Date" name="targetDate" 
                                    getValueProps={(value) => ({value: value ? dayjs(value) : ''})} 
                                    getValueFromEvent={(value) => FormatDate(value)}>
                                    <DatePicker format='YYYY-MM-DD' disabled={!isAuthorizedForRole(UserRole.CustomerUser)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={11} span={24}>
                                <Form.Item label="Description" name="description" >
                                    <Input maxLength={100}/>
                                </Form.Item>
                            </Col>
                            <Col xxl={2} span={0} />
                            <Col xxl={11} span={24}>
                                <Form.Item label="Delivery Type" name='deliveryTypeId' rules={[{ required: true, message: 'Delivery Type is required.' }]}>
                                    <CustomSelect data={deliveryTypeFetcher.data} loading={deliveryTypeFetcher.isLoading} required style={{ width: 200 }} placeholder='select a Delivery Type' />
                                </Form.Item>
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col xxl={11} span={24}>
                                <Form.Item label="Notes" name='notes' >
                                    <TextArea maxLength={500} rows={2} />
                                </Form.Item>
                            </Col>
                            <Col xxl={2} span={0} />
                            <Col xxl={11} span={24}>
                                <Form.Item label="Payment Type" name='paymentTypeId' rules={[{ required: true, message: 'Payment Type is required.' }]}>
                                    <CustomSelect data={paymentTypeFetcher.data} loading={paymentTypeFetcher.isLoading} required style={{ width: 200 }} placeholder='select a Payment Type' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <button ref={submitRef} type="submit" className='hide' />
                    </Form>
            </PageLoader>

            <Divider style={{marginTop: 0}} />

            <PageSubTitle>Order Lines</PageSubTitle>

            <OrderLines
                orderLines={state.orderLines}
                isLoading={false}
                onChange={onChangeOrderLines} />

            { (props.editMode !== OrderEditMode.Edit || (props.editMode === OrderEditMode.Edit && orderFetcher.data?.quoteStatusId === QuoteStatus.New)) && 
                <div style={{ float: 'right', marginRight: 0}}>
                            <Checkbox 
                                title='Request Quote' 
                                checked={state.requestQuote} 
                                onChange={(e) => {
                                    setState(prevState => ({ ...prevState, requestQuote: e.target.checked }))
                                    setIsChanged(true)
                                }} 
                                style={{ display: 'flex', justifyContent: 'right'}}>Request Quote</Checkbox>
                </div>
            }
        </>
    )
}