import React from 'react'
import { InputNumber, InputNumberProps } from 'antd'

export const PercentageInput = (props: InputNumberProps<number>) => {
    const formatter = (value: number | undefined) : string => {
        return value 
            ? `${value}`.replace(/(\d+\.\d*?[1-9])0+$/g, '')
            : ''
    }
    return (
        <InputNumber 
            {...props}
            style={{ width: 110 }}
            addonAfter='%'
            min={0}
            max={9999}
            precision={5}
            formatter={formatter}
            controls={false}
            size='small'
        />
    )
}