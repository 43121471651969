import React from 'react';
import { Col, Form, Input, Row, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLoader } from 'components/_common/PageLoader';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { PageTitle } from 'components/_common/PageTitle';
import { CustomButton } from 'components/_common/CustomButtons';

export const ChangePassword = () => {   
    const navigate = useNavigate();
    const { id } = useParams();
    const [ form ] = Form.useForm();

    const fetcher  = MultiFetcher('security/changepassword');   

    const Submit = async (data: any) => {
        const submitAsync = async (data: any): Promise<number> => {
            return fetcher.post(data);
        }

        await submitAsync(data).then((result) => {
            if (result > 0) {
                navigate('/')
                message.info('Your password has been changed.')
            }
        })
    }

    return (
        <>
            <PageTitle>Change Password</PageTitle> 

            <PageLoader loading={fetcher.isLoading}>
                <Form 
                    layout='horizontal'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ padding: '10px'}}
                    form={form}
                    onFinish={Submit}
                    autoComplete='off'>
                    <Form.Item label="Current Password" name="currentpassword" rules={[{ required: id == null, message: 'Current Password is required.' }]} >
                        <Input.Password style={{maxWidth: 400}} maxLength={50} autoComplete='new-password'></Input.Password>
                    </Form.Item>
                    <Form.Item label="New Password" name="newpassword" rules={[{ required: id == null, message: 'New Password is required.' }]} >
                        <Input.Password style={{maxWidth: 400}} maxLength={50} autoComplete='new-password'></Input.Password>
                    </Form.Item>
                    <Form.Item label="Repeat New Password" name="newpassword2" rules={[
                        { required: id == null, message: 'Repeat New Password is required.' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newpassword') === value) {
                                return Promise.resolve();
                                }
                                return Promise.reject(new Error('New Passwords do not match!'));
                            }
                        })]}>
                        <Input.Password style={{maxWidth: 400}} maxLength={50} autoComplete='new-password'></Input.Password>
                    </Form.Item>
                </Form>
            </PageLoader>
                
            <br/>

            <Row>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Cancel' onClick={() => navigate(-1)} />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Save' onClick={() => form.submit()} />
                </Col>
            </Row>

        </>
    )
}