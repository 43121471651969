import React, { useEffect, useRef, useState } from 'react';
import { Modal, Table } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { FormatCurrency } from 'components/_common/Formatters';
import { CheckOutlined } from '@ant-design/icons';
import { CustomButton, TableRowButton } from 'components/_common/CustomButtons';
import { useReactToPrint } from 'react-to-print';
import { ContentType } from 'components/_common/enums/ContentType';

interface Props {
    orderId: number
}

interface State {
    showDocumentHtmlModal: boolean
    documentTitle?:string
    documentHtml?: string
}

export const InvoicesViewer = (props: Props) => {
    const [state, setState] = useState<State>({
        showDocumentHtmlModal: false,
        documentTitle: undefined,
        documentHtml: undefined,
    })
    
    const refPrintableArea = useRef(null)
    
    const invoicesFetcher = MultiFetcher('invoice')

    useEffect(() => {
        invoicesFetcher.get({ 
            actionName: 'GetAllForOrder', 
            queryParams: `orderId=${props.orderId}`
        })        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //== Invoice ==
    const documentFetcher = MultiFetcher('document')

    const closeDocumentHtmlModal = () => setState(prevState => ({ ...prevState, showDocumentHtmlModal: false, orderId: undefined, orderNr: undefined }))

    const showInvoice = (paymentTermId: number, invoiceNr: string) => {
        documentFetcher.get({ actionName: 'InvoiceHtml', queryParams: `orderId=${props.orderId}&paymentTerm=${paymentTermId}`, contentType: ContentType.Text }).then((response) => {
            //console.log(`response: ${response}`)
            const documentTitle = `Invoice ${invoiceNr}`
            setState(prevState => ({ ...prevState, documentTitle, documentHtml: response, showDocumentHtmlModal: true }))
        })
    }

    const printDocumentHtml = useReactToPrint({
        content: () => refPrintableArea.current
    })

    return (
        <>
            <Table
                loading={invoicesFetcher.isLoading}
                dataSource={invoicesFetcher.data}
                rowKey='paymentTermId'
                size='small'
                pagination={false}
                className='table-striped-rows' 
                style={{ width: 'auto', paddingBottom: '10px'}} 
                columns={[
                    {
                        title: 'Invoice Nr.',
                        dataIndex: 'invoiceNr',
                        key: 'invoiceNr',
                        width: '20%'
                    },
                    {
                        title: 'Term',
                        dataIndex: 'paymentTermName',
                        key: 'paymentTermName',
                        width: '20%'
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                        align: 'right',
                        width: '10%',
                        render: (value) => <div className='nowrap'>{FormatCurrency(value)}</div>
                    },
                    {
                        title: 'Due',
                        dataIndex: 'due',
                        key: 'due',
                        align: 'center',
                        width: '20%',
                        render: (value) => value ? <CheckOutlined style={{ color: 'green'}} /> : undefined
                    },
                    {
                        title: 'Paid',
                        dataIndex: 'paid',
                        key: 'paid',
                        align: 'center',
                        width: '20%',
                        render: (value) => value ? <CheckOutlined style={{ color: 'green'}} /> : undefined
                    },
                    {
                        title: 'Verified',
                        dataIndex: 'verified',
                        key: 'verified',
                        align: 'center',
                        width: '20%',
                        render: (value) => value ? <CheckOutlined style={{ color: 'green'}} /> : undefined
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        width: 50,
                        render: (record: any) => <TableRowButton buttontype='View' onClick={(e) => showInvoice(record.paymentTermId, record.invoiceNr)} entity='Invoice'/>
                    }
                ]}
                //rowClassName={(record: any, index) => (!record.orderId && record.quantity === 0) ? 'hide' : ( index % 2 === 1 ? 'table-striped-row' :  '')}
                
            />

            <Modal title={state.documentTitle}
                open={state.showDocumentHtmlModal} 
                width={1440}
                //className='progress-modal'
                onCancel={() => closeDocumentHtmlModal()}
                maskClosable={false}
                destroyOnClose
                footer={
                    <>
                        <CustomButton 
                            buttontype='Close' 
                            onClick={closeDocumentHtmlModal} />
                        <CustomButton 
                            buttontype='Print'
                            onClick={printDocumentHtml} />
                    </>
                }>
                    <div style={{ border: '1px solid silver', maxHeight: 'calc(80vh - 70px)', overflow: 'auto' }}>
                        <div ref={refPrintableArea} dangerouslySetInnerHTML={{ __html: state.documentHtml ?? ''}} />
                    </div>
            </Modal>
        </>
    )
}
    