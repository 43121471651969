import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Space, Table, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { UserState, UserRole } from 'components/_common/UserState';
import { UserContext } from 'components/AppLayout';
import { FormatCurrency, FormatDate } from 'components/_common/Formatters';
import { ImageDownloadLink } from 'components/_common/ImageDownloadLink';
import { PaymentView } from './_common/PaymentView';
import { PaymentEdit } from './_common/PaymentEdit';
import { dateCompare } from 'components/_common/Generic';
import { OuterModal } from 'components/_common/OuterModal';

interface State {   
    viewPaymentId?: number
    editPaymentId?: number
    paymentReference?: string
}

export const PaymentList = () => {
    const { isAuthorizedForRole } = useContext<UserState>(UserContext)

    const [state, setState] = useState<State>({
        viewPaymentId: undefined,
        editPaymentId: undefined,
        paymentReference: undefined
    })
    
    const fetcher = MultiFetcher('payment');  

    useEffect(() => {
        window.scrollTo(0, 0);
        fetcher.get({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const viewPayment = (id: number) => setState(prevState => ({ ...prevState, viewPaymentId: id }))
    const editPayment = (id: number, reference: string) => setState(prevState => ({ ...prevState, editPaymentId: id, paymentReference: reference }))
    const onRefresh = () => fetcher.get({})

    const deleteItem = async (id: number, reference: string) => {
        fetcher.del(id).then(async (success) => {
            if (success === true) {
                await fetcher.get({})
                message.info(`Payment '${reference} 'was deleted.`)
            }
        })
    }

    return (
        <>
            <Table
                loading={fetcher.isLoading}
                rowKey={'id'}
                size='small'
                pagination={{ defaultPageSize: 12, size: 'default' }}
                showSorterTooltip={{ placement: 'bottom' }}
                columns={[
                    {
                        title: 'Date',
                        dataIndex: 'paymentDate',
                        key: 'paymentDate',
                        sorter: (a: any, b: any) => dateCompare(a.paymentDate, b.paymentDate),
                        render: (value: string) => <div>{FormatDate(value)}</div>
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                        render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>
                    },
                    {
                        title: 'Reference',
                        dataIndex: 'reference',
                        key: 'reference',
                        render: (value: string, record: any) => 
                            <Row style={{ width: '100%'}}>
                                <Col className='nowrap' span={12}>{value}</Col>
                                { isAuthorizedForRole(UserRole.SuperAdmin) &&
                                <Col span={12}>
                                    <TableRowButton buttontype='Edit' onClick={() => {editPayment(record.id, record.reference)}} title='Edit Reference' />
                                </Col>
                                }
                            </Row>
                    },
                    {
                        title: 'Proof',
                        key: 'imageFileName',
                        render: (record: any) => <ImageDownloadLink imageId={record.imageId} fileName={record.imageFileName} />
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        render: (record: any) =>
                            <div>
                                <Space>
                                    <TableRowButton buttontype='View' onClick={(e) => viewPayment(record.id)} entity='Payment'/>
                                    { isAuthorizedForRole(UserRole.SuperAdmin) &&
                                        <TableRowButton buttontype='Delete' onClick={() => deleteItem(record.id, record.reference)} entity='Payment' hasconfirm />
                                    }
                                </Space>
                            </div>
                    }
                ]}
                dataSource={fetcher.data} 
                style={{ paddingBottom: '10px'}} 
                className='table-striped-rows' /> 

                <OuterModal
                    title="View Payment Details" 
                    open={state.viewPaymentId !== undefined} 
                    width='80vw'
                    style={{ maxWidth: '1024px'}}
                    onClose={() => setState(prevState => ({ ...prevState, viewPaymentId: undefined }))} 
                    saveButtonProps={{ buttontype: 'Save', style: { display:'none'}}}>
                        { state.viewPaymentId && 
                            <div style={{width: '100%'}}>
                                <PaymentView paymentId={state.viewPaymentId} onClose={() => setState(prevState => ({ ...prevState, viewPaymentId: undefined }))}/>
                            </div>
                        }
                </OuterModal>

                <OuterModal
                    title="Edit Payment Reference" 
                    open={state.editPaymentId !== undefined} 
                    width='50vw'
                    style={{ maxWidth: '600px'}}
                    onClose={() => setState(prevState => ({ ...prevState, editPaymentId: undefined }))} >
                        { state.editPaymentId && 
                            <div style={{width: '100%'}}>
                                <PaymentEdit paymentId={state.editPaymentId} onClose={() => setState(prevState => ({ ...prevState, editPaymentId: undefined }))} onRefresh={onRefresh} />
                            </div>
                        }
                </OuterModal>
        </> 
    )
}