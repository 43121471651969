import React, { useContext, useEffect, useState } from 'react';
import { Form, message, Checkbox } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import * as _ from 'lodash';
import { QuoteRequestLineList } from './QuoteRequestLineList';
import { OrderHeaderViewer } from 'components/_common/OrderHeaderViewer';
import { UserRole, UserState } from 'components/_common/UserState';
import { UserContext } from 'components/AppLayout';
import { QuoteStatus } from 'components/_common/enums/QuoteStatus';
import { delay } from 'components/_common/Generic';
import { Phase } from 'components/_common/enums/Phase';
import { Unauthorized } from '../Security/Unauthorized';
import { PageLoader } from 'components/_common/PageLoader';
import { IsChangedOuterContext } from 'components/_common/OuterModal';

interface State {
    isPriced: boolean
}

interface Props {
    id: number|undefined
    onClose(): void
    onRefresh(): void
}

export const QuoteRequestDetails = (props: Props) => {
    const { userRole, isAuthorizedForRole } = useContext<UserState>(UserContext)
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    const [state, setState] = useState<State>({
        isPriced: false
    });
   
    const quoteFetcher = MultiFetcher('quoterequest')  
    const quoteActionsFetcher = MultiFetcher('quoteActions')
       
    useEffect(() => {
        window.scrollTo(0, 0)
        
        if (props.id){
            quoteFetcher.get({ id: props.id?.toString() }).then(quote => {
                if (quote.phaseId === Phase.Quotation && quote.quoteStatusId === QuoteStatus.Requested && userRole === UserRole.SupplierUser){
                    // Auto-Update status to 'Viewed'
                    const data = { orderId: props.id }
                    quoteActionsFetcher.post(data, 'ViewedQuote').then(async (success) => {
                        if (success){
                            await delay(250)
                            quoteFetcher.get({ id: props.id?.toString() })
                            message.info(`Quote status was automatically updated to 'Viewed'.`)
                        }
                    })
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async () => {
        const data = Object.assign({}, quoteFetcher.data)

        data.lastRevision.orderLines = updateOrderLinesWithFormData(data.lastRevision.orderLines)
        data.revisions = [ data.lastRevision ]
        data.lastRevision = undefined

        const submitAsync = async (data: any):Promise<number> => {
            return await (state.isPriced ? quoteFetcher.post(data) : quoteFetcher.put(data))
        }

        await submitAsync(data).then((result) => {
            if (result > 0) { 
                props.onClose()
                props.onRefresh()
                message.info(`Quote prices/costs have been ${state.isPriced ? 'saved and finished.' : 'saved.'}`)
            }
        })
    }

    const updateOrderLinesWithFormData = (orderLines: any[]) : any[] => {
        _.forEach(orderLines, orderLine => {
            if (!orderLine.quotePrice) orderLine.quotePrice = {}

            orderLine.quotePrice.minimumOrderQuantity = form.getFieldValue(`moq_${orderLine.id}`)
            orderLine.quotePrice.supplierUnitPrice = form.getFieldValue(`unitPrice_${orderLine.id}`)
            orderLine.quotePrice.supplierSetupCost = form.getFieldValue(`setupCost_${orderLine.id}`)
            orderLine.quotePrice.supplierMoldCost = form.getFieldValue(`moldCost_${orderLine.id}`)
        })

        return orderLines
    }

    const setIsPriced = (value: boolean) => setState(prevState => ({ ...prevState, isPriced: value }))

    return (
        <>
            { !isAuthorizedForRole(UserRole.SupplierUser)
                ? <Unauthorized /> 
                : <>
                    <OrderHeaderViewer order={quoteFetcher.data} loading={quoteFetcher.isLoading} />

                    <PageLoader loading={quoteFetcher.isLoading}>
                        { quoteFetcher.data && 
                            <>
                                <Form form={form} onFinish={onSubmit} onValuesChange={() => setIsChanged(true)} >
                                    <QuoteRequestLineList
                                        orderLines={quoteFetcher.data?.lastRevision.orderLines}
                                        previousOrderLines={quoteFetcher.data?.secondLastRevision?.orderLines}
                                        isLoading={quoteFetcher.isLoading}
                                        finish={state.isPriced}
                                        form={form}
                                        supplierId={quoteFetcher.data?.supplierId ?? 0} 
                                        supplierName={quoteFetcher.data?.supplierName ?? ''} />
                                        <button ref={submitRef} type="submit" className='hide' />
                                </Form>

                                <Checkbox
                                    title='Send Offer' 
                                    checked={state.isPriced} 
                                    onChange={(e) => {
                                        setIsPriced(e.target.checked)
                                        setIsChanged(true)
                                    }}
                                    style={{ display: 'flex', justifyContent: 'right'}}>Send Offer</Checkbox>
                            </>
                        }
                    </PageLoader>
                </>
            }
        </>
    )
}