/* eslint-disable react-hooks/exhaustive-deps */
import { Menu } from "antd"
import { ItemType } from "antd/es/menu/hooks/useItems"
import React, { useCallback, useEffect, useState } from "react"
import { PageLoader } from "./PageLoader"
import { CustomButton } from "./CustomButtons"

interface CategoryTreeMenuProps {
    categories: Category[]
    width?: string
    loading: boolean
    onChange(value: string): void
}

interface State {
    menuItems: ItemType[] | undefined
    selectedKeys: string[] | undefined
    openKeys: string[] | undefined
}

export const CategoryTreeMenu = (props: CategoryTreeMenuProps) => {
    const [state, setState] = useState<State>({
        menuItems: [],
        selectedKeys: [localStorage.getItem('selectedProductCategoryId') ?? ''],
        openKeys: JSON.parse(localStorage.getItem('openProductCategoryIds') ?? '[]'),
    });

    const getSubItems = useCallback((id: number | null) : ItemType[] | undefined => {
        const items: ItemType[] = []
    
        //const rootCategories = props.categories.filter(cat => id === null || cat.parentCategoryId === id)
        const rootCategories = props.categories.filter(cat => id == null ? !(cat.parentCategoryId) : cat.parentCategoryId === id)
    
        rootCategories.forEach(cat => {
            //console.log(`cat: ${JSON.stringify(cat)}`)
            const item = { 
                key: cat.id.toString(),
                label: cat.name + (cat.productCount != null ? ` (${cat.productCount})` : ''),
                title: cat.name + (cat.productCount != null ? ` (${cat.productCount})` : ''),
                children: getSubItems(cat.id),
                onTitleClick: onClick
            }

            items.push(item)
        })

        //console.log(`menu items: ${JSON.stringify(items)}`)
        return items.length > 0 ? items : undefined
    }, [props.categories])

    useEffect(() => {
        //console.log(`Categories loaded: ${JSON.stringify(props.categories)}`)
        if (props.categories) {
            const menuItems = getSubItems(null)
            setState(prevState => ({ ...prevState, menuItems }));
        }

        if (state.selectedKeys)
            props.onChange(state.selectedKeys[0])

    }, [props.categories]);

    const onClick = (item: any) => {
        //console.log(`key: ${item}`)
        props.onChange(item.key)

        setState(prevState => ({ ...prevState, selectedKeys: [item.key] }));
        localStorage.setItem('selectedProductCategoryId', item.key.toString())
    }

    const onOpenChange = (openKeys: string[]) => {
        setState(prevState => ({ ...prevState, openKeys }));
        localStorage.setItem('openProductCategoryIds', JSON.stringify(openKeys))
    }

    const onClear = () => {
        props.onChange('')
        setState(prevState => ({ ...prevState, selectedKeys: [], openKeys: [] }));
        localStorage.setItem('selectedProductCategoryId', '')
        localStorage.setItem('openProductCategoryIds', '[]')
    }

    return (
        <PageLoader loading={props.loading}>
            <Menu
                mode="inline"
                style={{ width: props.width }}
                items={state.menuItems}
                onClick={onClick}
                onOpenChange={onOpenChange}
                openKeys={state.openKeys}
                selectedKeys={state.selectedKeys}
                />
                <CustomButton buttontype='Close' title='Clear Selection' onClick={onClear} size='small'>Clear Selection</CustomButton>
        </PageLoader>
      )
}

interface Category {
    id: number
    parentCategoryId: number | null
    name: string
    productCount: number | null
}