import React, { useContext, useEffect, useState } from 'react'
import { Col, Row, Space, Table, message } from 'antd'
import { TableRowButton } from 'components/_common/CustomButtons'
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { ProductVariationDetails } from './ProductVariationDetails';
import { GenerateVariations } from './GenerateVariations';
import * as _ from 'lodash'
import { ImageDownloadLink } from 'components/_common/ImageDownloadLink';
import { PageSubTitle } from 'components/_common/PageTitle';
import { ImportVariations } from './ImportVariations';
import { UserRole, UserState } from 'components/_common/UserState';
import { UserContext } from 'components/AppLayout';
import { FormatCurrency } from 'components/_common/Formatters';
import { numberCompare } from 'components/_common/Generic';
import { OuterModal } from 'components/_common/OuterModal';
import { ColumnType } from 'antd/es/table/interface';

interface Props {
    isLoading: boolean
    variations: any
    setVariations(variations: any): void
}

interface State {
    isLoading: boolean
    selectedIndex?: number
    selectedVariation: any
    selectedVariationKeys: React.Key[]
    showDetailsModal: boolean
    showGenerateModal: boolean
    showImportModal: boolean
}

export const ProductVariationList = (props:Props) => {
    const { isAuthorizedForRole } = useContext<UserState>(UserContext)
    
    const [state, setState] = useState<State>({
        isLoading: true,
        selectedIndex: undefined,
        selectedVariation: undefined,
        selectedVariationKeys: [],
        showDetailsModal: false,
        showGenerateModal: false,
        showImportModal: false
    })

    const sizeFetcher = MultiFetcher('productsize')
    const threadTypeFetcher = MultiFetcher('productthreadtype')
    const tempFetcher = MultiFetcher('producttemperature')
    
    useEffect(() => {
        window.scrollTo(0, 0)

        const promise1 = sizeFetcher.get({})
        const promise2 = threadTypeFetcher.get({})
        const promise3 = tempFetcher.get({})

        Promise.all([promise1, promise2, promise3]).then(() => {
            setState(prevState => ({ ...prevState, isLoading: false }))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const lookupName = (data: any, id: string): string => lookup(data, id)?.name
    const lookupSizeInMm = (data: any, id: string): string => lookup(data, id)?.sizeInMm
    const lookup = (data: any, id: string): any => {
        if (id) {
             let item = data?.filter((item: { id: string; }) => item.id === id)
             //console.log(`item ${JSON.stringify(item)}`)
             return item[0]
        }
    }

    const getImage = (index: number, type: string) => {
        const images = props.variations[index].images
        const image = _.find(images, image => image.imageTypeName === type)
        //console.log(`found image: ${JSON.stringify(image)}`)

        return image
    }

    //== Variations ==
    const showVariationDetailsModel = (visible: boolean) => {
        setState(prevState => ({ ...prevState, showDetailsModal: visible }))
    }
    
    const onAddVariation = () => {
        setState(prevState => ({ ...prevState, selectedIndex: undefined, selectedVariation: {} }))
        showVariationDetailsModel(true)
    }

    const onEditVariation = (index: number) => {
        const variation = props.variations[index]
        setState(prevState => ({ ...prevState, selectedIndex: index, selectedVariation: variation }))
        showVariationDetailsModel(true)
    }

    const onDeleteVariation = (index: number) => {
        let variations = props.variations.slice(0);
        variations.splice(index, 1)
        props.setVariations(variations)
    }

    //== Multi-Select and Delete ==
    const onSelectedVariationsChange = (newSelectedRowKeys: React.Key[]) => {
        setState(prevState => ({ ...prevState, selectedVariationKeys: newSelectedRowKeys }))
        //console.log(`selected keys: [${JSON.stringify(newSelectedRowKeys)}]`)
    }

    const onDeleteSelectedVariations = () => {
        //console.log(`selected keys: [${JSON.stringify(state.selectedVariationKeys)}]`)
        let variations = props.variations.slice(0);
        _.forEach(state.selectedVariationKeys, key => {
            const variation = _.find(variations, p => p.id === key)
            const index = _.indexOf(variations, variation)
            //console.log(`index: [${index}]`)
            variations.splice(index, 1)
        })
        
        props.setVariations(variations)
        setState(prevState => ({ ...prevState, selectedVariationKeys: [] }))
    }

    //== Generate Variations ==
    const showGenerateModel = (visible: boolean) => setState(prevState => ({ ...prevState, showGenerateModal: visible }))

    const onFinishGenerate = (newVariations: any) => {
        //console.log(`value: ${JSON.stringify(value)}`)
        let variations = props.variations.slice(0)
        
        _.forEach(newVariations, newVariation => {
            variations.push(newVariation)
        })
        
        props.setVariations(variations)
        showGenerateModel(false)
        message.info(`${newVariations.length} variations were created.`, 10)
    }

    //== Import Variations ==
    const showImportModel = (visible: boolean) => setState(prevState => ({ ...prevState, showImportModal: visible }))

    const onFinishImport = (newVariations: any) => {
        let variations = props.variations.slice(0)
        
        _.forEach(newVariations, newVariation => {
            variations.push(newVariation)
        })

        props.setVariations(variations)
        showImportModel(false)
        message.info(`${newVariations.length} variations were imported.`, 10)
    }

    // == Update Variations ===
    const onSaveVariation = (variation: any) => {
        //console.log(`variation: ${JSON.stringify(variation)}`)
        let variations = props.variations.slice(0);

        if (state.selectedIndex !== undefined) {
            //console.log(`updating..`)
            variations[state.selectedIndex] = variation
        } else {
            //console.log(`adding..`)
            variations.push(variation)
        }
        
        props.setVariations(variations)
        showVariationDetailsModel(false)
    }

    //==== Highlight Column Functions ====
    const isDuplicate = (value: string, property: 'PartNumber' | 'DrawingNumber' | 'GuptaPriceWithoutSetup' | 'GuptaPriceWithSetup'):boolean => {
        if (!value) return false
        const predicate = (v: any) => {
            switch (property) {
                case 'PartNumber': return v.partNumber === value
                case 'DrawingNumber': return v.drawingNumber === value
                case 'GuptaPriceWithoutSetup': return v.guptaPriceWithoutSetup === value
                case 'GuptaPriceWithSetup': return v.guptaPriceWithSetup === value
            }
        }

        const firstIndex = _.findIndex(props.variations, (v:any) => predicate(v));
        const lastIndex = _.findLastIndex(props.variations, (v:any) => predicate(v));

        return (firstIndex !== lastIndex)
    }

    return (
        <>
            <Row>
                <Col span={12}>
                    <PageSubTitle>
                        Variations <span style={{fontSize: 12, color: 'silver !important'}}>[{props.variations?.length}]</span>
                    </PageSubTitle>
                </Col>
                <Col span={0} lg={{ span: 12}}>
                    <div style={{ position: 'absolute', bottom: 10, right: 8 }}>
                        { isAuthorizedForRole(UserRole.SuperAdmin) && 
                            <Space>
                                <TableRowButton buttontype='Build' onClick={() => showGenerateModel(true)} title='Generate Variations' />
                                <TableRowButton buttontype='Import' onClick={() => showImportModel(true)} title='Import Variations' />
                            </Space>
                        }
                    </div>
                </Col>
            </Row>
            
            <Table
                loading={props.isLoading && state.isLoading}
                rowKey={record => record.id ?? `${record.partNumber}_${record.drawingNumber}_${record.size1Id}_${record.size2Id}_${record.threadType1Id}_${record.threadType2Id}_${record.temperatureId}`}
                size='small'
                bordered
                rowSelection={{ 
                    type: 'checkbox',
                    selectedRowKeys: state.selectedVariationKeys,
                    onChange: onSelectedVariationsChange
                }}
                columns={[
                    {
                        title: 'Part No',
                        dataIndex: 'partNumber',
                        key: 'partNumber',
                        sorter: (a:any,b:any) => a.partNumber?.localeCompare(b.partNumber, undefined, { numeric: true, sensitivity: 'base' }),
                        render: (text) => <span className={isDuplicate(text, 'PartNumber') ? 'table-highlight' : ''}>{text}</span> 
                    },
                    {
                        title: 'Drawing No',
                        dataIndex: 'drawingNumber',
                        key: 'drawingNumber',
                        sorter: (a:any,b:any) => a.drawingNumber?.localeCompare(b.drawingNumber, undefined, { numeric: true, sensitivity: 'base' }),
                        render: (text) => <span className={isDuplicate(text, 'DrawingNumber') ? 'table-highlight' : ''}>{text}</span> 
                    },
                    { 
                        title: 'Size (mm)',
                        children: [
                            {
                                title: '1',
                                dataIndex: 'size1InMm',
                                key: 'size1',
                                align: 'right',
                                render: (value: string, record: any) => <div>{value ?? lookupSizeInMm(sizeFetcher.data, record.size1Id)}</div>,
                                sorter: (a:any,b:any) => numberCompare(a.size1InMm ?? lookupSizeInMm(sizeFetcher.data, a.size1Id), b.size1InMm ?? lookupSizeInMm(sizeFetcher.data, b.size1Id))
                            },
                            {
                                title: '2',
                                dataIndex: 'size2InMm',
                                key: 'size2',
                                align: 'right',
                                render: (value: string, record: any) => <div>{value ?? lookupSizeInMm(sizeFetcher.data, record.size2Id)}</div>,
                                sorter: (a:any,b:any) => numberCompare(a.size2InMm ?? lookupSizeInMm(sizeFetcher.data, a.size2Id), b.size2InMm ?? lookupSizeInMm(sizeFetcher.data, b.size2Id))
                            },
                        ]
                    },
                    { 
                        title: 'ThreadType',
                        children: [
                            {
                                title: <span style={{ margin: 'auto'}}>1</span>,
                                dataIndex: 'threadType1Name',
                                key: 'threadType1',
                                render: (value: string, record: any) => <div>{value ?? lookupName(threadTypeFetcher.data, record.threadType1Id)}</div>,
                                align: 'right',
                                sorter: (a:any,b:any) => (a.threadType1Name ?? lookupName(threadTypeFetcher.data, a.threadType1Id)?.localeCompare(b.threadType1Name ?? lookupName(threadTypeFetcher.data, b.threadType1Id)))
                            },
                            
                            {
                                title: 2,
                                dataIndex: 'threadType2Name',
                                key: 'threadType2',
                                render: (value: string, record: any) => <div>{value ?? lookupName(threadTypeFetcher.data, record.threadType2Id)}</div>,
                                align: 'right',
                                sorter: (a:any,b:any) => (a.threadType2Name ?? lookupName(threadTypeFetcher.data, a.threadType2Id)?.localeCompare(b.threadType2Name ?? lookupName(threadTypeFetcher.data, b.threadType2Id)))
                            },
                        ]
                    },
                    {
                        title: 'Temp.',
                        dataIndex: 'temperatureId',
                        key: 'temperature',
                        render: (value: string) => <div>{lookupName(tempFetcher.data, value)}</div>,
                        sorter: (a:any,b:any) => lookupName(tempFetcher.data, a.temperatureId)?.localeCompare(lookupName(tempFetcher.data, b.temperatureId)),
                        responsive: ['xl']
                    },
                    {
                        title: 'Technical Drawing',
                        dataIndex: 'technicalDrawing',
                        key: 'technicaldrawing',
                        render: (text, record, index: number) => {
                            const image = getImage(index, 'TechnicalDrawing')
                            if (image?.imageId)
                                return <ImageDownloadLink imageId={image.imageId} fileName={image.fileName} />
                            else
                                return <div>{image?.fileName}</div>
                        },
                        responsive: ['xl']
                    },
                    {
                        title: 'Step File',
                        dataIndex: 'stepFile',
                        key: 'stepfile',
                        render: (text, record, index: number) => {
                            const image = getImage(index, 'StepFile')
                            if (image?.imageId)
                                return <ImageDownloadLink imageId={image.imageId} fileName={image.fileName} />
                            else
                                return <div>{image?.fileName}</div>
                        },
                        responsive: ['xl']
                    },
                    {
                        title: 'Box Label',
                        dataIndex: 'boxLabel',
                        key: 'boxLabel',
                        render: (text, record, index: number) => {
                            const image = getImage(index, 'BoxLabelImage')
                            if (image?.imageId)
                                return <ImageDownloadLink imageId={image.imageId} fileName={image.fileName} />
                            else
                                return <div>{image?.fileName}</div>
                        },
                        responsive: ['xl']
                    },
                    ...isAuthorizedForRole(UserRole.SuperAdmin) ?
                    [{
                        title: 'Gupta Price',
                        children: [
                            {
                                title: 'w/o Setup',
                                dataIndex: 'guptaPriceWithoutSetup',
                                key: 'guptaPriceWithoutSetup',
                                align: 'right',
                                className: 'nowrap',
                                width: 75,
                                render: (value) => <span className={`${isDuplicate(value, 'GuptaPriceWithoutSetup') && 'table-highlight'} nowrap`}>{FormatCurrency(value)}</span> 
                            },
                            {
                                title: 'w/ Setup',
                                dataIndex: 'guptaPriceWithSetup',
                                key: 'guptaPriceWithSetup',
                                align: 'right',
                                className: 'nowrap',
                                width: 75,
                                render: (value) => <span className={`${isDuplicate(value, 'GuptaPriceWithSetup') && 'table-highlight'} nowrap`}>{FormatCurrency(value)}</span> 
                            },
                        ]
                    } as ColumnType<any>] : [],
                    {
                        key: 'actions',
                        align: 'right',
                        title: () => 
                            <Space>
                                <TableRowButton buttontype='Add' onClick={onAddVariation} entity='Variation' />
                                
                            </Space>,
                        render: (text, record, index: number) =>
                            <div>
                                <Space>
                                    <TableRowButton buttontype='Edit' recordid={index} onClick={onEditVariation} entity='Variation' />
                                    <TableRowButton buttontype='Delete' recordid={index} onClick={onDeleteVariation} entity='Variation' hasconfirm />
                                </Space>
                            </div>
                    }
                ]}
                dataSource={props.variations} 
                pagination={false}
                style={{ paddingBottom: '10px'}} 
                className='table-striped-rows' 
                summary={() => 
                     <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                            <TableRowButton buttontype='Delete' onClick={onDeleteSelectedVariations} entity='Variation' title='Delete selected Variations' hasconfirm confirmmessage='Are you sure you want to delete the selected Variations?' 
                                disabled={state.selectedVariationKeys.length === 0}/>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} colSpan={13}>

                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                    }
            />

            <OuterModal
                title="Variation Details" 
                open={state.showDetailsModal} 
                width={900}
                onClose={() =>showVariationDetailsModel(false)}
                saveButtonProps={{ buttontype: 'Ok', children: 'Update', title: 'Update changes'}} >
                <ProductVariationDetails 
                    productVariation={state.selectedVariation} 
                    sizes={sizeFetcher.data}
                    threadTypes={threadTypeFetcher.data}
                    temps={tempFetcher.data}
                    onClose={() => showVariationDetailsModel(false)} 
                    onSave={onSaveVariation} />
            </OuterModal>

            <OuterModal
                title="Generate Variations" 
                open={state.showGenerateModal} 
                width={900} 
                onClose={() => showGenerateModel(false)} 
                saveButtonProps={{ buttontype: 'Build', title: 'Generate Variations', children: 'Generate' }}>
                <GenerateVariations 
                    onClose={() => showGenerateModel(false)}
                    onFinish={onFinishGenerate} />
            </OuterModal>

            <OuterModal
                title="Import Variations" 
                open={state.showImportModal} 
                width={900} 
                onClose={() => showImportModel(false)} 
                saveButtonProps={{ buttontype: 'Import', title: 'Import Variations'}}>
                <ImportVariations 
                    onClose={() => showImportModel(false)}
                    onFinish={onFinishImport} />
            </OuterModal>

        </>
    )
}