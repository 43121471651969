import React, { useEffect, useState } from "react";
import { Image } from "antd";
import ImageZoom from "react-image-zooom";
import { MultiFetcher } from "./MultiFetcher";
import { ContentType } from "./enums/ContentType";

interface Props {
    imageId: string
    // width?: string | number
    preview: boolean
    style?: React.CSSProperties
    hasZoom?: boolean
}

interface State {
    objectUrl?: any
}

export const ImageFromId = (props: Props) => {
    const [state, setState] = useState<State>({
        objectUrl: undefined
    })
    
    const imageFetcher = MultiFetcher('image')

    useEffect(() => {
        imageFetcher.get({ id: props.imageId, contentType: ContentType.Blob }).then((image) => {
            const blob = new Blob([image])
            setState(prevState => ({ ...prevState, objectUrl: URL.createObjectURL(blob) }))
        })
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return state.objectUrl &&
    props.hasZoom === true ? 
        <div style={{width: '100%'}}>
            <ImageZoom src={state.objectUrl} alt="A image to apply the ImageZoom plugin" zoom="200" />
        </div>
    : 
        <Image 
            src={state.objectUrl} 
            // width={props.width} 
            preview={props.preview}
            fallback='/images/default.png' 
            style={Object.assign({ cursor: 'pointer', border: '1px solid silver' }, props.style)}  
            loading='lazy' />
   
}