import { TreeSelect } from "antd"
import React, { useCallback, useEffect, useState } from "react"

interface CategoryTreeSelectProps {
    categories: Category[]
    style?: React.CSSProperties
    loading: boolean
    value?: string
    onChange?(value: string): void
    allowClear?: boolean | undefined
}

interface State {
    treeNodes: TreeNode[]
}

export const CategoryTreeSelect = (props: CategoryTreeSelectProps) => {
    const [state, setState] = useState<State>({
        treeNodes: [],
    });

    const getSubTreeNodes = useCallback((id: number | null) : TreeNode[] => {
        const treeNodes: TreeNode[] = []
    
        if (props.categories) {
            //console.log(`cats: ${JSON.stringify(props.categories)}`)
            const rootCategories = props.categories.filter(cat => id == null ? !(cat.parentCategoryId) : cat.parentCategoryId === id)
            //console.log(`rootcats: ${JSON.stringify(rootCategories)}`)
            rootCategories.forEach(cat => {
            //console.log(`cat: ${JSON.stringify(cat)}`)
                const item = { 
                    value: cat.id,
                    title: cat.name,
                    children: getSubTreeNodes(cat.id)
                }
                treeNodes.push(item)
            })
        }
    
        return treeNodes
    }, [props.categories])

    useEffect(() => {
        const treeNodes = getSubTreeNodes(null)
        setState(prevState => ({ ...prevState, treeNodes }));
    }, [getSubTreeNodes, props.categories]);

    return (
        <TreeSelect
            {...props}
            loading={props.loading}
            showSearch
            treeNodeFilterProp="title"
            style={ props.style }
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Please select"
            allowClear={props.allowClear}
            treeDefaultExpandAll
            value={props.value}
            onChange={props.onChange}
            treeData={state.treeNodes}
        />
      )
}

interface TreeNode {
    value: number | null
    title: string
    children?: TreeNode[]
}

interface Category {
    id: number
    parentCategoryId: number | null
    name: string
}