import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './AppLayout';
import { OrderProgress } from './Pages/Dashboard/OrderProgress/OrderProgress';
import { Card, Col, Row } from 'antd';
import { OrdersForPayment } from './Pages/Dashboard/OrdersForPayment/OrdersForPayment';
import { debounce } from 'lodash';
import { FormatDateTime } from './_common/Formatters';
import { UserRole } from './_common/UserState';
import { QuoteRequests } from './Pages/Dashboard/QuoteRequests/QuoteRequests';
import { OrdersForApproval } from './Pages/Dashboard/OrdersForApproval/OrdersForApproval';
import { PricedQuotes } from './Pages/Dashboard/PricedQuotes/PricedQuotes';

interface State {
  timeStamp: Date
}

export const Home = () => {
  const { isAuthorizedForRole } = useContext(UserContext)
  
  const [state, setState] = useState<State>({
    timeStamp: new Date()
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    delayedRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const delayedRefresh = debounce(() => {
    refresh()
    delayedRefresh()
  }, 15_000)

  const refresh = () => setState(prevState => ({ ...prevState, timeStamp: new Date() }))

  const cardStyle = { margin: 0, paddingTop: 0, height: '100%', maxHeight: 400 }
  const cardBodyStyle = { padding: 15, paddingTop: 5}
  
  return (
    <>
      <div style={{ marginTop: 25, minWidth: 1072}}>
        <Row gutter={[10, 10]}>
          { isAuthorizedForRole(UserRole.CustomerUser) && 
            <Col span={12}>
              <Card 
                title={
                  <Row style={{ marginTop: 8 }}>
                    <Col span={12} className='card-title'>Payment Required</Col>
                    <Col span={12}>
                      {isAuthorizedForRole(UserRole.SuperAdmin) &&
                        <div className='card-extra'>Customer User</div>
                      }
                    </Col>
                  </Row>
                }
                bordered={false} style={cardStyle} bodyStyle={cardBodyStyle} >
                <OrdersForPayment onRefresh={refresh} timeStamp={state.timeStamp} />
              </Card>
            </Col>
          }
          { isAuthorizedForRole(UserRole.CustomerAdmin) && 
            <Col span={12}>
              <Card 
                title={
                  <Row style={{ marginTop: 8 }}>
                    <Col span={12} className='card-title'>Approval Required</Col>
                    <Col span={12}>
                      {isAuthorizedForRole(UserRole.SuperAdmin) &&
                        <div className='card-extra'>Customer Admin</div>
                      }
                    </Col>
                  </Row>
                }
                bordered={false} style={cardStyle} bodyStyle={cardBodyStyle} >
                <OrdersForApproval onRefresh={refresh} timeStamp={state.timeStamp} />
              </Card>
            </Col>
          }
          { isAuthorizedForRole(UserRole.SupplierUser) && 
            <Col span={12}>
              <Card
                title={
                  <Row style={{ marginTop: 8 }}>
                    <Col span={12} className='card-title'>Quote Requests</Col>
                    <Col span={12}>
                      {isAuthorizedForRole(UserRole.SuperAdmin) &&
                        <div className='card-extra'>Supplier User</div>
                      }
                    </Col>
                  </Row>
                }
                bordered={false} style={cardStyle} bodyStyle={cardBodyStyle} >
                <QuoteRequests onRefresh={refresh} timeStamp={state.timeStamp} />
              </Card>
            </Col>
          }
          { isAuthorizedForRole(UserRole.SuperAdmin) && 
            <Col span={12}>
              <Card 
                title={
                  <Row style={{ marginTop: 8 }}>
                    <Col span={12} className='card-title'>Priced Quotes</Col>
                    <Col span={12}>
                      {isAuthorizedForRole(UserRole.SuperAdmin) &&
                        <div className='card-extra'>Super Admin</div>
                      }
                    </Col>
                  </Row>
                }
                bordered={false} style={cardStyle} bodyStyle={cardBodyStyle} >
                <PricedQuotes onRefresh={refresh} timeStamp={state.timeStamp} />
              </Card>
            </Col>
          }
        </Row>
        
        <Row style={{ paddingTop: 10, paddingBottom: 5}}>
          <Col span={24}>
            <Card title='Active Orders' bordered={false} style={{ margin: 0 }}>
              <OrderProgress onRefresh={refresh} timeStamp={state.timeStamp}/>
            </Card>
          </Col>
        </Row>
      </div>
      <div style={{ textAlign: 'right', color: 'darkgray', paddingRight: 10 }}>Refreshed on: {FormatDateTime(state.timeStamp.toUTCString())}</div>
    </>
  );
}
