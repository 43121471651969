import { Result } from "antd";
import React from "react";
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
    loading: boolean
    title?: string
    subTitle?: string
    size?: number
    children: any
    style?: React.CSSProperties
  }

  export const PageLoader: React.FunctionComponent<Props> = (props:Props) => {
    return (
      <React.Fragment>
        {props.loading
          ? <Result
              icon={<LoadingOutlined style={{ fontSize: props.size ?? 48 }} spin />}
              title={props.title}
              subTitle={props.subTitle}>
            </Result>
          : <div style={props.style}>
              {props.children}
            </div>
        }
      </React.Fragment>
    );
  };