import React, { useEffect, useState } from 'react'
import { Row, Space, Table, Upload } from 'antd'
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { CustomButton } from 'components/_common/CustomButtons';
import { InboxOutlined } from '@ant-design/icons';
import { OperationResult } from 'components/_common/OperationResult';

const { Dragger } = Upload;

interface Props {
    onClose(): void
}

interface State {
    fileList: any[]
    hasStarted: boolean
    hasFinished: boolean
}

export const ImportFiles = (props: Props) => {
    const [state, setState] = useState<State>({
        fileList: [],
        hasStarted: false,
        hasFinished: false
    })

    const productFileFetcher = MultiFetcher('productFile')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const onUploadChange = (data: any) => {
        //console.log(`data: ${JSON.stringify(data)}`)
        setState(prevState => ({ ...prevState, fileList: data.fileList }))
    }
    
    const onProceed = async () => {      
        setState(prevState => ({ ...prevState, hasStarted: true }))

        var fileList = state.fileList.slice(0)

        for (let x = 0; x < fileList.length; x++) {
            var result = await productFileFetcher.postFile(fileList[x].originFileObj)
            
            //console.log(`result: ${result}`)
            let file = Object.assign({}, fileList[x])
            file.result = result
            fileList[x] = file
        }

        setState(prevState => ({ ...prevState, fileList, hasFinished: true }))
    }

    return (
        <>
            <Dragger 
                multiple={true} 
                maxCount={100} 
                beforeUpload={() => false} 
                onChange={onUploadChange} 
                showUploadList={false} 
                accept='.pdf, .step, .stp'
                style={{ marginBottom: 10, display: state.hasStarted ? 'none' : 'block' }}>
                
                <p className="ant-upload-drag-icon">
                <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag files to this area to upload</p>
                <p className="ant-upload-hint">
                Only technical drawings (.pdf) and step files (.step, .stp) are allowed.
                </p>
            </Dragger>

            <Table
                dataSource={state.fileList}
                rowKey={'name'}
                size='small'
                pagination={false}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        width: 125
                    },
                    {
                        title: 'Size (Mb)',
                        dataIndex: 'size',
                        key: 'size',
                        align: 'right',
                        width: 125,
                        render: (value: number) => Math.round(value / 1024 * 10) / 10
                    },
                    {
                        title: 'Result',
                        dataIndex: ['result', 'success'],
                        key: 'result',
                        align: 'center',
                        width: 75,
                        render: (value?: boolean) => <OperationResult isLoading={productFileFetcher.isLoading} result={value} />
                    },
                    {
                        title: 'Message',
                        dataIndex: ['result', 'message'],
                        key: 'resultMessage',
                        align: 'left',
                        width: '55%'
                    }
                ]}
                style={{ paddingBottom: '10px'}} 
                        className='table-striped-rows' />

            <Row style={{ marginTop: 10, display: 'flex', justifyContent: 'right'}}>
                { state.hasFinished ?
                    <CustomButton buttontype='Close' title='Close popup' onClick={props.onClose} />
                :
                    <Space>
                        <CustomButton buttontype='Cancel' title='Cancel the import' onClick={props.onClose} disabled={state.hasStarted} />
                        <CustomButton buttontype='Ok' onClick={onProceed} title='Proceed with Import of Files' disabled={state.hasStarted} >Proceed</CustomButton>
                    </Space>
                }
            </Row>
        </>
    )

}