import { Form, Input, message, Switch } from 'antd'
import { IsChangedOuterContext } from 'components/_common/OuterModal'
import { MultiFetcher } from 'components/_common/MultiFetcher'
import { PageLoader } from 'components/_common/PageLoader'
import React, { useContext, useEffect, useState } from 'react'
import * as _ from 'lodash'
import { OrderImageType } from 'components/_common/enums/OrderImageType'
import { ImagesManager } from 'components/_common/ImagesManager'

interface Props {
    orderId: number
    readonly: boolean
    onClose(): void
    onRefresh(): void
}

interface State {
    images: any[]
}

export const FirstPartFinishedDetails = (props: Props) => {
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    const [state, setState] = useState<State>({
        images: []
    })

    const orderProgressFetcher = MultiFetcher('orderprogress')
    const imageFetcher = MultiFetcher('image')

    useEffect(() => {
        orderProgressFetcher.get({ id: props.orderId.toString() }).then(order => {
            setState(prevState => ({ ...prevState, images: order.images }))
        })   
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onImagesChange = (images: any) => {
        //console.log(`images: [${JSON.stringify(images)}]`)
        setState(prevState => ({ ...prevState, images }))
        setIsChanged(true)
    }

    const Submit = async (data: any) => {
        //console.log(`data: [${JSON.stringify(data)}]`)
        const submitAsync = async (data: any):Promise<number> => await orderProgressFetcher.put(data, undefined, 'UpdateProductionFirstPart' )
        
        const images = state.images.slice(0)

        Promise.all(_.map(images.filter(image => image.imageId == null), async (image) => {
            image.imageId = await imageFetcher.postFile(image.file)
            image.imageTypeId = OrderImageType.FirstPartProof
            image.fileName = image.file.name
        })).then(async () => {

            const requestModel = {
                orderId: props.orderId,
                finished: data.productionFirstPartFinished,
                images
            }

            await submitAsync(requestModel).then((result) => {
                if (result > 0) {
                    props.onClose()
                    message.info(`Production progress for ${orderProgressFetcher.data.fullCustomerOrderNumber} was updated.`)
                    props.onRefresh()
                }
            })
        }).catch(err => message.error(err))
    }

    return (
        <PageLoader loading={orderProgressFetcher.isLoading || imageFetcher.isLoading}>
            <Form 
                initialValues={orderProgressFetcher.data} 
                form={form} 
                onFinish={Submit} 
                style={{ padding: '10px'}}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                onValuesChange={() => setIsChanged(true)}>
                <Form.Item name='id' hidden={true}><Input/></Form.Item>
                <Form.Item 
                    name='productionFirstPartFinished' 
                    label='1st Part produced' 
                    style={{marginBottom: 10}} 
                    valuePropName="checked">
                    <Switch disabled={props.readonly} />
                </Form.Item>
                <button ref={submitRef} type="submit" className='hide' />
            </Form> 

            <ImagesManager images={state.images} onChange={onImagesChange} max={25} readonly={props.readonly} />

        </PageLoader> 
    )
} 