import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Select, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLoader } from 'components/_common/PageLoader';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { PageTitle } from 'components/_common/PageTitle';
import { CustomButton } from 'components/_common/CustomButtons';
import { CustomSelect } from 'components/_common/CustomSelect';

interface State {
    selectedRole?: string
}

export const UserDetails = () => {
    const [state, setState] = useState<State>({
        selectedRole: undefined
    })
    
    const { id } = useParams();
    const navigate = useNavigate();
    const [ form ] = Form.useForm();

    const userFetcher  = MultiFetcher('user');   
    const customerFetcher  = MultiFetcher('customer');   
    const supplierFetcher  = MultiFetcher('supplier');   

    useEffect(() => {
        customerFetcher.get({})
        supplierFetcher.get({})
        if (id){
            userFetcher.get({ id }).then(
                (user) => setState(prevState => ({ ...prevState, selectedRole: user.role }))
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRoleChange = (value: string) => {
        if (value !== 'CustomerAdmin' && value !== 'CustomerUser') {
            form.setFieldValue('customerId', undefined)
        }
        if (value !== 'SupplierUser') {
            form.setFieldValue('supplierId', undefined)
        }

        setState(prevState => ({ ...prevState, selectedRole: value }))
    }

    const Submit = async (data: any) => {
        const submitAsync = async (data: any): Promise<number> => {
            return id ? await userFetcher.put(data) : userFetcher.post(data);
        }

        await submitAsync(data).then((result) => {
            if (result > 0) {
                navigate('/user/list')
                message.info(`User was ${id ? 'updated' : 'created'}.`)
            }
        })
    }

    return (
        <>
            <PageTitle>User Details</PageTitle> 

            <PageLoader loading={userFetcher.isLoading}>
                <Form 
                    layout='horizontal'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={userFetcher.data}
                    style={{ padding: '10px'}}
                    form={form}
                    onFinish={Submit}
                    autoComplete='off'>
                    <Form.Item name='id' hidden={true}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Name is required.' }]}>
                        <Input style={{maxWidth: 400}} maxLength={50} autoComplete='off'></Input>
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Email is required.' }]}>
                        <Input style={{maxWidth: 400}} maxLength={250} autoComplete='off'></Input>
                    </Form.Item>
                    <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Role is required.' }]}>
                        <Select
                            style={{ width: 175 }}
                            options={[
                                { value: 'SuperAdmin', label: 'SuperAdmin' },
                                { value: 'CustomerAdmin', label: 'CustomerAdmin' },
                                { value: 'CustomerUser', label: 'CustomerUser' },
                                { value: 'SupplierUser', label: 'SupplierUser' },
                            ]}
                            onChange={onRoleChange}
                        />
                    </Form.Item>
                    <Form.Item label="Customer" name="customerId" rules={[{ required: state.selectedRole === 'CustomerUser' || state.selectedRole === 'CustomerAdmin', message: 'Customer is required.' }]}>
                        <CustomSelect data={customerFetcher.data} loading={customerFetcher.isLoading} style={{ maxWidth: '400px' }} disabled={state.selectedRole !== 'CustomerUser' && state.selectedRole !== 'CustomerAdmin'} />
                    </Form.Item>
                    <Form.Item label="Supplier" name="supplierId" rules={[{ required: state.selectedRole === 'SupplierUser', message: 'Supplier is required.' }]}>
                        <CustomSelect data={supplierFetcher.data} loading={supplierFetcher.isLoading} style={{ maxWidth: '400px' }} disabled={state.selectedRole !== 'SupplierUser'} />
                    </Form.Item>
                    <Form.Item label="New Password" name="password" rules={[{ required: id == null, message: 'New Password is required.' }]} >
                        <Input.Password style={{maxWidth: 400}} maxLength={50} autoComplete='new-password'></Input.Password>
                    </Form.Item>
                    <Form.Item label="Repeat New Password" name="password2" rules={[
                        { required: id == null, message: 'Repeat New Password is required.' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                                }
                                return Promise.reject(new Error('Passwords do not match!'));
                            }
                        })]}>
                        <Input.Password style={{maxWidth: 400}} maxLength={50} autoComplete='new-password'></Input.Password>
                    </Form.Item>
                </Form>
            </PageLoader>
                
            <br/>

            <Row>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Cancel' onClick={() => navigate(-1)} />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Save' onClick={() => form.submit()} />
                </Col>
            </Row>

        </>
    )
}