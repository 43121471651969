import React from 'react'
import { InputNumber, InputNumberProps } from 'antd'

export const MoneyInput = (props: InputNumberProps) => {
    return (
        <InputNumber 
            {...props}
            addonBefore='$'
            style={{ width: '115px' }}
            min={0}
            max={9999999.99}
            precision={2}
            controls={false}
            size='small'
        />
    )
}