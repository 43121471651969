/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { BoxDetails } from 'components/Pages/Base/BoxDetails';
import { TableRowButton } from 'components/_common/CustomButtons';
import { CustomSelect } from 'components/_common/CustomSelect';
import { FormLabel } from 'components/_common/FormLabel';
import { IsChangedInnerContext } from 'components/_common/InnerModal';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { OuterModal } from 'components/_common/OuterModal';
import { WeightInput } from 'components/_common/WeightInput';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

interface Props {
    packedBox?: any
    qtyPlanned: number
    qtyPacked: number
    onSave(data: any): void
    onClose(): void
}

interface State {
    showAddBoxModal: boolean
    leftToPack: number
}

export const PackedBoxDetails = (props: Props) => {
    const { setIsChanged, submitRef } = useContext(IsChangedInnerContext)
    const [state, setState] = useState<State>({
        showAddBoxModal: false,
        leftToPack: 0
    })

    const [form] = Form.useForm()
    const boxFetcher = MultiFetcher('box')

    useEffect(() => {
        boxFetcher.get({})
        recalculateLeftToPack()
    }, [])

    const onSave = (packedBox: any) => {
        packedBox.box = boxFetcher.data.find(p => p.id === packedBox.boxId)
        props.onSave(packedBox)
    }

    const showAddBoxModal = (showAddBoxModal: boolean) => () => setState(prevState => ({ ...prevState, showAddBoxModal }))
    const onRefreshBoxesAndSelect = async (boxId: number) => {
        await boxFetcher.get({}).then(() => form.setFieldValue('boxId', boxId))
        setIsChanged(true)
    }

    const qtyPackedOtherBoxes = () => props.qtyPacked - (props.packedBox?.totalParts ?? 0)

    const recalculateLeftToPack = () => {
        const partsPerBox = form.getFieldValue('partsPerBox') ?? 0
        const numberOfBoxes = form.getFieldValue('numberOfBoxes') ?? 0
        const qtyPackedInThisBox = partsPerBox * numberOfBoxes

        const leftToPack = props.qtyPlanned - qtyPackedOtherBoxes() - (qtyPackedInThisBox ?? 0)
        setState(prevState => ({ ...prevState, leftToPack}))
    }

    const partsPerBoxChanged = debounce((value: number | null) => {
        const numberOfBoxes = value ? Math.floor((props.qtyPlanned - qtyPackedOtherBoxes()) / value) : null
        form.setFieldValue('numberOfBoxes', numberOfBoxes)
        recalculateLeftToPack()
    }, 500)
    
    return (
        <>
            <Form 
                initialValues={props.packedBox} 
                form={form} 
                style={{ padding: '10px'}}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                onValuesChange={() => setIsChanged(true)}
                onFinish={onSave} >
                    <Form.Item name='id' hidden={true}><Input/></Form.Item>
                    <Form.Item name='batchLineId' hidden={true}><Input/></Form.Item>
                    <Row>
                        <Col span={22}>  
                            <Form.Item label='Box (LxWxH)' name='boxId' rules={[{ required: true, message: 'Box is required.' }]}>
                                <CustomSelect data={boxFetcher.data} loading={boxFetcher.isLoading} required placeholder='select a box' displayfieldname='displayName' addonafter={<>cm</>}  />
                            </Form.Item>
                        </Col>
                        <Col span={2} style={{ float: 'right' }}>
                            <TableRowButton buttontype='Add' onClick={showAddBoxModal(true)} size='middle' type='primary' />
                        </Col>
                        <Col span={22}>  
                            <Form.Item label="Parts per Box" name="partsPerBox" rules={[{ required: true, message: 'Parts per Box is required.' }]}>
                                <InputNumber min={0} max={99999999} controls={false} onChange={partsPerBoxChanged}/>
                            </Form.Item>
                        </Col>
                        <Col span={22}>  
                            <Form.Item label="Number of Boxes" name="numberOfBoxes" rules={[{ required: true, message: 'Number of Boxes is required.' }]}>
                                <InputNumber min={0} max={99999999} controls={false} onChange={() => recalculateLeftToPack()}/>
                            </Form.Item>
                        </Col>
                        <Col span={22}>
                            <Form.Item label='Left to Pack' className='color-blackish'>
                                <FormLabel style={{ width: 78, textAlign: 'right' }} value={state.leftToPack.toString()} className={state.leftToPack >= 0 ? 'color-blackish' : 'color-red'} />
                            </Form.Item>
                        </Col>
                        <Col span={22}>  
                            <Form.Item label="Weight per Box" name="weightPerBox">
                                <WeightInput />
                            </Form.Item>
                        </Col>
                    </Row> 
                    <button ref={submitRef} type="submit" className='hide' />
            </Form>

            <OuterModal
                title='Add Box'
                open={state.showAddBoxModal} 
                style={{ maxWidth: '400px'}}
                onClose={showAddBoxModal(false)}>
                    <BoxDetails onClose={showAddBoxModal(false)} onRefreshAndSelect={onRefreshBoxesAndSelect} />
            </OuterModal>
        </>
    )
}