/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { Tab } from 'rc-tabs/lib/interface';
import * as _ from 'lodash';
import { MultiFetcher } from 'components/_common/MultiFetcher';

interface Props {
    timeStamp?: Date
    onStatusChange(phaseId: string, statusId?: string): void
}

interface State {
    phaseId: string
    quoteStatusId?: string
}

export const OrderStatusTabs = (props: Props) => {    
    const [state, setState] = useState<State>({
        phaseId: localStorage.getItem('selectedPhaseId') ?? '1',
        quoteStatusId: localStorage.getItem('selectedQuoteStatusId') ?? undefined,
    })

    const phaseFetcher = MultiFetcher('enumValues/Phase')
    const quoteStatusFetcher = MultiFetcher('quoteStatus')

    const getQuoteStatuses = async () => quoteStatusFetcher.get({ actionName: 'getallwithordercount' })  

    useEffect(() => {
        window.scrollTo(0, 0)
        phaseFetcher.get({ })      
        if (state.phaseId === '1') getQuoteStatuses()
    }, []);

    useEffect(() => {
        if (state.phaseId === '1') getQuoteStatuses()
    }, [state.phaseId, props.timeStamp])
    
    const mapResultsToTabs = (data: any, showAll: boolean, showTotalCount: boolean):Tab[] => {
        let items: any[] = []

        const createCount = (value: number) => <span style={{fontSize: 12, color: 'silver !important'}}>[{value}]</span>

        if (showAll) {
            if (showTotalCount){
                const totalCount = _.sum(_.map(data, (item) => item.orderCount))
                items.push({ key: '',  label: <>All {createCount(totalCount)}</> })
            } else {
                items.push({ key: '',  label: `All` })
            }
        }

        _.forEach(data, item => {
            items.push({ 
                key: `${item.id}`, 
                label: showTotalCount 
                ? <>{item.id}. {item.name} {createCount(item.orderCount)}</>
                : <>{`${item.id}. ${item.name}`}</>
            })
        })
        return items
    }

    const onPhaseChange = async (phaseId: string) => {
        //console.log(`onPhaseChanged: [${phaseId}]`)
        setState(prevState => ({ ...prevState, phaseId, quoteStatusId: '' }))
        localStorage.setItem('selectedQuoteStatusId', '')
        localStorage.setItem('selectedPhaseId', phaseId)
        props.onStatusChange(phaseId, '')
    }

    const onQuoteStatusChanged = async (statusId: string) => {
        //console.log(`onStatusChanged: [${statusId}]`)
        setState(prevState => ({ ...prevState, quoteStatusId: statusId }))
        localStorage.setItem('selectedQuoteStatusId', statusId)
        props.onStatusChange(state.phaseId, statusId)
    }

    return (
        <>
            <Tabs
                activeKey={state.phaseId}
                onChange={onPhaseChange}
                type="card"
                size='small'
                items={mapResultsToTabs(phaseFetcher.data, false, false)}
                className='blue-tabs'
                tabBarStyle={{ marginBottom: 0, marginLeft: 20}} />

                {state.phaseId === '1' &&
                    <Tabs
                        activeKey={state.quoteStatusId}
                        onChange={onQuoteStatusChanged}
                        type="card"
                        size='small'
                        items={mapResultsToTabs(quoteStatusFetcher.data, true, true)}
                        tabBarStyle={{ marginBottom: 0, marginLeft: 10}} />
                }
        </>
    )
}