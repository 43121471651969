/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, Fragment} from 'react';
import { Layout, Menu, Row, Col, Image } from 'antd';
import { Link } from 'react-router-dom'
import authService from './api-authorization/AuthorizeService';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { CreateUserState, UserRole, UserState, isAuthorizedForRole } from './_common/UserState';
import { MultiFetcher } from './_common/MultiFetcher';

const { Header, Footer, Content } = Layout;

export const UserContext = React.createContext<UserState>(new UserState());

export const AppLayout = (props:any) => {
  const [state, setState] = useState({
      isAuthenticated: false,
      userName: undefined,
      userRole: undefined,
      customerId: undefined,
      supplierId: undefined,
      environment: undefined
  });

  const systemFetcher = MultiFetcher('system');   

  const getAuthDetails = useCallback(async () => {
    const isAuthenticated = await authService.isAuthenticated()
    const user = await authService.getUser()
      const userName = user?.['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
      const userRole = user?.['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
      const customerId = user?.customerId && Number.parseInt(user.customerId)
      const supplierId = user?.supplierId && Number.parseInt(user.supplierId)

    console.info(`Authenticated user '${userName}' with role '${userRole}'.`)
    customerId && console.info(`CustomerId: '${customerId}'`)
    supplierId && console.info(`SupplierId: '${supplierId}'`)

    setState(prevState => ({ ...prevState, 
      userName,
      userRole,
      customerId,
      supplierId,
      isAuthenticated, 
    }))

  }, [setState]);

  useEffect(() => {
    window.scrollTo(0, 0)
    getAuthDetails()
    systemFetcher.get({ actionName: 'GetEnvironmentSection'}).then(result => {
      setState(prevState => ({ ...prevState, environment: result.value }))
    })
  }, [getAuthDetails])

  return (
      <Layout style={{ minHeight: '100vh', minWidth: '800px'}}>
        <Header style={{ height: '90px', backgroundColor: '#001529' }} >
          <Row>
            <Col span={4}>
              <Image src='../images/e2e_logo.jpg' preview={false} style={{height: 90}} />
            </Col>
            { state.environment === 'Test' &&
              <Col span={4} className='header-environment'>{state.environment}</Col>
            }
            <Col span={state.environment === 'Test' ? 16 : 20}>
              <div style={{}}>
                <Menu
                  theme="dark"
                  mode="horizontal"
                  style={{marginLeft: 'auto', justifyContent: 'flex-end', marginTop: '18px'}}
                >
                  { state.isAuthenticated ? 
                    <Fragment>
                      <Menu.Item key="0"><Link to='/'><span>Home</span></Link></Menu.Item>
                        { isAuthorizedForRole(UserRole.CustomerUser, state.userRole) && 
                            <Menu.Item key="10"><Link to='/order/list'><span>Orders</span></Link></Menu.Item>
                        }
                        { isAuthorizedForRole(UserRole.SupplierUser, state.userRole) && 
                            <Menu.Item key="20"><Link to='/quoterequest/list'><span>Quote Requests</span></Link></Menu.Item>
                        }
                        { state.userRole === UserRole.SuperAdmin && 
                            <Menu.Item key="21"><Link to='/pricedquote/list'><span>Priced Quotes</span></Link></Menu.Item>
                        }
                        { isAuthorizedForRole(UserRole.SuperAdmin, state.userRole) && 
                            <Menu.Item key="25"><Link to='/payment/list'><span>Payments</span></Link></Menu.Item>
                        }
                        { isAuthorizedForRole(UserRole.CustomerUser, state.userRole) && 
                            <Menu.Item key="28"><Link to='/product/list'><span>Products</span></Link></Menu.Item> 
                        }
                        { isAuthorizedForRole(UserRole.SuperAdmin, state.userRole) && 
                            <Menu.Item key="29"><Link to='/price/list'><span>Prices</span></Link></Menu.Item> 
                        }
                        { isAuthorizedForRole(UserRole.SuperAdmin, state.userRole) && 
                          <Menu.SubMenu key="30" title="CRM">
                            <Menu.Item key="31"><Link to='/customer/list'><span>Customers</span></Link></Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="32"><Link to='/contact/list'><span>Contacts</span></Link></Menu.Item>
                            <Menu.Item key="33"><Link to='/contactrole/list'><span>Contact Roles</span></Link></Menu.Item>
                              <>
                                <Menu.Divider />
                                <Menu.Item key="34"><Link to='/supplier/list'><span>Suppliers</span></Link></Menu.Item>
                              </>
                              <>
                                <Menu.Divider />
                                <Menu.Item key="35"><Link to='/country/list'><span>Countries</span></Link></Menu.Item>
                              </>
                          </Menu.SubMenu>
                        }
                        { (isAuthorizedForRole(UserRole.CustomerAdmin, state.userRole) || isAuthorizedForRole(UserRole.SupplierUser, state.userRole)) &&
                          <Menu.SubMenu key="40" title="Base">
                            { isAuthorizedForRole(UserRole.SupplierUser, state.userRole) &&
                              <Menu.Item key="43"><Link to='/box/list'><span>Boxes</span></Link></Menu.Item>
                            }

                            { state.userRole === UserRole.SuperAdmin &&
                              <>
                                <Menu.Divider />
                                <Menu.Item key="42"><Link to='/productcategory/list'><span>Product Categories</span></Link></Menu.Item>
                              </>
                            }

                            { isAuthorizedForRole(UserRole.CustomerAdmin, state.userRole) &&
                              <>                        
                                <Menu.Item key="44"><Link to='/productsize/list'><span>Product Sizes</span></Link></Menu.Item>
                                <Menu.Item key="45"><Link to='/productthreadtype/list'><span>Product ThreadTypes</span></Link></Menu.Item>
                                <Menu.Item key="46"><Link to='/productcolor/list'><span>Product Colors</span></Link></Menu.Item>
                                <Menu.Item key="47"><Link to='/producttemperature/list'><span>Product Temperatures</span></Link></Menu.Item>
                              </>
                            }
                          </Menu.SubMenu>
                        }
                        <Menu.SubMenu key="90" icon={ state.userRole === UserRole.SuperAdmin ? <UserSwitchOutlined/> : <UserOutlined/>} title={state.userName}>
                          { state.userRole === UserRole.SuperAdmin &&
                            <>
                              <Menu.Item key="91"><Link to='/user/list'><span>Users</span></Link></Menu.Item>
                              <Menu.Item key="92"><Link to='/auditlog/list'><span>Audit Log</span></Link></Menu.Item>
                            </>
                          }
                          <Menu.Item key="93"><Link to='/changepassword'><span>Change Password</span></Link></Menu.Item>
                          <Menu.Divider />
                          <Menu.Item key="99"><Link to={ApplicationPaths.LogOut}><span>Logout</span></Link></Menu.Item>
                        </Menu.SubMenu>
                      </Fragment>
                    :                    
                      <Menu.Item key="99"><Link to={ApplicationPaths.Login}><span>Login</span></Link></Menu.Item>
                  }
                </Menu>
              </div>
            </Col>
          </Row>
        </Header>
        <Content style={{ margin: 'auto', width: '90%', marginBottom: '20px'}}>
          <div>
            <UserContext.Provider value={CreateUserState(state.isAuthenticated, state.userName, state.userRole, state.customerId, state.supplierId)}>
              {props.children}
            </UserContext.Provider>
          </div>
        </Content>
        <Footer className='footer'>
          <Row>
            <Col span={4}></Col>
            <Col span={16}>©2024 Pinpoint Solutions</Col>
            <Col span={4} style={{textAlign: 'right'}}>{state.environment}</Col>
          </Row>
        </Footer>
      </Layout>
  )
}
