export enum UserRole {
    SuperAdmin = "SuperAdmin",
    CustomerAdmin = "CustomerAdmin",
    CustomerUser = "CustomerUser",
    SupplierUser = "SupplierUser"
}

export const CreateUserState = (isAuthenticated: boolean, userName?: string, userRole?: UserRole, customerId?: number, supplierId?: number) => {
    const userState = new UserState()

    userState.isAuthenticated = isAuthenticated
    userState.userName = userName
    userState.userRole = userRole
    userState.customerId = customerId
    userState.supplierId = supplierId

    return userState
}

export class UserState {
    isAuthenticated: boolean
    userName?: string
    userRole?: UserRole
    customerId?: number
    supplierId?: number
    isAuthorizedForRole = (role: UserRole): boolean => isAuthorizedForRole(role, this.userRole)
}

export const isAuthorizedForRole = (role: UserRole, userRole: string | undefined) : boolean => {
    //console.log(`isAuthorizedForRole(role: ${role}, userRole: ${userRole})`)
    if (!userRole) return false
    switch (role) {
        case UserRole.SuperAdmin: return userRole === 'SuperAdmin'
        case UserRole.CustomerAdmin: return userRole === 'CustomerAdmin' || userRole  === 'SuperAdmin'
        case UserRole.CustomerUser: return userRole === 'CustomerUser' || userRole === 'CustomerAdmin' || userRole === 'SuperAdmin'
        case UserRole.SupplierUser: return userRole === 'SupplierUser' || userRole === 'SuperAdmin'
    }
}