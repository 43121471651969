import React, { useEffect } from 'react';
import { Col, Form, Input, Row, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLoader } from 'components/_common/PageLoader';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { PageTitle } from 'components/_common/PageTitle';
import { CustomSelect } from 'components/_common/CustomSelect';
import { CustomButton } from 'components/_common/CustomButtons';

export const ContactDetails = () => {   
    const { id } = useParams();
    const navigate = useNavigate();
    const [ form ] = Form.useForm();

    const customerFetcher = MultiFetcher('customer');   
    const contactRoleFetcher = MultiFetcher('contactrole');
    const contactFetcher = MultiFetcher('contact');   

    useEffect(() => {
        window.scrollTo(0, 0)
        customerFetcher.get({})
        contactRoleFetcher.get({})
        if (id) contactFetcher.get({ id })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Submit = async (data: any) => {
        const submitAsync = async (data: any):Promise<number> => {
            return id ? await contactFetcher.put(data) : contactFetcher.post(data);
        }

        await submitAsync(data).then((result) => {
            if (result > 0) {
                navigate('/contact/list')
                message.info(`Contact was ${id ? 'updated' : 'created'}.`)
            }
        })
    }

    return (
        <>
            <PageTitle>Contact Details</PageTitle>

            <PageLoader loading={contactFetcher.isLoading}>
                <Form 
                    layout='horizontal'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={contactFetcher.data}
                    style={{ padding: '10px'}}
                    form={form}
                    onFinish={Submit}>
                    <Form.Item name='id' hidden={true}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Customer" name="customerId" rules={[{ required: true, message: 'Customer is required.' }]}>
                        <CustomSelect data={customerFetcher.data} loading={customerFetcher.isLoading} style={{ width: '400px' }} />
                    </Form.Item>
                    <Form.Item label="Role" name="contactRoleId">
                        <CustomSelect data={contactRoleFetcher.data} loading={contactRoleFetcher.isLoading} style={{ width: '400px' }} />
                    </Form.Item>
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required.'}]}>
                        <Input style={{maxWidth: 400}} maxLength={50}></Input>
                    </Form.Item>
                    <Form.Item label="Telephone" name="telephone">
                        <Input style={{maxWidth: 400}} maxLength={50}></Input>
                    </Form.Item>
                    <Form.Item label="Email" name="email">
                        <Input style={{maxWidth: 400}} maxLength={50}></Input>
                    </Form.Item>                    
                </Form>
            </PageLoader>

            <br/>

            <Row>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Cancel' onClick={() => navigate(-1)} />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Save' onClick={() => form.submit()} />
                </Col>
            </Row>

        </>
    )
}