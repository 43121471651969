/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, Row, Space, Table, TimePicker } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { PageTitle } from 'components/_common/PageTitle';
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter';
import { FormatCombinedDateTime, FormatDateTime } from 'components/_common/Formatters';
import { dateCompare } from 'components/_common/Generic';
import { OuterModal } from 'components/_common/OuterModal';
import { AuditLogDetails } from './AuditLogDetails';
import dayjs, { Dayjs } from "dayjs"

interface State {
    entry?: any,
    startDate?: Dayjs,
    startTime?: Dayjs,
    endDate?: Dayjs,
    endTime?: Dayjs
}

export const AuditLogList = () => {
    const [state, setState] = useState<State>({
        entry: undefined,
        startDate: dayjs().subtract(1, 'day'),
        startTime: dayjs(),
        endDate: undefined,
        endTime: undefined
    })

    const auditLogFetcher = MultiFetcher('auditLog')
    const getDataAsync = async () => {
        const startDate = FormatCombinedDateTime(state.startDate, state.startTime)
        const endDate = FormatCombinedDateTime(state.endDate, state.endTime)

        auditLogFetcher.get({ queryParams: `startDate=${startDate}&endDate=${endDate}`})
    }

    const setEntry = (value?: any) => setState(prevState => ({ ...prevState, entry: value }))

    const onStartDateChanged = (date: any) => setState(prevState => ({ ...prevState, startDate: date }))
    const onStartTimeChanged = (time: any) => setState(prevState => ({ ...prevState, startTime: time }))
    const onEndDateChanged = (date: any) => setState(prevState => ({ ...prevState, endDate: date }))
    const onEndTimeChanged = (time: any) => setState(prevState => ({ ...prevState, endTime: time }))

    useEffect(() => {
        getDataAsync()
    }, [state.startDate, state.startTime, state.endDate, state.endTime])

    return (
        <>
            <PageTitle>Audit Log</PageTitle>

            <Row>
                <Col xs={10} md={10} lg={8} xl={7} xxl={5}>
                    <Form.Item label="From" style={{ margin: 6 }}>
                        <DatePicker style={{ width: 125 }} value={state.startDate} onChange={onStartDateChanged} />
                        <TimePicker style={{ width: 100 }} value={state.startTime} onChange={onStartTimeChanged} />
                    </Form.Item>
                </Col>
                <Col xs={10} md={10} lg={8} xl={7} xxl={5}>
                    <Form.Item label="Until" style={{ margin: 6 }}>
                        <DatePicker  style={{ width: 125 }} value={state.endDate} onChange={onEndDateChanged} />
                        <TimePicker style={{ width: 100 }} value={state.endTime} onChange={onEndTimeChanged} />
                    </Form.Item>
                </Col>
            </Row>
                
            <Table
                loading={auditLogFetcher.isLoading}
                dataSource={auditLogFetcher.data} 
                className='table-striped-rows' 
                rowKey={'id'}
                size='small'
                pagination={{ defaultPageSize: 12, size: 'default' }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => setEntry(record)
                    }
                }}
                style={{ paddingBottom: '10px', cursor: 'pointer'}}
                columns={[
                    {
                        title: 'Timestamp',
                        dataIndex: 'timestamp',
                        key: 'timeStamp',
                        sorter: (a: any, b: any) => dateCompare(a.timeStamp, b.timeStamp),
                        render: (value: string) => <div>{FormatDateTime(value)}</div>
                    },
                    {
                        title: 'Username',
                        dataIndex: 'userName',
                        key: 'userName',
                        sorter: (a:any,b:any) => a.userName.localeCompare(b.userName),
                        ...ColumnTextFilter('userName'),
                    },
                    {
                        title: 'Route',
                        dataIndex: 'route',
                        key: 'route',
                        sorter: (a:any,b:any) => a.route.localeCompare(b.route),
                        ...ColumnTextFilter('route'),
                    },
                    {
                        title: 'Method',
                        dataIndex: 'method',
                        key: 'method',
                        sorter: (a:any,b:any) => a.method.localeCompare(b.method),
                        ...ColumnTextFilter('method'),
                    },
                    {
                        title: 'Body',
                        dataIndex: 'body',
                        key: 'body',
                        ellipsis: { showTitle: false },
                        responsive: ['lg'],
                        width: '25%',
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        render: (record: any) =>
                            <div>
                                <Space>
                                    <TableRowButton buttontype='View' onClick={() => setEntry(record)} title='Open'/>
                                </Space>
                            </div>
                    }

                ]} />

                <OuterModal
                    title="AuditLog Entry" 
                    width="1200px"
                    style={{ maxHeight: 600 }}
                    open={state.entry !== undefined} 
                    onClose={() => setEntry(undefined)} >
                        <AuditLogDetails entry={state.entry} />
                </OuterModal>
        </>
    )
}