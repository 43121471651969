import React, { useEffect } from 'react';
import { Col, Form, Input, Row, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLoader } from 'components/_common/PageLoader';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { PageTitle } from 'components/_common/PageTitle';
import { CustomSelect } from 'components/_common/CustomSelect';
import { CustomButton } from 'components/_common/CustomButtons';

export const CustomerDetails = () => {
   
    const { id } = useParams();
    const navigate = useNavigate();
    const [ form ] = Form.useForm();

    const countryFetcher = MultiFetcher('country');   
    const customerFetcher = MultiFetcher('customer');   

    useEffect(() => {
        window.scrollTo(0, 0)
        countryFetcher.get({})
        if (id) customerFetcher.get({ id })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Submit = async (data: any) => {
        const submitAsync = async (data: any):Promise<number> => {
            return id ? await customerFetcher.put(data) : customerFetcher.post(data);
        }

        await submitAsync(data).then((result) => {
            if (result > 0) {
                navigate('/customer/list')
                message.info(`Customer was ${id ? 'updated' : 'created'}.`)
            }
        })
    }

    return (
        <>
            <PageTitle>Customer Details</PageTitle>

            <PageLoader loading={customerFetcher.isLoading}>
                <Form 
                    layout='horizontal'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={customerFetcher.data}
                    style={{ padding: '10px'}}
                    form={form}
                    onFinish={Submit}>
                    <Form.Item name='id' hidden={true}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required.' }]}>
                        <Input style={{maxWidth: 400}} maxLength={100}></Input>
                    </Form.Item>
                    <Form.Item label="Order Nr. Prefix" name="orderNumberPrefix"  rules={[{ required: true, message: 'Order Nr. Prefix is required.' }]}>
                        <Input style={{maxWidth: 75}} maxLength={3}></Input>
                    </Form.Item>
                    <Form.Item label="Address" name="address">
                        <Input style={{maxWidth: 400}} maxLength={50}></Input>
                    </Form.Item>
                    <Form.Item label="City" name="city">
                        <Input style={{maxWidth: 400}} maxLength={50}></Input>
                    </Form.Item>
                    <Form.Item label="ZipCode" name="zipCode">
                        <Input style={{maxWidth: 200}} maxLength={10}></Input>
                    </Form.Item>
                    <Form.Item label="Country" name="countryId" rules={[{ required: true, message: 'Country is required' }]}>
                        <CustomSelect data={countryFetcher.data} loading={countryFetcher.isLoading} style={{ width: '400px' }} />
                    </Form.Item>
                </Form>
            </PageLoader>
                
            <br/>

            <Row>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Cancel' onClick={() => navigate(-1)} />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Save' onClick={() => form.submit()} />
                </Col>
            </Row>

        </>
    )
}