import React, { useEffect } from 'react';
import { Col, Form, Input, Row, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLoader } from 'components/_common/PageLoader';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { PageTitle } from 'components/_common/PageTitle';
import { CustomSelect } from 'components/_common/CustomSelect';
import { CategoryTreeSelect } from 'components/_common/CategoryTreeSelect';
import { CustomButton } from 'components/_common/CustomButtons';

export const ProductCategoryDetails = () => {
    const navigate = useNavigate()
    const { id } = useParams();
    const [ form ] = Form.useForm();

    const categoryFetcher = MultiFetcher('productcategory');   
    const parentCategoryFetcher = MultiFetcher('productcategory')
    const supplierFetcher = MultiFetcher('supplier');   

    useEffect(() => {
        window.scrollTo(0, 0);
        parentCategoryFetcher.get({}).then(() => {
            if (!id){
                const selectedCategoryId = localStorage.getItem('selectedProductCategoryId')
                if (selectedCategoryId) form.setFieldValue('parentCategoryId', selectedCategoryId)
            }
        })
        supplierFetcher.get({})
        if (id) categoryFetcher.get({ id })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Submit = async (data: any) => {
        const submitAsync = async (data: any):Promise<number> => {
            return id ? await categoryFetcher.put(data) : categoryFetcher.post(data);
        }

        await submitAsync(data).then((result) => {
            if (result > 0) {
                navigate('/productcategory/list')
                message.info(`Category was ${id ? 'updated' : 'created'}.`)
            }
        })
    }

    return (
        <>
            <PageTitle>Category Details</PageTitle>

            <PageLoader loading={categoryFetcher.isLoading}>
                <Form 
                    layout='horizontal'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={categoryFetcher.data}
                    style={{ padding: '10px'}}
                    form={form}
                    onFinish={Submit}>
                    <Form.Item name='id' hidden={true}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Parent Category" name="parentCategoryId">
                        <CategoryTreeSelect categories={parentCategoryFetcher.data} style={{ maxWidth: '400px' }} loading={parentCategoryFetcher.isLoading} allowClear/>
                    </Form.Item>
                    <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                        <Input style={{maxWidth: 400}} maxLength={100}></Input>
                    </Form.Item>
                    <Form.Item label="Preferred Supplier" name="preferredSupplierId">
                        <CustomSelect data={supplierFetcher.data} loading={supplierFetcher.isLoading} style={{ maxWidth: '400px'}} />
                    </Form.Item>
                </Form>
            </PageLoader>

            <br/>

            <Row>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Cancel' onClick={() => navigate(-1)} /> 
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Save' onClick={() => form.submit()} />
                </Col>
            </Row>

        </>
    )
}