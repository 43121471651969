import React, { Key, useContext, useState } from 'react'
import { Col, Form, Input, Row, Table, Tooltip, message } from 'antd';
import { FormatCurrency } from 'components/_common/Formatters'
import { FileUpload } from 'components/_common/FileUpload';
import { UploadChangeParam } from 'antd/es/upload';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import _ from 'lodash';
import { IsChangedOuterContext } from 'components/_common/OuterModal';

interface Props {
    selectedOrders: any[]
    onClose(): void
    onRefresh(): void
}

interface State {
    expandedKeys?: Key[]
}

export const CreatePayment = (props: Props) => {
    const [state, setState] = useState<State>({
        expandedKeys: undefined
    })

    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    const imageFetcher = MultiFetcher('image')
    const paymentFetcher = MultiFetcher('payment')

    const handleUpload = (file: UploadChangeParam) => {
        form.setFieldValue('fileName', file.file.name)
        form.setFieldValue('file', file.file)
        setIsChanged(true)
    } 

    const onSubmit = async (values: any) => {
        const paymentAmount = _.sum(_.map(props.selectedOrders, p => p.duePrice))
        const paymentOrders = props.selectedOrders.map((order: any) => ({ orderId: order.id, paymentTermId: order.duePaymentTermId, amount: order.duePrice }))
        //console.log(`PaymentOrders: ${JSON.stringify(paymentOrders)}`)
        
        await imageFetcher.postFile(values.file).then(async (imageId) => {
            //console.log( `ImageId: [${imageId}]`)
            const payment = {
                amount: paymentAmount,
                reference: values.paymentReference,
                imageId: imageId,
                imageFileName: values.fileName,
                paymentOrders
            }

            console.log(`payment: [${JSON.stringify(payment)}]`)

            await paymentFetcher.post(payment).then((result) => {
                if (result > 0) { 
                    props.onClose()
                    message.info(`Payment was created.`)
                    props.onRefresh()
                }
            })
        })
    }

    return (
        <>
            <Table
                dataSource={props.selectedOrders}
                size='small'
                rowKey={'id'}
                pagination={false}
                bordered={true}
                style={{ padding: 0, margin: 0}}
                className='table-striped-rows' 
                columns={[
                    {
                        title: 'Order Nr.',
                        dataIndex: 'fullCustomerOrderNumber',
                        key: 'fullCustomerOrderNumber',
                        className: 'nowrap',
                        //width: 150
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                        ellipsis: { showTitle: false },
                        render: (value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                        //width: '40vw',
                    },
                    {
                        title: 'Total Price',
                        dataIndex: 'totalPrice',
                        key: 'totalPrice',
                        render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>,
                        align: 'right',
                        //width: 100
                    },
                    {
                        title: 'Due Term',
                        dataIndex: 'duePaymentTermName',
                        key: 'duePaymentTermName',
                        align: 'center'
                        //width: 125
                    },
                    {
                        title: 'Due Price',
                        dataIndex: 'duePrice',
                        key: 'duePrice',
                        render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>,
                        align: 'right',
                        //width: 75
                    },
                ]}
                expandable={{
                    expandRowByClick: true,
                    childrenColumnName: 'details',
                    expandedRowKeys: state.expandedKeys,
                    onExpand(expanded, record) {
                        const keys = [] as Key[]
                        if (expanded) keys.push(record.id)
                        setState(prevState => ({ ...prevState, expandedKeys: keys }))
                    },
                }}
                summary={(pageData) => {
                    let totalDue = 0;
            
                    pageData.forEach(({ duePrice }) => totalDue += duePrice)
                        
                    return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={1} colSpan={4}>Total</Table.Summary.Cell>
                          <Table.Summary.Cell index={2} align='right' className='nowrap'>{FormatCurrency(totalDue)}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                  }}
            />

            <br/>
        
            <Form 
                layout='horizontal'
                labelCol={{ span: 4, xl: 6}}
                wrapperCol={{ span: 20, xl: 18}}
                style={{ padding: '10px'}}
                form={form}
                onValuesChange={() => setIsChanged(true)} 
                onFinish={onSubmit}
                >
                    <Row>
                        <Col xl={12} span={24}>
                            <Form.Item name='paymentReference' label="Payment Ref."  >
                                <Input style={{maxWidth: 225}} maxLength={25}/>
                            </Form.Item>        
                        </Col>
                        <Col xl={12} span={24}>
                            <Form.Item name='file' hidden={true}><Input/></Form.Item>
                            <Form.Item name='fileName' label="Proof" rules={[{ required: true, message: 'Proof is required.' }]} >
                                <FileUpload 
                                    onUpload={handleUpload} 
                                    accept='image/*' />
                            </Form.Item>        
                        </Col>
                    </Row>
                    <button ref={submitRef} type="submit" className='hide' />
            </Form>
        </>
    )
}