import React from 'react'

interface Props {
    productColor: any
}

export const ColorDisplay = (props: Props) => {

    const getColor = (htmlColor: string) => {
        if (!htmlColor) return '#ffffff'

        const red = parseInt(htmlColor.substring(1, 3), 16)
        const green = parseInt(htmlColor.substring(3,5), 16)
        const blue = parseInt(htmlColor.substring(5,7), 16)

        return (red*0.299 + green*0.587 + blue*0.114 > 186) ? '#000000' : '#ffffff'
    }

    return props.productColor && 
        <div style={{ 
            height: 22, 
            width: 35, 
            fontSize: 13,
            color: getColor(props.productColor?.htmlColor), 
            backgroundColor: props.productColor?.htmlColor, 
            borderColor: 'silver',
            borderWidth: '1px',
            borderStyle: 'solid',
            textAlign: 'center' }} >
                {props.productColor?.shortName}
        </div>
}
