/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { Form, Input } from 'antd'
import { MultiFetcher } from 'components/_common/MultiFetcher';
import _ from 'lodash';
import { IsChangedOuterContext } from 'components/_common/OuterModal';

interface Props {
    onClose(): void
    onFinish(value: any): void
}

export const ImportVariations = (props: Props) => {
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()
    
    const { TextArea } = Input;

    const sizeFetcher = MultiFetcher('productsize')
    const threadTypeFetcher = MultiFetcher('productthreadtype')

    useEffect(() => {
        window.scrollTo(0, 0)

        sizeFetcher.get({})
        threadTypeFetcher.get({})
    }, [])

    const findIdByPartNumberCode = (data:any, code: string) => data.filter((item: any) => item.partNumberCode === code)[0]?.id

    const onSubmit = (value: any) => {
        const variations: { }[] = []

        const rows = value.text.split('\n')
        //console.log(`rows: [${rows.length}]`)

        _.forEach(rows, row => {
            if (row.length > 1) {
                const cols = row.split('	')
                if (cols.length >= 4){
                    var foundPartNumber = _.find(variations, (p:any) => p.partNumber === cols[3].substring(2))

                    if (!foundPartNumber)
                    {
                        const partNumberSegments = cols[3].split('-')
                        let size1Id, size2Id, threadType2Id = undefined

                        if (partNumberSegments.length > 1) {
                            size1Id = findIdByPartNumberCode(sizeFetcher.data, partNumberSegments[1])
                        }

                        if (partNumberSegments.length > 2){
                            size2Id = findIdByPartNumberCode(sizeFetcher.data, partNumberSegments[2])
                            if (!size2Id)
                                threadType2Id = findIdByPartNumberCode(threadTypeFetcher.data, partNumberSegments[2])
                        }

                        const partNumberWithoutColorCode = cols[3].substring(2).trim()

                        const variation = {
                            size1Id: size1Id,
                            size2Id: size2Id,                   
                            threadType2Id: threadType2Id,
                            drawingNumber: cols[2].trim(),
                            partNumber: partNumberWithoutColorCode,
                            extraInfo: cols.length >= 5 ? cols[4] : undefined,
                            guptaPriceWithoutSetup: cols[0].substring(2).trim().replace(',', '.'),
                            guptaPriceWithSetup: cols[1].substring(2).trim().replace(',', '.')
                        }

                        variations.push(variation)
                    }
                }
            }
        })

        props.onFinish(variations)
    }

    return (
        <Form 
            layout='horizontal'
            labelCol={{ span: 0}}
            wrapperCol={{ span: 24}}
            style={{ padding: '10px'}}
            form={form}
            onFinish={onSubmit}
            onValuesChange={() => setIsChanged(true)} >
                <Form.Item name="text" rules={[{ required: true, message: 'Text is required.' }]}>
                    <TextArea rows={20} placeholder="Copy-paste your tab-separated excel data here, in the following format: &#10;-------------------------------------------------------------------------------&#10;<Gupta Price w/o SC>	<Gupta Price w/ SC>	<Gupta PartNr>	<AST PartNumber>	<Description>" />
                </Form.Item>
            <button ref={submitRef} type="submit" className='hide' />
        </Form>
    )
}