/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Popconfirm, Row, Tooltip, Typography, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { QuoteStatus } from 'components/_common/enums/QuoteStatus';
import React, { useContext, useEffect, useState } from 'react'
import { ProgressSquare } from './_common/ProgressSquare';
import { VerifyPayment } from './Payment/VerifyPayment';
import { UserRole, UserState } from 'components/_common/UserState';
import { UserContext } from 'components/AppLayout';
import { OuterModal } from 'components/_common/OuterModal';
import { FinishedSquare } from './_common/FinishedSquare';
import { QualifiedDetails } from './Qc/QualifiedDetails';
import { FirstPartFinishedDetails } from './Production/FirstPartFinishedDetails';
import { ShippingBatchList } from './Shipping/ShippingBatchList';
import { OrderView } from 'components/Pages/Orders/OrderView';
import { ProductionProgressDetails } from './Production/ProductionProgressDetails';
import { CustomButton, TableRowButton } from 'components/_common/CustomButtons';
import { OrderDetails } from 'components/Pages/Orders/OrderDetails';
import { OrderEditMode } from 'components/Pages/Orders/_common/OrderEditMode';
import { WarningOutlined, CheckSquareFilled, MailFilled } from '@ant-design/icons';
import { MaterialsFinishedDetails } from './Production/MaterialsFinishedDetails';
import { DisqualifiedDetails } from './Qc/DisqualifiedDetails';

const { Text } = Typography;

interface State {
    orderId?: number
    orderNumber?: string
    deliveryType?: string

    showViewOrderModal: boolean
    approveQuote: boolean
    showEditOrderModal: boolean
    editMode: OrderEditMode

    showVerifyPaymentModal: boolean
    showMaterialsModal: boolean
    showFirstPartModal: boolean
    showProductionProgressModal: boolean
    showShippingBatchesModal: boolean
    showQualifiedModal: boolean
    showDisqualifiedModal: boolean
}

interface Props {
    timeStamp: Date
    onRefresh(): void
}
 
export const OrderProgress = (props: Props) => {
    const { isAuthorizedForRole } = useContext<UserState>(UserContext)

    const [state, setState] = useState<State>({
        orderId: undefined,
        orderNumber: undefined,
        deliveryType: undefined,

        showViewOrderModal: false,
        approveQuote: false,
        showEditOrderModal: false,
        editMode: OrderEditMode.Add,

        showVerifyPaymentModal: false,
        showMaterialsModal: false,
        showFirstPartModal: false,
        showProductionProgressModal: false,
        showShippingBatchesModal: false,
        showQualifiedModal: false,
        showDisqualifiedModal: false
    })

    const orderFetcher = MultiFetcher('orderProgress')
    const quoteActionsFetcher = MultiFetcher('quoteActions')

    const getOrdersAsync = async () => orderFetcher.get({ })

    useEffect(() => {
        window.scrollTo(0, 0)
 
        getOrdersAsync()
    }, [props.timeStamp])

    const colSpanQuote = 7
    const colSpanPayment = 2
    const colSpanProduction = 4
    const colSpanShipping = 7
    const colSpanQc = 4

    const addOrder = () => setState(prevState => ({ ...prevState, showEditOrderModal: true, editMode: OrderEditMode.Add, orderId: 0 }))
    const closeViewOrderModal = () => setState(prevState => ({ ...prevState, showViewOrderModal: false, orderId: undefined, approveQuote: false }))
    const closeEditOrderModal = () => setState(prevState => ({ ...prevState, showEditOrderModal: false, orderId: undefined, editMode: OrderEditMode.Add }))

    const onRequestQuote = (id: number, orderNumber: string) => {
        const data = {
            orderId: id
        }
        quoteActionsFetcher.post(data, 'RequestQuote').then(async (success) => {
            if (success) {
                props.onRefresh()
                message.info(`A Quote Request for Order ${orderNumber} has been sent.`)
            }
        })
    }

    const onApproveQuote = async (id: number, orderNumber: string, paymentTypeId: number, deliveryTypeId: number) => {
        const data = {
            orderId: id,
            paymentTypeId,
            deliveryTypeId
        }
        quoteActionsFetcher.post(data, 'ApproveQuote').then(async (success) => {
            if (success) {
                closeViewOrderModal()
                props.onRefresh()
                message.info(`Order ${orderNumber} was approved.`)
            }
        })
    }

    return (
        <>
            {/* <PageLoader loading={orderFetcher.isLoading}> */}
                <div className='orderprogress-border'>
                    <Row gutter={5}>
                        <Col span={6}>&nbsp;</Col>
                        <Col span={18}>
                            <Row gutter={25} style={{ textAlign: 'center'}}>
                                <Col span={colSpanQuote}><div className='orderprogress-square color-quote'><Tooltip placement='top' title='Quote'>Quote</Tooltip></div></Col>
                                <Col span={colSpanPayment}><div className='orderprogress-square color-payment'><Text ellipsis><Tooltip placement='top' title='Payment'>Payment</Tooltip></Text></div></Col>
                                <Col span={colSpanProduction}><div className='orderprogress-square color-production'><Tooltip placement='top' title='Production'>Production</Tooltip></div></Col>
                                <Col span={colSpanShipping}><div className='orderprogress-square color-shipping'><Tooltip placement='top' title='Shipping'>Shipping</Tooltip></div></Col>
                                <Col span={colSpanQc}><div className='orderprogress-square color-qc'><Tooltip placement='top' title='Quality Control'>QC</Tooltip></div></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='orderprogress-titlerow' gutter={5}>
                        <Col span={6}>&nbsp;</Col>
                        <Col span={18}>
                            <Row gutter={25}>
                                <Col span={colSpanQuote}>
                                    <Row gutter={3}>
                                        <Col span={4} className='orderprogress-title'>New</Col>
                                        <Col span={4} className='orderprogress-title'>Requested</Col>
                                        <Col span={4} className='orderprogress-title'>Viewed</Col>
                                        <Col span={4} className='orderprogress-title'>Quoting</Col>
                                        <Col span={4} className='orderprogress-title'>Quoted</Col>
                                        <Col span={4} className='orderprogress-title'>Approved</Col>
                                    </Row>
                                </Col>
                                <Col span={colSpanPayment}>
                                    <Row gutter={3}>
                                        {/* <Col span={12} className='orderprogress-title'>Announced</Col> */}
                                        <Col span={24} className='orderprogress-title'>Paid</Col>
                                    </Row>
                                </Col>
                                <Col span={colSpanProduction}>
                                    <Row gutter={3}>
                                        <Col span={6} className='orderprogress-title'>Materials</Col>
                                        <Col span={6} className='orderprogress-title'>1st Part</Col>
                                        <Col span={12} className='orderprogress-title'>Produced</Col>
                                    </Row>
                                </Col>
                                <Col span={colSpanShipping}>
                                    <Row gutter={3}>
                                        <Col span={3} className='orderprogress-title'><div>Packing<br/>Instruction</div></Col>
                                        <Col span={7} className='orderprogress-title'>Packed</Col>
                                        <Col span={7} className='orderprogress-title'>Shipped</Col>
                                        <Col span={7} className='orderprogress-title'>Arrived</Col>
                                    </Row>
                                </Col>
                                <Col span={colSpanQc}>
                                    <Row gutter={3}>
                                        <Col span={12} className='orderprogress-title'>Qualified</Col>
                                        <Col span={12} className='orderprogress-title'>Disqualified</Col>
                                    </Row>
                                </Col>
                                
                            </Row>
                        </Col>
                    </Row>
                    {orderFetcher.data?.map((order:any) => {
                        const hasSuperAdminRights = isAuthorizedForRole(UserRole.SuperAdmin)
                        const hasSupplierUserRights = isAuthorizedForRole(UserRole.SupplierUser)
                        const hasCustomerAdminRights = isAuthorizedForRole(UserRole.CustomerAdmin)
                        const hasCustomerUserRights = isAuthorizedForRole(UserRole.CustomerUser)

                        const isPaymentUnVerified = order.paymentVerifiedProgress < order.paymentPaidProgress
                        
                        return (
                            <Row key={order.id} className='orderprogress-datarow' gutter={5}>
                                <Col span={6}>
                                    <Row>
                                        <Col span={8} onClick={() => setState(prevState => ({ ...prevState, orderId: order.id, showViewOrderModal: true }))} style={{ cursor: 'pointer' }}>
                                            <Text ellipsis={{ tooltip: order.fullCustomerOrderNumber }}>{order.fullCustomerOrderNumber}</Text>
                                        </Col>
                                        <Col span={14} onClick={() => setState(prevState => ({ ...prevState, orderId: order.id, showViewOrderModal: true }))} style={{ cursor: 'pointer' }}>
                                            <Text ellipsis={{ tooltip: order.description }}>{order.description}</Text>
                                        </Col>
                                        <Col span={2} style={{ display: 'flex', justifyContent: 'right' }}>
                                            {(order.quoteStatusId === QuoteStatus.New || hasSuperAdminRights) &&
                                                <TableRowButton buttontype='Edit' entity='Order' className='showOnHover' onClick={() => setState(prevState => ({ ...prevState, orderId: order.id, showEditOrderModal: true, editMode: OrderEditMode.Edit }))} />
                                            }
                                            {(order.quoteStatusId === QuoteStatus.Quoted && hasCustomerAdminRights) &&
                                                <TableRowButton buttontype='Undo' title="Edit for Re-Quote" className='showOnHover' onClick={() => setState(prevState => ({ ...prevState, orderId: order.id, showEditOrderModal: true, editMode: OrderEditMode.ReQuote }))} />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={18}>
                                    <Row gutter={25}>
                                        <Col span={colSpanQuote}>
                                            <Row gutter={3}>
                                                { order.quoteStatusId === QuoteStatus.New && hasCustomerUserRights
                                                    ?
                                                    <Col span={4} style={{ cursor: 'pointer' }} title='Request Quote'>
                                                        <Popconfirm
                                                            title='Request Quote'
                                                            description='Are you sure you want to request a quote for this order?'
                                                            icon={<WarningOutlined style={{ fontSize: '20px', color: 'orange', verticalAlign: 'middle', paddingBottom: 6 }} />}
                                                            onCancel={(e) => e?.stopPropagation()}
                                                            onConfirm={(e) => {
                                                                e?.stopPropagation()
                                                                onRequestQuote(order.id, order.fullCustomerOrderNumber)
                                                            }}>
                                                                <div className={`orderprogress-square color-quote`}>
                                                                    <MailFilled className='orderprogress-square-icon' style={{ color: 'white' }} />
                                                                </div>
                                                        </Popconfirm>
                                                    </Col>
                                                    :
                                                    <Col span={4}>
                                                        <div className={`orderprogress-square ${order.quoteStatusId >= QuoteStatus.New && 'color-quote'}`}>&nbsp;</div>
                                                    </Col>
                                                }
                                                <Col span={4} >
                                                    <div className={`orderprogress-square ${order.quoteStatusId >= QuoteStatus.Requested && 'color-quote'}`}>&nbsp;</div>
                                                </Col>
                                                <Col span={4}>
                                                    <div className={`orderprogress-square ${order.quoteStatusId >= QuoteStatus.Viewed && 'color-quote'}`}>&nbsp;</div>
                                                </Col>
                                                <Col span={4}>
                                                    <div className={`orderprogress-square ${order.quoteStatusId >= QuoteStatus.Quoting && 'color-quote'}`}>&nbsp;</div>
                                                </Col>
                                                { order.quoteStatusId === QuoteStatus.Quoted && hasCustomerAdminRights
                                                    ?
                                                    <Col span={4} style={{ cursor: 'pointer' }} onClick={() => setState(prevState => ({ ...prevState, orderId: order.id, showViewOrderModal: true, approveQuote: true }))} title='Approve Quote'>
                                                        <div className='orderprogress-square color-quote'>
                                                            <CheckSquareFilled className='orderprogress-square-icon' style={{ color: 'white' }} />
                                                        </div>
                                                    </Col>
                                                    :
                                                    <Col span={4}>
                                                        <div className={`orderprogress-square ${order.quoteStatusId >= QuoteStatus.Quoted && 'color-quote'}`}>&nbsp;</div>
                                                    </Col>
                                                }
                                                <Col span={4}>
                                                    <div className={`orderprogress-square ${order.quoteStatusId >= QuoteStatus.Approved && 'color-quote'}`}>&nbsp;</div>
                                                </Col>
                                            </Row>
                                        </Col>

                                        {/* == Payment == */}
                                        <Col span={colSpanPayment}>
                                            <Row gutter={3}>
                                                {/* <Col span={12}>
                                                    <ProgressSquare 
                                                        percentage={order.paymentAnnouncedProgress} 
                                                        color={order.paymentAcceptedProgress < order.paymentAnnouncedProgress ? 'NotReady' : 'Payment'} />
                                                </Col> */}
                                                <Col span={24}>
                                                    <ProgressSquare 
                                                        percentage={order.paymentPaidProgress} 
                                                        color={isPaymentUnVerified ? 'yellow' : 'payment'} 
                                                        onClick={isPaymentUnVerified && hasSuperAdminRights ? () => { setState(prevState => ({ ...prevState, showVerifyPaymentModal: true, orderId: order.id })) } : undefined} 
                                                        tooltip={isPaymentUnVerified && hasSuperAdminRights ? 'Verify Payment' : undefined} />&nbsp;
                                                </Col>
                                            </Row>
                                        </Col>
                                        
                                        {/* == Production == */}
                                        <Col span={colSpanProduction}>
                                            <Row gutter={3}>
                                                <Col span={6}>
                                                    <FinishedSquare 
                                                        finished={order.productionMaterialsFinished} 
                                                        color='production' 
                                                        onClick={hasSupplierUserRights ? () => setState(prevState => ({ ...prevState, 
                                                            showMaterialsModal: true, 
                                                            orderId: order.id, 
                                                            orderNumber: order.fullCustomerOrderNumber })) : undefined} 
                                                        tooltip={hasSupplierUserRights ? 'Change materials progress' : undefined} />&nbsp;
                                                </Col>
                                                <Col span={6}>
                                                    <FinishedSquare 
                                                        finished={order.productionFirstPartFinished} 
                                                        color='production' 
                                                        onClick={() => setState(prevState => ({ ...prevState, 
                                                            showFirstPartModal: true, 
                                                            orderId: order.id, 
                                                            orderNumber: order.fullCustomerOrderNumber }))} 
                                                        tooltip={hasSupplierUserRights ? 'Change 1st Part progress' : 'Show 1st Part progress'} />&nbsp;
                                                </Col>
                                                <Col span={12}>
                                                    <ProgressSquare 
                                                        percentage={order.productionFinishedProgress} 
                                                        color='production' 
                                                        onClick={() => setState(prevState => ({ ...prevState, 
                                                            showProductionProgressModal: true, 
                                                            orderId: order.id, 
                                                            orderNumber: order.fullCustomerOrderNumber }))} 
                                                        tooltip={`${hasSupplierUserRights ? 'Manage': 'View'} Production Batches`} />&nbsp;
                                                </Col>
                                            </Row>
                                        </Col>

                                        {/* == Shipping == */}
                                        <Col span={colSpanShipping}>
                                            <Row gutter={3}>
                                                <Col span={3}>
                                                    <FinishedSquare 
                                                        finished={order.shippingPackingInstructionAvailable} 
                                                        color='orange' 
                                                        onClick={() => setState(prevState => ({ ...prevState, 
                                                            showShippingBatchesModal: true,
                                                            orderId: order.id, 
                                                            orderNumber: order.fullCustomerOrderNumber,
                                                            deliveryType: order.deliveryTypeName }))} 
                                                        tooltip={`${hasSupplierUserRights ? 'Manage': 'View'} Shipping Batches`} />
                                                </Col>
                                                <Col span={7}>
                                                    <ProgressSquare 
                                                        percentage={order.shippingPackedProgress} 
                                                        color='shipping' 
                                                        onClick={() => setState(prevState => ({ ...prevState, 
                                                            showShippingBatchesModal: true,
                                                            orderId: order.id, 
                                                            orderNumber: order.fullCustomerOrderNumber,
                                                            deliveryType: order.deliveryTypeName }))} 
                                                        tooltip={`${hasSupplierUserRights ? 'Manage': 'View'} Shipping Batches`} />&nbsp;
                                                </Col>
                                                <Col span={7}>
                                                    <ProgressSquare 
                                                        percentage={order.shippingShippedProgress} 
                                                        color='shipping'
                                                        onClick={() => setState(prevState => ({ ...prevState, 
                                                            showShippingBatchesModal: true, 
                                                            orderId: order.id, 
                                                            orderNumber: order.fullCustomerOrderNumber,
                                                            deliveryType: order.deliveryTypeName }))} 
                                                        tooltip={`${hasSupplierUserRights ? 'Manage': 'View'} Shipping Batches`} />&nbsp;
                                                </Col>
                                                <Col span={7}>
                                                    <ProgressSquare 
                                                        percentage={order.shippingArrivedProgress} 
                                                        color='shipping'
                                                        onClick={() => setState(prevState => ({ ...prevState, 
                                                            showShippingBatchesModal: true, 
                                                            orderId: order.id, 
                                                            orderNumber: order.fullCustomerOrderNumber,
                                                            deliveryType: order.deliveryTypeName }))} 
                                                        tooltip={`${hasSupplierUserRights ? 'Manage': 'View'} Shipping Batches`} />&nbsp;
                                                </Col>
                                            </Row>
                                        </Col>

                                        {/* == QC == */}
                                        <Col span={colSpanQc}>
                                            <Row gutter={3}>
                                                <Col span={12}>
                                                    <ProgressSquare 
                                                        percentage={order.qcQualifiedProgress} 
                                                        color='qc'
                                                        onClick={hasCustomerAdminRights ? () => setState(prevState => ({ ...prevState, 
                                                            showQualifiedModal: true, 
                                                            orderId: order.id, 
                                                            orderNumber: order.fullCustomerOrderNumber })) : undefined} 
                                                        tooltip={hasCustomerAdminRights ? 'Change Qualified progress' : undefined} />&nbsp;
                                                </Col>
                                                <Col span={12}>
                                                    <ProgressSquare 
                                                        percentage={order.qcDisqualifiedProgress} 
                                                        color='qc'
                                                        onClick={hasCustomerAdminRights ? () => setState(prevState => ({ ...prevState, 
                                                            showDisqualifiedModal: true, 
                                                            orderId: order.id, 
                                                            orderNumber: order.fullCustomerOrderNumber })) : undefined} 
                                                        tooltip={hasCustomerAdminRights ? 'Change Disqualified progress' : undefined} />&nbsp;
                                                </Col>
                                            </Row>
                                        </Col>
                                        
                                    </Row>
                                </Col>
                            </Row>
                        )
                        }
                    )}
                    <br/>
                    { isAuthorizedForRole(UserRole.CustomerUser) && 
                        <div className='cell-fit-content-align-right'>
                            <CustomButton buttontype='Add' title='Create new Order' onClick={addOrder}>New Order</CustomButton>
                        </div>
                    }
                </div>
            {/* </PageLoader> */}

            <OuterModal
                title="View Order Details" 
                open={state.showViewOrderModal} 
                width='80vw'
                style={{ minWidth: 1000, maxWidth: '1440px'}}
                onClose={closeViewOrderModal} 
                saveButtonProps={ state.approveQuote ? { 
                    buttontype: 'Approve',
                    children: 'Approve',
                    title: 'Approve this Quote',
                    hasConfirm: true,
                    confirmMessage: 'Are you sure you want to Approve this Quote?',
                    disabled: false
                } : { buttontype: 'Ok', style: { display: 'none'} }}>
                    { state.showViewOrderModal && state.orderId && 
                        <div style={{width: '100%'}}>
                            <OrderView 
                                orderId={state.orderId}
                                approveQuote={state.approveQuote}
                                onClose={closeViewOrderModal} 
                                onApprove={onApproveQuote}/>
                        </div>
                    }
            </OuterModal>

            <OuterModal
                title="Verify Payment" 
                open={state.showVerifyPaymentModal} 
                width='80vw'
                onClose={() => setState(prevState => ({ ...prevState, showVerifyPaymentModal: false }))} 
                saveButtonProps={{
                    buttontype: 'Approve',
                    title: 'Verify this payment',
                    hasConfirm: true,
                    confirmMessage: 'Are you sure you want to verify this payment?',
                    disabled: false
                }}>
                { state.orderId && 
                    <div style={{width: '100%'}}>
                        <VerifyPayment 
                            orderId={state.orderId} 
                            onClose={() => setState(prevState => ({ ...prevState, showVerifyPaymentModal: false }))}
                            onRefresh={() => getOrdersAsync()} />
                    </div>
                }
            </OuterModal>

            <OuterModal
                title={<div className={`color-production`}>{`Production Progress for ${state.orderNumber}`}</div>}
                open={state.showMaterialsModal} 
                width='400px'
                className='progress-modal'
                centered
                onClose={() => setState(prevState => ({ ...prevState, showMaterialsModal: false }))} >
                { state.orderId && 
                    <div style={{width: '100%'}}>
                        <MaterialsFinishedDetails 
                            orderId={state.orderId}
                            readonly={!isAuthorizedForRole(UserRole.SupplierUser)}
                            onClose={() => setState(prevState => ({ ...prevState, showMaterialsModal: false }))}
                            onRefresh={() => getOrdersAsync()} />
                    </div>
                }
            </OuterModal>

            <OuterModal
                title={<div className='color-production'>{`Production Progress for ${state.orderNumber}`}</div>}
                open={state.showFirstPartModal} 
                width='805px'
                className='progress-modal'
                centered
                onClose={() => setState(prevState => ({ ...prevState, showFirstPartModal: false }))} 
                saveButtonProps={{ buttontype: 'Save', style: { display: isAuthorizedForRole(UserRole.SupplierUser) ? 'inline-block' : 'none'}}}>
                { state.orderId && 
                    <div style={{width: '100%'}}>
                        <FirstPartFinishedDetails
                            orderId={state.orderId}
                            readonly={!isAuthorizedForRole(UserRole.SupplierUser)}
                            onClose={() => setState(prevState => ({ ...prevState, showFirstPartModal: false }))}
                            onRefresh={() => getOrdersAsync()} />
                    </div>
                }
            </OuterModal>

            <OuterModal
                title={<div className='color-production'>{`Production Progress for ${state.orderNumber}`}</div>}
                open={state.showProductionProgressModal} 
                width='1440px'
                className='progress-modal'
                centered
                onClose={() => setState(prevState => ({ ...prevState, showProductionProgressModal: false, orderId: undefined, orderNumber: undefined }))}>
                { state.orderId && 
                    <div style={{width: '100%'}}>
                        <ProductionProgressDetails
                            orderId={state.orderId}
                            orderNr={state.orderNumber}
                            readonly={!isAuthorizedForRole(UserRole.SupplierUser)}
                            onClose={() => setState(prevState => ({ ...prevState, showProductionProgressModal: false, orderId: undefined, orderNumber: undefined }))}
                            onRefresh={() => getOrdersAsync()} />
                    </div>
                }
            </OuterModal>

            <OuterModal
                title={<div className='color-shipping'>{`Shipping Batches for ${state.orderNumber} (${state.deliveryType})`}</div>}
                open={state.showShippingBatchesModal} 
                width='700px'
                className='progress-modal'
                centered
                onClose={() => setState(prevState => ({ ...prevState, showShippingBatchesModal: false }))} 
                saveButtonProps={{ buttontype: 'Save', style: { display: 'none' }}}>
                { state.orderId && 
                    <div style={{width: '100%'}}>
                        <ShippingBatchList
                            orderId={state.orderId}
                            orderNr={state.orderNumber}
                            readonly={!isAuthorizedForRole(UserRole.SupplierUser)}
                            onClose={() => setState(prevState => ({ ...prevState, showShippingBatchesModal: false }))}
                            onRefresh={() => getOrdersAsync()} />
                    </div>
                }
            </OuterModal>

            <OuterModal
                title={<div className={`color-qc`}>{`QC Progress for ${state.orderNumber}`}</div>}
                open={state.showQualifiedModal} 
                width='400px'
                className='progress-modal'
                centered
                onClose={() => setState(prevState => ({ ...prevState, showQualifiedModal: false }))} >
                { state.orderId && 
                    <div style={{width: '100%'}}>
                        <QualifiedDetails
                            orderId={state.orderId} 
                            onClose={() => setState(prevState => ({ ...prevState, showQualifiedModal: false }))}
                            onRefresh={() => getOrdersAsync()} />
                    </div>
                }
            </OuterModal>

            <OuterModal
                title={<div className={`color-qc`}>{`QC Progress for ${state.orderNumber}`}</div>}
                open={state.showDisqualifiedModal} 
                width='400px'
                className='progress-modal'
                centered
                onClose={() => setState(prevState => ({ ...prevState, showDisqualifiedModal: false }))} >
                { state.orderId && 
                    <div style={{width: '100%'}}>
                        <DisqualifiedDetails
                            orderId={state.orderId} 
                            onClose={() => setState(prevState => ({ ...prevState, showDisqualifiedModal: false }))}
                            onRefresh={() => getOrdersAsync()} />
                    </div>
                }
            </OuterModal>

            <OuterModal
                title={`${state.editMode} Order`}
                open={state.showEditOrderModal} 
                width='80vw'
                style={{ minWidth: 1000, maxWidth: '1440px'}}
                onClose={closeEditOrderModal} >
                    <div style={{width: '100%'}}>
                        <OrderDetails
                            orderId={state.orderId}
                            editMode={state.editMode}
                            onClose={closeEditOrderModal} 
                            onSubmitted={props.onRefresh} />
                        </div>
            </OuterModal>
        </>
    )
}
