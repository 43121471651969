import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLoader } from 'components/_common/PageLoader';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { PageTitle } from 'components/_common/PageTitle';
import { CategoryTreeSelect } from 'components/_common/CategoryTreeSelect';
import { ProductVariationList } from './ProductVariationList';
import { CustomButton } from 'components/_common/CustomButtons';
import * as _ from 'lodash'

const { TextArea } = Input;

interface State {
    variations: any
}

export const ProductDetails = () => {   
    const [state, setState] = useState<State>({
        variations: []
    })

    const { id } = useParams();
    const navigate = useNavigate();
    const [ form ] = Form.useForm();

    const productFetcher = MultiFetcher('product');   
    const variationFetcher = MultiFetcher('productvariation');   
    const categoryFetcher = MultiFetcher('productcategory')
    const imageFetcher = MultiFetcher('image'); 

    useEffect(() => {
        window.scrollTo(0, 0)

        categoryFetcher.get({}).then(() => {
            if (!id)
                form.setFieldValue('categoryId', localStorage.getItem('selectedCategoryId'))
        })

        if (id){
            productFetcher.get({ id })
            variationFetcher.get({ queryParams: `productId=${id}` }).then((variations) => {
                setState(prevState => ({ ...prevState, variations }))
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Submit = async (data: any) => {
        data.variations = state.variations

        const submitAsync = async (data: any):Promise<number> => {
            return id ? await productFetcher.put(data) : productFetcher.post(data);
        }

        const imageUploadPromises: Promise<number>[] = []

        _.each(data.variations, (variation) => {
            _.each(variation.images, (image) => {
                if (!image.id){
                    imageUploadPromises.push(new Promise(async (resolve, reject) => {
                        //console.log(`Posting new Image: ${image.fileName}`)
                        //console.log(`posting file: ${JSON.stringify(image.file)}`)
                        image.imageId = await imageFetcher.postFile(image.file)
                        
                        if (image.imageId)
                            resolve(image.imageId)
                        else
                            reject("Could not upload image")
                    }))
                }
            })
        })

        Promise.all(imageUploadPromises).then(async () => {
            await submitAsync(data).then((result) => {
                if (result > 0) { 
                    navigate('/product/list') 
                    message.info(`The Product was ${id ? 'updated' : 'created'}.`)
                }
            }).catch(err => message.error(err))
        })
    }

    return (
        <>
            <PageTitle>Product Details</PageTitle>

            <PageLoader loading={productFetcher.isLoading}>
                <Form 
                    layout='horizontal'
                    labelCol={{ span: 4, xl: 5}}
                    wrapperCol={{ span: 20, xl: 19}}
                    initialValues={productFetcher.data}
                    style={{ padding: '10px'}}
                    form={form}
                    onFinish={Submit}>
                        <Form.Item name='id' hidden={true}>
                            <Input/>
                        </Form.Item>
                        <Row>
                            <Col xl={12} span={24}>
                                <Form.Item label="Category" name="categoryId" rules={[{ required: true, message: 'Category is required.' }]}>
                                    <CategoryTreeSelect categories={categoryFetcher.data} loading={categoryFetcher.isLoading} />
                                </Form.Item>
                            </Col>
                            <Col xl={12} span={24}>
                                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required.' }]}>
                                    <Input maxLength={50}></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} span={24}>
                                <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Description is required.' }]}>
                                    <TextArea maxLength={500} rows={4}></TextArea>
                                </Form.Item>
                            </Col>
                            <Col xl={12} span={24}>
                                <Form.Item label="ItemNumber" name="itemNumber">
                                    <Input maxLength={100}></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                </Form>
            </PageLoader>

            <ProductVariationList 
                variations={state.variations} 
                isLoading={variationFetcher.isLoading}
                setVariations={(variations) => setState(prevState => ({ ...prevState, variations }))}
            />
                
            <br/>

            <Row>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Cancel' onClick={() => navigate(-1)} />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Save' onClick={() => form.submit()} />
                </Col>
            </Row>

        </>
    )
}