import { Button, Card, Upload } from "antd";
import React from "react";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ImageFromBlob } from "./ImageFromBlob";
import * as _ from 'lodash';
import { ImageFromId } from "./ImageFromId";

interface Props {
    images: any[]
    max: number
    readonly?: boolean
    onChange(images: any[]): void
}

export const ImagesManager = (props: Props) => {

    const deleteImage = (index: number) => {
        //console.log(`deleting image with index [${index}]`)
        const images = props.images
        images.splice(index, 1);
        props.onChange(images)
    }

    const onBeforeUpload = (file, fileList) => {
        //console.log(`fileList: [${JSON.stringify(fileList)}]`)
        const images = props.images.slice(0);
        _.forEach(fileList, file => images.push({ file }))
        props.onChange(images)
        return false
    }

    return (
        <React.Fragment>
             <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                {_.map(props.images, (image, index) => {
                    return (
                        <Card
                            key={image.id || image.file.uid}
                            style={{ width: 100, maxWidth: 100, marginRight: '8px', marginBottom: '8px'}}
                            bodyStyle={{ padding: 0}}
                            headStyle={{ margin: 0}}
                            actions={!props.readonly ? [
                                null,
                                // <Popconfirm
                                //     title='Are you sure you want to delete this image?'
                                //     onCancel={(e) => e?.stopPropagation()}
                                //     onConfirm={(e) => {
                                //         e?.stopPropagation();
                                //         deleteImage(index)
                                //     }}>
                                    <Button danger size='small' icon={<DeleteOutlined />} title='Delete Image' onClick={(e) => {
                                        e.stopPropagation()
                                        deleteImage(index)
                                    }} />
                                // </Popconfirm>
                            ] : []}>
                            <div style={{width: 100, height: 100}}>
                                { image.imageId ?
                                    <ImageFromId imageId={image.imageId} style={{maxWidth: 100, maxHeight: 100}} preview={true} />
                                    :   
                                    <ImageFromBlob blob={image.file} style={{maxWidth: 100, maxHeight: 100}} />
                                }
                            </div>
                        </Card>
                    )
                })} 

                { props.images.length < props.max && !props.readonly ?
               
                <div style={{width: '100px', marginRight: 8, marginBottom: 8 }}>
                    <Upload 
                        beforeUpload={onBeforeUpload}
                        showUploadList={false} 
                        multiple
                        listType="picture-card"
                        accept='image/*'
                        style={{ width: 100, padding: 0 }}
                        >
                            <PlusOutlined />
                    </Upload>
                </div>
            : null }
            </div>   
        </React.Fragment>
    )
}