import React, { useEffect, useState } from 'react';
import {  Layout, Table } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { PageTitle } from 'components/_common/PageTitle';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import { CategoryTreeMenu } from 'components/_common/CategoryTreeMenu';
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter';
import { Key } from 'antd/es/table/interface';
import { VariationPrices } from './VariationPrices';

interface State {
    filterCategoryId?: string,
    expandedKeys?: Key[]
}

export const PriceList = () => {
    const [state, setState] = useState<State>({
        filterCategoryId: localStorage.getItem('selectedCategoryId') ?? undefined,
        expandedKeys: undefined,
    });

    const productFetcher = MultiFetcher('product');   
    const categoryFetcher = MultiFetcher('productcategory')

    const fetchProductsAsync = async (categoryId: string | undefined) => {
        productFetcher.get({ queryParams: categoryId ? `categoryId=${categoryId}` : '' })
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        categoryFetcher.get({ actionName: 'getallwithproductcount' })
        fetchProductsAsync(state.filterCategoryId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCategoryChange = (value: string) => {
        fetchProductsAsync(value)
        setState(prevState => ({ ...prevState, filterCategoryId: value }));
        localStorage.setItem('selectedCategoryId', value);
    }

    return (
        <>
            <PageTitle>Prices</PageTitle>

            <Layout>
                <Sider width='20%' style={{ backgroundColor: '#F5F5F5' }} >
                    <CategoryTreeMenu 
                        categories={categoryFetcher.data} 
                        loading={categoryFetcher.isLoading} 
                        onChange={onCategoryChange}  />
                </Sider>
                <Content style={{ marginLeft: '20px', width: '80%'}}>
                    <Table
                        loading={productFetcher.isLoading}
                        rowKey={'id'}
                        size='small'
                        pagination={{ defaultPageSize: 12, size: 'default' }}
                        expandable={{  
                            expandRowByClick: true,
                            expandedRowRender: (record: any) => <VariationPrices productId={record.id} />,
                            expandedRowKeys: state.expandedKeys,
                            onExpand(expanded, record) {
                                const keys = [] as Key[]
                                if (expanded) keys.push(record.id)
                                setState(prevState => ({ ...prevState, expandedKeys: keys }))
                            } }}
                        columns={[
                            {
                                title: 'Category',
                                dataIndex: 'categoryName',
                                key: 'category',
                                className: state.filterCategoryId ? 'hide' : 'show'
                            },
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                                sorter: (a:any,b:any) => a.name.localeCompare(b.name),
                                ...ColumnTextFilter('name')
                            },
                            {
                                title: 'Description',
                                dataIndex: 'description',
                                key: 'description',
                                sorter: (a:any,b:any) => a.description?.localeCompare(b.description),
                                ...ColumnTextFilter('description')
                            },
                            {
                                title: 'ItemNumber',
                                dataIndex: 'itemNumber',
                                key: 'itemnumber',
                                sorter: (a:any,b:any) => a.itemnumber?.localeCompare(b.itemnumber),
                                ...ColumnTextFilter('itemnumber')
                            }
                        ]}
                        dataSource={productFetcher.data} 
                        style={{ paddingBottom: '10px'}} 
                        rowClassName={(record, index) => index % 2 === 1 ? 'table-striped-row' :  ''}
                    />
                </Content>
            </Layout>
        </>
    )
}