import React from 'react';
import { Tooltip } from 'antd'
import { MultiFetcher } from './MultiFetcher';
import { ContentType } from './enums/ContentType';

interface Props {
    imageId: string
    drawingNumber?: string
    fileName: string
}

export const ImageDownloadLink = (props: Props) => {
    const imageFetcher = MultiFetcher('image')

    const onClick = async (e: any, imageId: string, fileName: string) => {
        e.preventDefault()

        const image = await imageFetcher.get({ id: imageId, contentType: ContentType.Blob })
        
        DownloadBlob(image, props.fileName)
    }

    return (
        <Tooltip placement="topLeft" title={`Download ${props.fileName}`}>
            <a href='nothing' onClick={(e) => onClick(e, props.imageId, props.fileName)}>{props.drawingNumber ?? props.fileName}</a>
        </Tooltip>
    )
}

export const DownloadBlob = (data: any, fileName: string) => {
    const blob = new Blob([data])
    const objectUrl = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = objectUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
    URL.revokeObjectURL(objectUrl)
}