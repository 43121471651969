import React from 'react'
import { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import { isAuthorizedForRole } from 'components/_common/UserState'

export default class AuthorizeRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false,
      userRole: undefined
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated, userRole } = this.state
    var link = document.createElement("a")
    link.href = this.props.path
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`
    const { element } = this.props

    if (!ready) {
      return <div></div>;
    } else if (!authenticated) {
      return <Navigate replace to={redirectUrl} />
    } else if (this.props.userRole && !isAuthorizedForRole(this.props.userRole, userRole)) {
      return <Navigate replace to={`/unauthorized`} />
    } else {
      return element
    }
  }

  async populateAuthenticationState() {
    const user = await authService.getUser()
    const authenticated = !!user
    const userRole = user && user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']

    //console.log(`authenticated: [${authenticated}], userRole: [${userRole}]`)

    this.setState({ ready: true, authenticated, userRole });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false, userRole: undefined });
    await this.populateAuthenticationState();
  }
}
