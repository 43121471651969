import React from 'react';
import { Descriptions } from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { FormatDateTime } from 'components/_common/Formatters';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

interface Props {
    entry: any
}

export const AuditLogDetails = (props: Props) => {    
    
    return (
        <>
            <Descriptions bordered size='small' 
                labelStyle={{ width: '140px', minWidth: '110px', maxWidth: '165px', whiteSpace: 'nowrap' }}
                contentStyle={{ minWidth: 150 }}
                column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}>
                    <DescriptionsItem label='Timestamp'>{FormatDateTime(props.entry.timestamp)} </DescriptionsItem>
                    <DescriptionsItem label='Username'>{props.entry.userName}</DescriptionsItem>
                    <DescriptionsItem label='Route'>{props.entry.route}</DescriptionsItem>
                    <DescriptionsItem label='Method'>{props.entry.method}</DescriptionsItem>
            </Descriptions>
            { props.entry.body &&
            <>
                <br/>
                <div style={{ maxHeight: 600 }}>
                    <JsonView data={JSON.parse(props.entry?.body)} shouldExpandNode={allExpanded} style={defaultStyles} />
                </div>
            </>
            }
        </>
    )
}