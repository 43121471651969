import React, { useEffect } from 'react';
import { Space, Table, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { PageTitle } from 'components/_common/PageTitle';
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter';

export const SupplierList = () => {
    const fetcher = MultiFetcher('supplier');   

    useEffect(() => {
        window.scrollTo(0, 0);
        fetcher.get({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteItem = async (id: number) => {
        fetcher.del(id).then(async (success) => {
            if (success === true) {
                await fetcher.get({})
                message.info(`Supplier was deleted.`)
            }
        })
    }

    return (
        <>
            <PageTitle>Suppliers</PageTitle>

            <Table
                loading={fetcher.isLoading}
                rowKey={'id'}
                size='small'
                pagination={{ defaultPageSize: 12, size: 'default' }}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: (a:any,b:any) => a.name.localeCompare(b.name),
                        ...ColumnTextFilter('name')
                    },
                    {
                        title: 'Address',
                        dataIndex: 'address',
                        key: 'address',
                        sorter: (a:any,b:any) => a.address?.localeCompare(b.address),
                        ...ColumnTextFilter('address')
                    },
                    {
                        title: 'City',
                        dataIndex: 'city',
                        key: 'city',
                        sorter: (a:any,b:any) => a.city?.localeCompare(b.city),
                        ...ColumnTextFilter('city')
                    },
                    {
                        title: 'ZipCode',
                        dataIndex: 'zipCode',
                        key: 'zipcode',
                        sorter: (a:any,b:any) => a.zipCode?.localeCompare(b.zipCode),
                        ...ColumnTextFilter('zipCode')
                    },
                    {
                        title: 'Country',
                        dataIndex: 'countryName',
                        key: 'country',
                        sorter: (a:any,b:any) => a.countryName?.localeCompare(b.countryName),
                        ...ColumnTextFilter('countryName')
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        title: () => <TableRowButton buttontype='Add' url='/supplier/edit/' entity='Supplier' />,
                        render: (record: any) =>
                            <div>
                                <Space>
                                    <TableRowButton buttontype='Edit' recordid={record.id} url={`/supplier/edit`} entity='Supplier' />
                                    <TableRowButton buttontype='Delete' recordid={record.id} onClick={deleteItem} entity='Supplier' hasconfirm />
                                </Space>
                            </div>
                    }

                ]}
                dataSource={fetcher.data} 
                style={{ paddingBottom: '10px'}} 
                className='table-striped-rows' />
        </>
    )
}