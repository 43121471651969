import { RoundUpTo2Decimals } from "./Formatters"

export const calculateCustomerPrice = (price?: number, margin?: number): number => {
    //console.log(`price: [${price}]`)
    //console.log(`margin: [${margin}]`)
    if (!price) return 0
    
    const totalMargin = (margin ?? 0) * price / 100
    //console.log(`totalMargin: [${totalMargin}]`)
    const totalPrice = price + totalMargin
    //console.log(`totalPrice: [${totalPrice}]`)
    const roundedTotalPrice = RoundUpTo2Decimals(totalPrice)
    //onsole.log(`roundedTotalPrice: [${roundedTotalPrice}]`)
    return roundedTotalPrice
}