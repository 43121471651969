import { Modal, Table, Tooltip } from 'antd';
import { CustomButton, TableRowButton } from 'components/_common/CustomButtons';
import { FormatCurrency } from 'components/_common/Formatters';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import { CreatePayment } from './CreatePayment';
import { OuterModal } from 'components/_common/OuterModal';
import { ContentType } from 'components/_common/enums/ContentType';
import { useReactToPrint } from 'react-to-print';

interface State {
    selectedRowKeys: React.Key[]
    selectedOrders: any[]
    showCreatePaymentModal: boolean
    showDocumentHtmlModal: boolean
    documentTitle?:string
    documentHtml?: string
    orderId?: number
    orderNr?: string
}

interface Props {
    onRefresh(): void
    timeStamp: Date
}
 
export const OrdersForPayment = (props: Props) => {
    const [state, setState] = useState<State>({
        selectedRowKeys: [],
        selectedOrders: [],
        showCreatePaymentModal: false,
        showDocumentHtmlModal: false,
        documentTitle: undefined,
        documentHtml: undefined,
        orderId: undefined,
        orderNr: undefined
    })

    const refPrintableArea = useRef(null)

    const orderFetcher = MultiFetcher('orderForPayment')
    
    useEffect(() => {
        window.scrollTo(0, 0)
        orderFetcher.get({ })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.timeStamp])

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        //console.log('selectedRowKeys changed: ', selectedRowKeys);
        const selectedOrders = _.filter(orderFetcher.data, (order: any) => _.includes(selectedRowKeys, order.id as React.Key))
        //console.log('selectedOrders: ', JSON.stringify(selectedOrders));
        setState(prevState => ({ ...prevState, selectedRowKeys, selectedOrders }))
      };

    const showCreatePaymentModal = (show: boolean) => setState(prevState => ({ ...prevState, showCreatePaymentModal: show }))

    const onRefresh = () => {
        setState(prevState => ({ ...prevState, selectedRowKeys: [] }))
        props.onRefresh()
    }

    //== Invoice ==
    const documentFetcher = MultiFetcher('document')

    const closeDocumentHtmlModal = () => setState(prevState => ({ ...prevState, showDocumentHtmlModal: false, orderId: undefined, orderNr: undefined }))

    const showInvoice = (orderId: number, orderNr: string, paymentTerm: number, ) => {
        documentFetcher.get({ actionName: 'InvoiceHtml', queryParams: `orderId=${orderId}&paymentTerm=${paymentTerm}`, contentType: ContentType.Text }).then((response) => {
            //console.log(`response: ${response}`)
            setState(prevState => ({ ...prevState, orderId, orderNr, documentTitle: 'Invoice', documentHtml: response, showDocumentHtmlModal: true }))
        })
    }

    const printDocumentHtml = useReactToPrint({
        content: () => refPrintableArea.current
    })

    return (
        <>
            <Table
                loading={orderFetcher.isLoading}
                dataSource={orderFetcher.data}
                size='small'
                rowKey={'id'}
                showHeader={false}
                pagination={false}
                bordered={false}
                style={{ padding: 0, margin: 0}}
                locale= {{ emptyText: 'No data'}}
                className='table-striped-rows' 
                rowSelection={{
                    selectedRowKeys: state.selectedRowKeys,
                    onChange: onSelectChange
                }}
                columns={[
                    {
                        dataIndex: 'fullCustomerOrderNumber',
                        key: 'fullCustomerOrderNumber',
                        className: 'nowrap',
                        render: (value) => <Tooltip placement="topLeft" title={`Customer OrderNr: ${value}`}>{value}</Tooltip>,
                        responsive: ['xl'],
                    },
                    {
                        dataIndex: 'invoiceNr',
                        key: 'invoiceNr',
                        className: 'nowrap',
                        render: (value) => <Tooltip placement="topLeft" title={`Invoice Nr: ${value}`}>{value}</Tooltip>,
                    },
                    {
                        dataIndex: 'description',
                        key: 'description',
                        ellipsis: { showTitle: false },
                        render: (value) => <Tooltip placement="topLeft" title={`Description: ${value}`}>{value}</Tooltip>,
                    },
                    {
                        dataIndex: 'duePaymentTermName',
                        key: 'duePaymentTermName',
                        ellipsis: { showTitle: false },
                        render: (value) => <Tooltip placement="topLeft" title={`Due payment term: ${value}`}>{value}</Tooltip>,
                    },
                    {
                        dataIndex: 'duePrice',
                        key: 'duePrice',
                        render: (value) => <Tooltip placement="topLeft" title={`Due Price: ${FormatCurrency(value)}`}>{FormatCurrency(value)}</Tooltip>,
                        align: 'right',
                        width: 75
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        width: 50,
                        render: (record: any) => <TableRowButton buttontype='View' onClick={(e) => showInvoice(record.id, record.fullCustomerOrderNumber, record.duePaymentTermId)} entity='Invoice'/>
                    }
                ]}
                footer={() => 
                    <div style={{ width: 'fit-content', marginLeft: 'auto' }} >
                        <CustomButton buttontype='Pay' size='small' disabled={state.selectedRowKeys.length === 0} onClick={() => showCreatePaymentModal(true)} title='Pay for the selected order(s)'>Pay&nbsp;</CustomButton>
                    </div>
                }
            />

            <OuterModal
                title="Create New Payment" 
                open={state.showCreatePaymentModal} 
                width='60vw'
                style={{ maxWidth: '1440px'}}
                onClose={() => showCreatePaymentModal(false)} >
                    <CreatePayment selectedOrders={state.selectedOrders} onClose={() => showCreatePaymentModal(false)} onRefresh={onRefresh}/>
            </OuterModal>

            <Modal title={<div className='color-shipping'>{state.documentTitle} for {state.orderNr}</div>}
                open={state.showDocumentHtmlModal} 
                width={1440}
                className='progress-modal'
                onCancel={() => closeDocumentHtmlModal()}
                maskClosable={false}
                destroyOnClose
                footer={
                    <>
                        <CustomButton 
                            buttontype='Close' 
                            onClick={closeDocumentHtmlModal} />
                        <CustomButton 
                            buttontype='Print'
                            onClick={printDocumentHtml} />
                    </>
                }>
                    <div style={{ border: '1px solid silver', maxHeight: 'calc(80vh - 70px)', overflow: 'auto' }}>
                        <div ref={refPrintableArea} dangerouslySetInnerHTML={{ __html: state.documentHtml ?? ''}} />
                    </div>
            </Modal>
        </>
    )
}
