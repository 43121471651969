import React, { useEffect } from 'react';
import { Col, Form, Input, InputNumber, Row, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLoader } from 'components/_common/PageLoader';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { PageTitle } from 'components/_common/PageTitle';
import { CustomButton } from 'components/_common/CustomButtons';

export const ProductSizeDetails = () => {   
    const { id } = useParams()
    const navigate = useNavigate()
    const [ form ] = Form.useForm()

    const fetcher = MultiFetcher('productsize')

    useEffect(() => {
        window.scrollTo(0, 0)
        if (id) fetcher.get({ id })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Submit = async (data: any) => {
        const submitAsync = async (data: any):Promise<number> => {
            return id ? await fetcher.put(data) : fetcher.post(data);
        }

        await submitAsync(data).then((result) => {
            if (result > 0) {
                navigate('/productsize/list')
                message.info(`ProductSize was ${id ? 'updated' : 'created'}.`)
            }
        })
    }

    return (
        <>
            <PageTitle>ProductSize Details</PageTitle>

            <PageLoader loading={fetcher.isLoading}>
                <Form 
                    layout='horizontal'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={fetcher.data}
                    style={{ padding: '10px'}}
                    form={form}
                    onFinish={Submit}>
                    <Form.Item name='id' hidden={true}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Size in mm" name="sizeInMm" rules={[{ required: true, message: 'Size in mm is required.' }]}>
                        <InputNumber min={0} max={99999999} style={{width: 100}}></InputNumber>
                    </Form.Item>
                    <Form.Item label="PartNumberCode" name="partNumberCode" >
                        <Input style={{maxWidth: 100}} maxLength={5}></Input>
                    </Form.Item>
                </Form>
            </PageLoader>
                
            <br/>

            <Row>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Cancel' onClick={() => navigate(-1)} />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Save' onClick={() => form.submit()} />
                </Col>
            </Row>

        </>
    )
}