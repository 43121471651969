import React from "react"
import { Button, ButtonProps, Popconfirm } from "antd"
import { EditOutlined, DeleteOutlined, PlusOutlined, SaveOutlined, CloseOutlined, CheckOutlined, CheckSquareOutlined, ExclamationCircleOutlined, HistoryOutlined, SearchOutlined, 
    MailOutlined, BuildOutlined, CopyOutlined, RetweetOutlined, ImportOutlined, UndoOutlined, ClearOutlined, DollarOutlined, PercentageOutlined, ForwardOutlined, PrinterOutlined, TagsOutlined,
    ProfileOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { ButtonType } from "antd/es/button";

interface TableRowButtonProps extends ButtonProps  {
    buttontype: 'Add' | 'Edit' | 'Delete' | 'View' | 'Approve' | 'History' | 'Copy' |  'Mail' | 'Build' | 'Reorder' | 'Import' | 'Undo' | 'Clear' | 'Percentage' | 'Forward' | 'Tags' | 'Profile'
    recordid?: number | undefined
    url?: string
    onClick?(value: any): void
    entity?: string
    hasconfirm?: boolean
    confirmmessage?: string
}

export const TableRowButton = (props: TableRowButtonProps) => {
    const navigate = useNavigate()

    const onClick = (e?: any) => {
        console.log(`props.recordId: [${props.recordid}]`)
        if (props.url) 
            navigate(`${props.url}${props.recordid ? `/${props.recordid}` : ''}`)

        if (props.onClick){
            if (props.recordid === undefined) {
                props.onClick(e)
            }
            else {
                props.onClick(props.recordid)
            }
        }
    }

    let buttonIcon: React.ReactNode
    const defaultStyle = { verticalAlign: 'middle', paddingBottom: 2, }
    
    switch (props.buttontype) {
        case 'Add': 
            buttonIcon = <PlusOutlined style={defaultStyle}/>
            break;
        case 'Edit': 
            buttonIcon = <EditOutlined style={{...defaultStyle, color: 'goldenrod'}}/>
            break;
        case 'Delete': 
            buttonIcon = <DeleteOutlined style={{...defaultStyle, color: "darkred"}}/>
            break;
        case 'View': 
            buttonIcon = <SearchOutlined style={defaultStyle}/>
            break;
        case 'Approve': 
            buttonIcon = <CheckSquareOutlined style={{...defaultStyle, color: "green"}}/>
            break;
        case 'History': 
            buttonIcon = <HistoryOutlined style={defaultStyle}/>
            break;
        case 'Copy': 
            buttonIcon = <CopyOutlined style={{...defaultStyle, color: "goldenrod"}}  />
            break;
        case 'Mail': 
            buttonIcon = <MailOutlined style={{...defaultStyle, color: "green"}}/>
            break;
        case 'Build': 
            buttonIcon = <BuildOutlined style={defaultStyle}/>
            break;
        case 'Reorder': 
            buttonIcon = <RetweetOutlined style={defaultStyle}/>
            break;
        case 'Import': 
            buttonIcon = <ImportOutlined style={defaultStyle}/>
            break;
        case 'Undo': 
            buttonIcon = <UndoOutlined style={{...defaultStyle, color: "goldenrod"}}/>
            break;
        case 'Clear': 
            buttonIcon = <ClearOutlined style={{...defaultStyle, color: "darkred"}}/>
            break;
        case 'Percentage': 
            buttonIcon = <PercentageOutlined style={defaultStyle}/>
            break;
        case 'Forward': 
            buttonIcon = <ForwardOutlined style={{...defaultStyle, color: "darkgreen"}}/>
            break;
        case 'Tags': 
            buttonIcon = <TagsOutlined style={{...defaultStyle, color: "darkgreen"}}/>
            break;
        case 'Profile': 
            buttonIcon = <ProfileOutlined style={{...defaultStyle, color: "darkblue"}}/>
            break;
    }

    const buttonTitle = props.title ?? `${props.buttontype} ${props.buttontype === 'Add' ? 'new' : 'this'} ${props.entity ?? 'item'}`

    let buttonProps: TableRowButtonProps = Object.assign({}, props);
    //delete buttonProps["buttontype"];
    delete buttonProps.recordid;
    delete buttonProps.url;
    delete buttonProps.entity;
    delete buttonProps["hasconfirm"];
    delete buttonProps.confirmmessage;

    const button = <Button {...buttonProps}
        type={props.type ?? 'default'} 
        size={ props.size ?? 'small' }
        icon={buttonIcon} 
        onClick={(e) => {
            e.stopPropagation()
            if (!props.hasconfirm === true)
                onClick(e)
        }}
        title={buttonTitle} 
        disabled={props.disabled ?? false}/>    
    
    return props.hasconfirm === true ?
        (
            <Popconfirm
                title='Warning'
                description={props.confirmmessage ?? `Are you sure you want to ${props.buttontype.toLowerCase()} this ${props.entity ?? 'item'}?`}
                icon={<ExclamationCircleOutlined style={{ height: '20px', color: 'red', verticalAlign: 'middle' }} />}
                overlayClassName="z-index-9999"
                onCancel={(e) => e?.stopPropagation()}
                onConfirm={(e) => {
                    e?.stopPropagation()
                    onClick()
                }}>
                    {button}
                
            </Popconfirm>
        ) : 
        ( <>{button}</> )
}

export interface CustomButtonProps extends ButtonProps {
    buttontype: 'Close' | 'Ok'| 'Cancel' | 'Save' | 'Approve' | 'Pay' | 'Build' | 'Import' | 'Print' | 'Add'
    hasConfirm?: boolean
    confirmMessage?: string
}

export const CustomButton = (props: CustomButtonProps) => {
    const iconSize = (props.size === 'small' ? 14 : 18)
    const iconStyle = { height: iconSize, width: iconSize }
    let icon: React.ReactNode
    let title: string | undefined
    let children: React.ReactNode
    let type: ButtonType | undefined
    
    switch (props.buttontype) {
        case 'Close': 
            icon = <CloseOutlined style={iconStyle}/>
            title = props.title ?? 'Discard changes'
            children = props.children ?? 'Close'
            break
        case 'Ok': 
            icon = <CheckOutlined style={iconStyle}/>
            type = props.type ?? 'primary'
            title = props.title ?? 'Keep changes'
            children = props.children ?? 'Ok'
            break
        case 'Cancel': 
            icon = <CloseOutlined style={iconStyle}/>
            title = props.title ?? 'Discard all changes'
            children = props.children ?? 'Cancel'
            break
        case 'Save': 
            icon = <SaveOutlined style={iconStyle}/>
            type = props.type ?? 'primary'
            title = props.title ?? 'Save all changes'
            children = props.children ?? 'Save'
            break
        case 'Approve': 
            icon = <CheckSquareOutlined style={iconStyle}/>
            type = props.type ?? 'primary'
            title = props.title ?? 'Approve'
            children = props.children ?? 'Approve'
            break
        case 'Pay': 
            icon = <DollarOutlined style={iconStyle}/>
            type = props.type ?? 'primary'
            title = props.title ?? 'Pay'
            children = props.children ?? 'Pay'
            break
        case 'Build': 
            icon = <BuildOutlined style={iconStyle}/>
            type = props.type ?? 'primary'
            title = props.title ?? 'Build'
            children = props.children ?? 'Build'
            break
        case 'Import': 
            icon = <ImportOutlined style={iconStyle}/>
            type = props.type ?? 'primary'
            title = props.title ?? 'Import'
            children = props.children ?? 'Import'
            break
        // case 'Pdf': 
        //     icon = <FilePdfOutlined style={iconStyle}/>
        //     type = props.type ?? 'primary'
        //     title = props.title ?? 'Download Pdf'
        //     children = props.children ?? 'Download Pdf'
        //     break
        case 'Print': 
            icon = <PrinterOutlined style={iconStyle}/>
            type = props.type ?? 'primary'
            title = props.title ?? 'Print'
            children = props.children ?? 'Print'
            break
        case 'Add': 
            icon = <PlusOutlined style={iconStyle}/>
            type = props.type ?? 'default'
            title = props.title ?? 'Add'
            children = props.children ?? 'Add'
            break
        default: 
            title = props.title
            type = props.type ?? 'default'
            break
    }

    //-- destructure props, don't pass custom props through to Button HTML element
    const { hasConfirm, confirmMessage, ...buttonProps } = props

    const button = <Button {...buttonProps} 
                        icon={icon} 
                        title={title} 
                        type={type}
                        onClick={(e) => {
                            e.stopPropagation()
                            if (!props.hasConfirm === true)
                                props.onClick && props.onClick(e)
                        }}>{children}</Button>

    return props.hasConfirm === true ?
        (
            <Popconfirm
                title='Warning'
                description={props.confirmMessage}
                icon={<ExclamationCircleOutlined style={{ height: '20px', color: 'red', verticalAlign: 'middle' }} />}
                onCancel={(e) => e?.stopPropagation()}
                onConfirm={(e) => {
                    e?.stopPropagation()
                    if (props.onClick && e)
                        props.onClick(e)
                }}>
                    {button}
                
            </Popconfirm>
        ) : 
        ( <>{button}</> )
        
    
}

//-- SMALL Buttons --
interface ExpandButtonProps {
    expanded: boolean,
    onClick?(value: any): void
}

export const ExpandButton = (props: ExpandButtonProps) => {
    return (
        <button 
            type="button" 
            className={`ant-table-row-expand-icon ant-table-row-expand-icon-${props.expanded ? 'expanded' : 'collapsed'}`} 
            aria-label="Expand row" 
            aria-expanded={props.expanded} 
            onClick={props.onClick} />
    )
}