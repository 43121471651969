import React, { useContext, useEffect } from 'react';
import { Form, Input, message } from 'antd';
import { PageLoader } from 'components/_common/PageLoader';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { IsChangedOuterContext } from 'components/_common/OuterModal';

interface Props {
    paymentId: number
    onClose(): void
    onRefresh(): void
}

export const PaymentEdit = (props: Props) => {
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    const fetcher  = MultiFetcher('payment');   

    useEffect(() => {
        fetcher.get({ id: props.paymentId.toString() })
        form.setFieldValue('id', props.paymentId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Submit = async (data: any) => {
        const submitAsync = async (data: any):Promise<number> => await fetcher.put(data)

        await submitAsync(data).then((result) => {
            if (result > 0) {
                props.onClose()
                message.info('Payment reference was updated.')
                props.onRefresh()
            }
        })
    }

    return (
        <PageLoader loading={fetcher.isLoading}>
            <Form 
                layout='horizontal'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={fetcher.data}
                style={{ padding: '10px'}}
                form={form}
                onFinish={Submit}
                onValuesChange={() => setIsChanged(true)} >
                <Form.Item name='id' hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Reference" name="reference">
                    <Input style={{maxWidth: 225}} maxLength={25}/>
                </Form.Item>
                <button ref={submitRef} type="submit" className='hide' />
            </Form>
        </PageLoader>
    )
}