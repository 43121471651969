import React from 'react'

interface Props {
    finished: boolean
    color: string
    onClick?(): void
    tooltip?: string
}

export const FinishedSquare = (props: Props) => {
    return (
        <div style={{ width: '100%', height: '100%', cursor: props.onClick && 'pointer'}} title={props.tooltip} onClick={props.onClick} className='orderprogress-square'>
            {props.finished &&
                <div className={`bar color-${props.color.toLowerCase()}`} style={{width: `calc(100% - 5px`}}>&nbsp;</div>
            }
        </div>
    )
}