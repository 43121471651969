import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import * as _ from 'lodash';
import { MultiFetcher } from './MultiFetcher';

interface State {
    options: any
}

interface Props extends SelectProps {
    rolename?: string
}

export const ContactSelect = (props: Props) => {
    const [state, setState] = useState<State>({
        options: []
    })

    const contactFetcher = MultiFetcher('contact')

    useEffect(() => {
        contactFetcher.get({ 
            queryParams: props.rolename && `roleName=${props.rolename}`, 
            onDataFetched: OnContactsFetched
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   

    const OnContactsFetched = (data: any) => {
        var groups = _.groupBy(data, (item) => item.customerId)

        var options = _.map(groups, (group: any) => {
            //console.log(`group: ${JSON.stringify(group)}`)
            const contacts = _.map(group, (contact) => { 
                //console.log(`contact: ${JSON.stringify(contact)}`)
                return {'label': `${contact.name} ${contact.contactRoleName ? `(${contact.contactRoleName})` : ''}`, 'value': contact.id }
            })

            return {
                label: group[0].customerName,
                options: contacts
              }
        })

        setState(prevState => ({ ...prevState, options }))
    }

    const filterOptions = (input: any, option: any) => {
        let value = input.toLowerCase();
        //console.log(`option: ${JSON.stringify(option)}`)
        if (!option.options) {
        //if (!option.type.isSelectOptGroup) {
          return (
            option.props.label.toLowerCase().indexOf(value) >= 0
          );
        }
        return false
    }  

    return (
        <Select 
            {...props}
            loading={contactFetcher.isLoading} 
            placeholder={`select a ${props.rolename?.toLowerCase() ?? 'contact'}`}
            showSearch={true}
            //optionFilterProp="label"
            filterOption={filterOptions}
            options={state.options}>
        </Select>
    );
}