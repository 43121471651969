import React, { CSSProperties } from 'react'

interface Props {
    value?: string
    style?: CSSProperties
    className?: string
    onChange?(): void
}

export const FormLabel = (props: Props) => {
    return <label style={props.style} className={props.className}>{props.value}</label>
}
