import React, { useContext, useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { UserContext } from 'components/AppLayout';
import { FormatDate, FormatDateTime } from 'components/_common/Formatters';
import { UserRole, UserState } from 'components/_common/UserState';
import { QuoteRequestDetails } from 'components/Pages/QuoteRequests/QuoteRequestDetails';
import { OuterModal } from 'components/_common/OuterModal';

interface Props {
    onRefresh(): void
    timeStamp: Date
}

interface State {
    selectedOrderId?: number
    showEditModal: boolean
}

export const QuoteRequests = (props: Props) => {
    const [state, setState] = useState<State>({
        selectedOrderId: undefined,
        showEditModal: false
    })
    const { isAuthorizedForRole } = useContext<UserState>(UserContext)

    const quoteFetcher = MultiFetcher('quoterequest');   

    useEffect(() => {
        window.scrollTo(0, 0)
        quoteFetcher.get({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.timeStamp]);

    const showEditModal = (show: boolean) => setState(prevState => ({ ...prevState, showEditModal: show }))

    return (
        <>
            <Table
                dataSource={quoteFetcher.data} 
                loading={quoteFetcher.isLoading}
                rowKey={'id'}
                size='small'
                showHeader={false}
                pagination={false}
                bordered={false}
                className='table-striped-rows' 
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => { setState(prevState => ({ ...prevState, selectedOrderId: record.id, showEditModal: true }))}
                    }
                }}
                style={{ cursor: 'pointer'}}
                locale= {{ emptyText: 'No data'}}
                columns={[
                    {
                        title: 'Customer OrderNr.',
                        dataIndex: 'fullCustomerOrderNumber',
                        key: 'fullCustomerOrderNumber',
                        width: 125,
                        render: (value) => <Tooltip placement="topLeft" title={`Customer OrderNr: ${value}`}>{value}</Tooltip>,
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                        ellipsis: { showTitle: false },
                        render: (value) => <Tooltip placement="topLeft" title={`Description: ${value}`}>{value}</Tooltip>,
                        width: '60%'
                    },
                    ...isAuthorizedForRole(UserRole.SuperAdmin) ?
                    [{
                        title: 'Supplier',
                        dataIndex: 'supplierName',
                        key: 'supplier',
                        render: (value) => <Tooltip placement="topLeft" title={`Supplier: ${value}`}>{value}</Tooltip>,
                    }] : [],
                    {
                        title: 'Requested On',
                        dataIndex: 'quoteRequestedOn',
                        key: 'quoteRequestedOn',
                        render: (value: string, record: any) => <Tooltip placement="topLeft" title={`Requested on ${FormatDate(value)} by ${record.quoteRequestedBy}`}>{FormatDate(value)}</Tooltip>,
                        className: 'nowrap',
                        responsive: ['xxl']
                    }
                ]} 
            />

            <OuterModal
                title="Quote Request" 
                open={state.showEditModal} 
                fullScreen
                onClose={() => showEditModal(false)} >
                    <QuoteRequestDetails id={state.selectedOrderId} onClose={() => showEditModal(false)} onRefresh={() => props.onRefresh()}/>
            </OuterModal>
        </>
    )
}