import dayjs, { Dayjs } from "dayjs"

export const FormatCurrency = (value?: string | number | undefined) : string => {
    if (value === null || value === undefined) return ''

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    var float = (typeof value === 'string') ? Number.parseFloat(value) : value

    return formatter.format(float).replace('$', '$ ') 
}

export const FormatPercentage = (value: number) : string => value ? `${value} %` : ''

export const RoundUpTo2Decimals = (value: number) : number => {
    const simplified = Number.parseFloat(value.toFixed(8))
    return Math.ceil(simplified * 100) / 100
}

export const RoundTo3Decimals = (value: number) : number => {
    const simplified = Number.parseFloat(value.toFixed(8))
    return Math.round(simplified * 1000) / 1000
}

export const FormatDate = (value: string) : string => {
    return value ? dayjs(value).format('YYYY-MM-DD') : ''
}

export const FormatTime = (value: string) : string => {
    return value ? dayjs(value).format('HH:mm:ss') : ''
}

export const FormatDateTime = (value: string) : string => {
    return value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : ''
}

export const FormatCombinedDateTime = (date?: Dayjs, time?: Dayjs) => {
    if (!date) return ''

    const dateString = FormatDate(date?.toString() ?? '')
    const timeString = FormatTime(time?.toString() ?? '')

    return `${dateString}${timeString && `T${timeString}`}`
}

export const CreateOrderNumberPrefix = ( customerPrefix: string, year: number) => {
    return `${customerPrefix}${TakeLastTwoDigits(year)}`
}

export const FormatOrderNumber = ( prefix: string, orderNr: number, revisionNr: number) => { 
    const formattedOrderNr = ('0000' + orderNr).slice(-4)
    const formattedRevisionNr = ('00' + revisionNr).slice(-2)
    return `${prefix}.${formattedOrderNr}.${formattedRevisionNr}`
}

export const TakeLastTwoDigits = (value: number) : string => {
    return value ? value.toString().slice(-2) : ''
}

export const FormatVariationProperties = (record: any): string => {
    const props: string[] = []
    
    if (record.productVariation) {
        record.productVariation?.size1Name && props.push(record.productVariation.size1Name)
        record.productVariation?.threadType1Name && props.push(record.productVariation.threadType1Name)
        record.productVariation?.size2Name && props.push(record.productVariation.size2Name)
        record.productVariation?.threadType2Name && props.push(record.productVariation.threadType2Name)
        record.productVariation?.temperatureName && props.push(record.productVariation.temperatureName)
        //record.productVariation?.extraInfo && props.push(record.productVariation.extraInfo)
    }

    return props.join(', ')
}

export const JsonStringifySafe = (value: any):string =>{
    const getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key: any, value: any) => {
          if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
              return;
            }
            seen.add(value);
          }
          return value;
        };
      };

      return JSON.stringify(value, getCircularReplacer())
}