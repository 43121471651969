import React from 'react'

interface Props {
    percentage: number
    color: string 
    onClick?(): void
    tooltip?: string
}

export const ProgressSquare = (props: Props) => {
    return (
        <div style={{ width: '100%', height: '100%', cursor: props.onClick && 'pointer'}} title={props.tooltip} onClick={props.onClick} className='orderprogress-square'>
            <div className={`bar color-${props.color.toLowerCase()}`} style={{width: `calc(${props.percentage}% - 5px)`} }>&nbsp;</div>
                <div className='number'>{props.percentage}%</div>
        </div>
    )
}