import React, { useContext, useEffect, useState } from 'react'
import { Col, Divider, Form, Input, InputNumber, Row, Table } from 'antd'
import { CustomSelect } from 'components/_common/CustomSelect';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import * as _ from 'lodash';
import { PageSubTitle } from 'components/_common/PageTitle';
import { CategoryTreeSelect } from 'components/_common/CategoryTreeSelect';
import { IsChangedInnerContext } from 'components/_common/InnerModal';

interface Props {
    onClose(): void
    onAddMultiple(value: any[]): void
    new?: boolean
}

interface State {
    selectedVariationKeys: React.Key[]
    colorIds: number[]
}

export const AddMultipleOrderLines = (props: Props) => {  
    const [state, setState] = useState<State>({
        selectedVariationKeys: [],
        colorIds: []
    })
    
    const { setIsChanged, submitRef } = useContext(IsChangedInnerContext)
    const [form] = Form.useForm()

    const categoryFetcher = MultiFetcher('productCategory')
    const productFetcher = MultiFetcher('product')
    const productVariationFetcher = MultiFetcher('productvariation')
    const productColorFetcher = MultiFetcher('productcolor')
    
    useEffect(() => {
        window.scrollTo(0, 0)
        categoryFetcher.get({})
        productColorFetcher.get({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onCategoryChange = (value:string) => {
        productFetcher.get({ queryParams: `categoryId=${value}` })
        form.resetFields(['productId'])
    }

    const onProductChange = (value:string) => {
        productVariationFetcher.get({ queryParams: `productId=${value}` })
        form.resetFields(['productVariationId'])
    }

    const onSelectedVariationsChange = (newSelectedRowKeys: React.Key[]) => {
        setState(prevState => ({ ...prevState, selectedVariationKeys: newSelectedRowKeys }))
        form.setFieldValue('productVariationId', newSelectedRowKeys)
        //console.log(`selected keys: [${JSON.stringify(newSelectedRowKeys)}]`)
    }

    const onColorChange = (values:string) => {
        const colorIds = [] as number[]
        const quantity = form.getFieldValue('quantity')

        _.forEach(values, value => {          
            colorIds.push(Number.parseInt(value))

            if (!form.getFieldValue(`distribution_${value}`)) {
                form.setFieldValue(`distribution_${value}`, quantity)
            }
        })
        
        setState(prevState => ({ ...prevState, colorIds }))
    }

    const onQuantityChange = (value:any) => {
        _.forEach(state.colorIds, colorId => form.setFieldValue(`distribution_${colorId}`, value))
    }

    const onFormFinish = (value: any) => {
        //console.log(`value: ${JSON.stringify(value)}`)
        const orderLines: any[] = []
        
        _.forEach(value.productVariationId ?? [null], (productVariationId: any) => {
            let totalQuantity = 0

            const orderLine = {
                productVariationId: productVariationId,
                productVariation: _.find(productVariationFetcher.data, item => item.id === productVariationId),
                orderLineDetails: [] as any[],
                totalQuantity: value.quantity
            }

            _.forEach(value.productColorId ?? [null], (productColorId: any) => {
                const quantity = value[`distribution_${productColorId}`]
                const productColor = _.find(productColorFetcher.data, item => item.id === productColorId)
                orderLine.orderLineDetails.push({ productColorId, productColor, quantity })
                totalQuantity += quantity
                
            })

            orderLine.totalQuantity = totalQuantity

            orderLines.push(orderLine)
        })
        
        props.onAddMultiple(orderLines)
    }

    return (
        <Form 
            layout='horizontal'
            labelCol={{ span: 8, xl: 7, xxl: 6}}
            wrapperCol={{ span: 16, xl: 17, xxl: 18}}
            style={{ padding: '10px'}}
            form={form}
            onFinish={onFormFinish}
            onValuesChange={() => setIsChanged(true)} preserve={false} >
            <Form.Item name='orderId' hidden={true}><Input/></Form.Item>
            <Row>
                <Col span={24}>
                    <Form.Item label="Category" name={'categoryId'} rules={[{ required: true, message: 'Category is required.' }]}>
                        <CategoryTreeSelect categories={categoryFetcher.data} loading={categoryFetcher.isLoading} onChange={onCategoryChange} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label="Product" name={'productId'} rules={[{ required: true, message: 'Product is required.' }]} >
                        <CustomSelect data={productFetcher.data} loading={productFetcher.isLoading} onChange={onProductChange} required  style={{ width: 500 }} />
                    </Form.Item>    
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label="Variation(s)" name="productVariationId" rules={[{ required: true, message: 'Variation is required.' }]} >
                    <Table
                        loading={productVariationFetcher.isLoading}
                        rowKey={record => record.id ?? `${record.partNumber}_${record.drawingNumber}_${record.size1Id}_${record.size2Id}_${record.threadType1Id}_${record.threadType2Id}_${record.temperatureId}`}
                        size='small'
                        bordered
                        rowSelection={{ 
                            type: 'checkbox',
                            selectedRowKeys: state.selectedVariationKeys,
                            onChange: onSelectedVariationsChange
                        }}
                        columns={[
                            {
                                title: 'Part Number',
                                dataIndex: 'partNumber',
                                key: 'partNumber',
                                sorter: (a:any,b:any) => a.partNumber?.localeCompare(b.partNumber, undefined, { numeric: true, sensitivity: 'base' })
                            },
                            {
                                title: 'Drawing Number',
                                dataIndex: 'drawingNumber',
                                key: 'drawingNumber',
                                sorter: (a:any,b:any) => a.drawingNumber?.localeCompare(b.drawingNumber, undefined, { numeric: true, sensitivity: 'base' }),
                            },
                            {
                                title: 'Properties',
                                dataIndex: 'properties',
                                key: 'properties',
                                sorter: (a:any,b:any) => a.properties?.localeCompare(b.properties)
                            },
                        ]}
                        dataSource={productVariationFetcher.data} 
                        pagination={false}
                        style={{ paddingBottom: '10px'}} 
                        className='table-striped-rows' />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label="Color" name="productColorId" rules={[{ required: true, message: 'Color is required.' }]}>
                        <CustomSelect data={productColorFetcher.data} loading={productColorFetcher.isLoading} mode='multiple' required onChange={onColorChange} style={{ width: 150 }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label="Quantity per Variation" name="quantity">
                        <InputNumber onChange={onQuantityChange} min={1} max={9999999} className='text-align-right' controls={false}/>
                    </Form.Item>
                </Col>
            </Row>

            {state.colorIds?.length > 0 && 
                <>
                    <Divider />
                    <PageSubTitle>Color Distribution <b>per Variation</b></PageSubTitle>
                    
                    { state.colorIds.map(colorId => {
                        const foundColor = productColorFetcher.findDataItem(colorId)
                        const colorName = `${foundColor?.name}`
                        return (
                            <Row key={`col_${colorId}`}>
                                <Col span={24} key={`col_${colorId}`}>
                                    <Form.Item 
                                        label={colorName} 
                                        name={`distribution_${colorId}`} 
                                        rules={[{ required: true, message: `Quantity is required.` }]} 
                                        style={{ whiteSpace: 'nowrap'}}>
                                        <InputNumber 
                                            min={1} 
                                            max={9999999} 
                                            controls={false}
                                            style={{maxWidth: '100px'}} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    })}
                </>
            }
            <button ref={submitRef} type="submit" className='hide' />
        </Form>
    )
}