import React, { useContext } from 'react';
import { PageLoader } from './PageLoader';
import { Descriptions } from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { FormatDate } from './Formatters';
import { QuoteStatus } from './enums/QuoteStatus';
import { UserContext } from 'components/AppLayout';
import { UserRole, UserState } from './UserState';

interface Props {
    order: any,
    loading: boolean
}

export const OrderHeaderViewer = (props: Props) => {
    const { isAuthorizedForRole } = useContext<UserState>(UserContext)
    
    const showPaymentType = () => props.order?.quoteStatusId === QuoteStatus.Approved

    return (
        <PageLoader loading={props.loading}>
            <Descriptions bordered size='small' 
                labelStyle={{ width: '140px', minWidth: '110px', maxWidth: '165px', whiteSpace: 'nowrap' }}
                contentStyle={{ minWidth: 150 }}
                column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 4 }}>
                <DescriptionsItem label='Customer OrderNr.' style={{whiteSpace: 'nowrap'}}>{props.order?.fullCustomerOrderNumber} </DescriptionsItem>
                { isAuthorizedForRole(UserRole.SuperAdmin) &&
                    <DescriptionsItem label='Order Number' style={{whiteSpace: 'nowrap'}}>{props.order?.fullOrderNumber} </DescriptionsItem>
                }
                <DescriptionsItem label='Contact'>{props.order?.contactName} </DescriptionsItem>
                <DescriptionsItem label='Purchaser'>{props.order?.purchaserName} </DescriptionsItem>
                <DescriptionsItem label='Supplier'>{props.order?.supplierName} </DescriptionsItem>
                <DescriptionsItem label='Phase'>{props.order?.phaseName} </DescriptionsItem>
                <DescriptionsItem label='QuoteStatus'>{props.order?.quoteStatusName} </DescriptionsItem>
                {showPaymentType() &&
                    <DescriptionsItem label='Payment Type'>{props.order.paymentTypeName} </DescriptionsItem>
                }
                <DescriptionsItem label='Target Date'>{FormatDate(props.order?.targetDate)} </DescriptionsItem>
                <DescriptionsItem label='Delivery Type'>{props.order?.deliveryTypeName} </DescriptionsItem>
                { isAuthorizedForRole(UserRole.SuperAdmin) && !showPaymentType() &&
                    <DescriptionsItem label=''> </DescriptionsItem>
                }
                <DescriptionsItem label='Description' span={2}>{props.order?.description} </DescriptionsItem>
                <DescriptionsItem label='Notes' span={4}>{props.order?.notes} </DescriptionsItem>
            </Descriptions>
        </PageLoader>
    )
}