/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Space, Table, Tooltip, message } from 'antd'
import { TableRowButton } from 'components/_common/CustomButtons'
import { FormatCurrency } from 'components/_common/Formatters'
import { MultiFetcher } from 'components/_common/MultiFetcher'
import { OrderLineDetails } from './_orderDetails/OrderLineDetails'
import * as _ from 'lodash';
import { AddMultipleOrderLines } from './_common/AddMultipleOrderLines'
import { ColumnType, Key } from 'antd/es/table/interface'
import { WarningOutlined} from '@ant-design/icons';
import { QuoteStatus } from 'components/_common/enums/QuoteStatus'
import { InnerModal } from 'components/_common/InnerModal'
import { ColorDisplay } from 'components/_common/ColorDisplay'

interface State {
    expandedKeys?: Key[]
    editOrderLine?: any
    showAddMultipleModal: boolean
}

interface Props {
    orderRevisionId: number
    quoteStatusId: number
    readOnly?: boolean
    timeStamp?: Date
}

export const OrderLinesList = (props:Props) => {
    const [state, setState] = useState<State>({
        expandedKeys: undefined,
        editOrderLine: undefined,
        showAddMultipleModal: false
    })
    
    const orderLineFetcher = MultiFetcher('orderline')
    const getOrderLines = async () => await orderLineFetcher.get({ queryParams: `orderRevisionId=${props.orderRevisionId}`})      

    useEffect(() => {
        window.scrollTo(0, 0)
        getOrderLines()  
    }, [props.timeStamp]);

    // == Add Single ==
    const onAdd = () => {
        const maxLineNumber = _.maxBy(orderLineFetcher.data, (p: any) => p.lineNumber)?.lineNumber ?? 0
        setState(prevState => ({ ...prevState, editOrderLine: { orderRevisionId: props.orderRevisionId, lineNumber: maxLineNumber + 1 } }))
    }

    const onEdit = (orderLine: any) => () => setState(prevState => ({ ...prevState, editOrderLine: orderLine }))

    const onDelete = async (id: number) => {
        const deleteAsync = async (id: any):Promise<boolean> => {
            return await orderLineFetcher.del(id)
        }

        await deleteAsync(id).then(async (success) => {
            if (success === true) {
                message.info(`OrderLine was deleted.`)
                await getOrderLines()
            }
        })
    }

    const onSave = async (orderLine: any) => {
        const submitAsync = async (orderLine: any):Promise<number> => {
            return orderLine.id ? await orderLineFetcher.put(orderLine) : orderLineFetcher.post(orderLine);
        }

        // filter out empty orderlinedetails
        orderLine.orderLineDetails = orderLine.orderLineDetails.filter(p => p.quantity > 0)

        //console.log(`orderLine: [${JSON.stringify(orderLine)}]`)

        await submitAsync(orderLine).then(async (result) => {
            if (result > 0) {
                onClose()
                if (orderLine.id) {
                    // Update
                    message.info(`OrderLine ${orderLine.lineNumber} was updated.`)
                } else {
                    // Add
                    const updatedOrderline = orderLineFetcher.findDataItem(result as number)

                    if (updatedOrderline){
                        message.info(`OrderLine ${updatedOrderline.lineNumber} was updated.`)
                    } else {
                        message.info(`New OrderLine was created.`)
                    }
                    
                }
                
                await getOrderLines()
            }
        })
    }

    const onClose = () => setState(prevState => ({ ...prevState, editOrderLine: undefined }))

    //== Add Multiple ==
    const showAddMultipleModal= (visible: boolean) => setState(prevState => ({ ...prevState, showAddMultipleModal: visible }))

    const onAddMultipleSave = async (orderLines: any[]) => {
        //console.log(`orderLines: ${JSON.stringify(orderLines)}`)
        let nextLineNumber = _.maxBy(orderLineFetcher.data, (p: any) => p.lineNumber)?.lineNumber ?? 0

        _.forEach(orderLines, orderLine => {
            orderLine.orderRevisionId = props.orderRevisionId
            orderLine.lineNumber = ++nextLineNumber

            // filter out empty orderlinedetails
            orderLine.orderLineDetails = orderLine.orderLineDetails.filter(p => p.quantity > 0)
        })

        await orderLineFetcher.post(orderLines, 'postMultiple').then(async (result) => {
            if (result > 0) {
                showAddMultipleModal(false)
                message.info(`OrderLines were added and/or updated.`)
                await getOrderLines()
            }
        })
    }

    return (
        <>
            <Table
                loading={orderLineFetcher.isLoading}
                rowKey={record => record.lineNumber ?? `color_${record.productColorId}`}
                size='small'
                pagination={false}
                expandable={{
                    expandRowByClick: true,
                    childrenColumnName: 'orderLineDetails',
                    expandedRowKeys: state.expandedKeys,
                    onExpand(expanded, record) {
                        const keys = [] as Key[]
                        if (expanded) keys.push(record.lineNumber)
                        setState(prevState => ({ ...prevState, expandedKeys: keys }))
                    },
                }}
                columns={[
                    {
                        title: '#',
                        dataIndex: 'lineNumber',
                        key: 'linenumber',
                        align: 'right',
                        width: 50,
                        className: 'nowrap'
                    },
                    {
                        title: 'Product',
                        dataIndex: ['productVariation', 'productName'],
                        key: 'product',
                        ellipsis: { showTitle: false },
                        render: (value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                        width: '12%',
                    },
                    {
                        title: 'Part Number',
                        dataIndex: ['productVariation', 'partNumber'],
                        key: 'partNumber',
                        ellipsis: { showTitle: false },
                        render: (value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                        responsive: ['lg'],
                        width: '12%',
                    },
                    {
                        title: 'Drawing Number',
                        dataIndex: ['productVariation', 'drawingNumber'],
                        key: 'drawingNumber',
                        ellipsis: { showTitle: false },
                        render: (value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                        responsive: ['lg'],
                        width: '12%',
                    },
                    {
                        title: 'Properties',
                        dataIndex: ['productVariation', 'properties'],
                        key: 'properties',
                        ellipsis: { showTitle: false },
                        render: (value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                        responsive: ['lg'],
                        width: '12%',
                    },
                    ...((state.expandedKeys && state.expandedKeys.length > 0) ?
                    [{
                        title: 'Color',
                        dataIndex: 'productColor',
                        key: 'color',
                        className: 'nowrap',
                        width: 50,
                        render: (value) => <ColorDisplay productColor={value} />
                    }] : []),
                    {
                        title: 'Qty',
                        key: 'quantity',
                        align: 'right',
                        width: 55,
                        render: (record: any) => {
                            if ('totalQuantity' in record) {
                                if ((props.quoteStatusId === QuoteStatus.Quoted && record.minimumOrderQuantity && Number.parseInt(record.totalQuantity) < Number.parseInt(record.minimumOrderQuantity)) || 
                                    (props.quoteStatusId === QuoteStatus.New && record.productVariation.lastPrice && Number.parseInt(record.totalQuantity) < Number.parseInt(record.productVariation.lastPrice.minimumOrderQuantity))){
                                    return <div>
                                                <Tooltip title='This item does not meet the minimum order quantity and a setup fee will be charged. Please update your quantity to a higher value to eliminate this fee.'>
                                                    <WarningOutlined style={{ color: 'orange', fontSize: 16, marginRight: 5, paddingBottom: 4, verticalAlign: 'bottom' }}></WarningOutlined>{record.totalQuantity}
                                                </Tooltip>
                                            </div>
                                } else {
                                    return record.totalQuantity
                                }

                            } else {
                                return record.quantity
                            }
                        }
                    },
                    {
                        title: <Tooltip title='Minimum Order Quantity'>MOQ</Tooltip>,
                        key: 'minimumOrderQuantity',
                        align: 'right',
                        width: 55,
                        render: (record: any) => {
                            if (props.quoteStatusId === QuoteStatus.Quoted) 
                                return record.minimumOrderQuantity
                            else if (props.quoteStatusId === QuoteStatus.New) 
                                return record.productVariation?.lastPrice?.minimumOrderQuantity
                            else return ''
                        }
                    },
                    {
                        title: 'Unit Price',
                        dataIndex: 'customerUnitPrice',
                        key: 'customerUnitPrice',
                        align: 'right',
                        width: 75,
                        render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>
                    },
                    {
                        title: 'Parts Total',
                        key: 'customerPrice',
                        align: 'right',
                        width: 100,
                        render: (record: any) => <div className='nowrap'>{record.customerUnitPrice && FormatCurrency(record.totalQuantity * record.customerUnitPrice)}</div>
                    },
                    {
                        title: 'Setup Cost',
                        dataIndex: 'customerSetupCost',
                        key: 'customerSetupCost',
                        align: 'right',
                        width: 75,
                        render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>
                    },
                    {
                        title: 'Mold Cost',
                        dataIndex: 'customerMoldCost',
                        key: 'customerMoldCost',
                        align: 'right',
                        width: 75,
                        render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>
                    },
                    {
                        title: 'Total Price',
                        key: 'totalCustomerPrice',
                        align: 'right',
                        width: 100,
                        render: (record: any) => <div className='nowrap'>{record.customerUnitPrice && FormatCurrency(record.totalQuantity * record.customerUnitPrice + (record.customerSetupCost ?? 0) + (record.customerMoldCost ?? 0))}</div>
                    },
                    ...(!props.readOnly ?
                    [{
                        key: 'actions',
                        align: 'right',
                        width: 100,
                        title: () => 
                            <Space>
                                <TableRowButton buttontype='Build' onClick={() => showAddMultipleModal(true)} title='Add Multiple OrderLines' />
                                <TableRowButton buttontype='Add' onClick={onAdd} entity='OrderLine' />
                            </Space>,
                        render: (text, record:any, index: number) => !record.orderRevisionId ||
                            <div>
                                <Space>
                                    <TableRowButton buttontype='Edit' onClick={onEdit(record)} entity='OrderLine' />
                                    <TableRowButton buttontype='Delete' recordid={record.id} onClick={onDelete} entity='OrderLine' hasconfirm />
                                </Space>
                            </div>
                    } as ColumnType<any>] : []),
                ]}
                rowClassName={(record: any, index) => (!record.orderId && record.quantity === 0) ? 'hide' : ( index % 2 === 1 ? 'table-striped-row' :  '')}
                dataSource={orderLineFetcher.data}
                style={{ width: 'auto', paddingBottom: '10px'}} 
                summary={(pageData) => {
                    let totalPrice = 0;
            
                    pageData.forEach(({ totalQuantity, customerUnitPrice, customerSetupCost, customerMoldCost }) => {
                        totalPrice += (totalQuantity * (customerUnitPrice ?? 0)) + (customerSetupCost ?? 0) + (customerMoldCost ?? 0)
                    });
            
                    return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}>&nbsp;</Table.Summary.Cell>
                          <Table.Summary.Cell index={1} colSpan={state.expandedKeys && state.expandedKeys.length > 0 ? 11 : 10}>Total</Table.Summary.Cell>
                          <Table.Summary.Cell index={4} align='right' className='nowrap'>{FormatCurrency(totalPrice)}</Table.Summary.Cell>
                          {!props.readOnly &&
                            <Table.Summary.Cell index={5}></Table.Summary.Cell>
                          }
                        </Table.Summary.Row>
                    );
                  }}
            />
            
            <InnerModal
                title={state.editOrderLine?.id ? `Edit OrderLine #${state.editOrderLine.lineNumber}` : 'Add New OrderLine'}
                open={state.editOrderLine !== undefined} 
                width={650} 
                onClose={onClose} >
                <OrderLineDetails 
                    orderLine={state.editOrderLine} 
                    onClose={onClose} 
                    onSave={onSave} />
            </InnerModal>

            <InnerModal
                title="Add Multiple OrderLines" 
                open={state.showAddMultipleModal} 
                width='80vw'
                style={{ maxWidth: '800px'}}
                onClose={() => showAddMultipleModal(false)} >
                <AddMultipleOrderLines
                    onClose={() => showAddMultipleModal(false)}
                    onAddMultiple={onAddMultipleSave} />
            </InnerModal>
        </>
    )
}