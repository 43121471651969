import React, { useContext, useEffect, useState } from 'react'
import { Space, Table, Tooltip, message } from 'antd'
import { MultiFetcher } from 'components/_common/MultiFetcher'
import { TableRowButton } from 'components/_common/CustomButtons'
import { PageTitle } from 'components/_common/PageTitle'
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter'
import { FormatDate } from 'components/_common/Formatters'
import { OrderLinesList } from 'components/Pages/Orders/OrderLinesList'
import { OrderStatusTabs } from 'components/Pages/Orders/_common/OrderStatusTabs'
import { OrderView } from './OrderView'
import { OrderDetails } from './OrderDetails'
import { UserRole, UserState } from 'components/_common/UserState'
import { UserContext } from 'components/AppLayout'
import { ColumnType, Key } from 'antd/es/table/interface'
import { QuoteStatus } from 'components/_common/enums/QuoteStatus'
import { OrderEditMode } from './_common/OrderEditMode'
import { Phase } from 'components/_common/enums/Phase'
import { dateCompare } from 'components/_common/Generic'
import { OuterModal } from 'components/_common/OuterModal'

interface State {
    expandedKeys?: Key[]
    filterPhaseId: string
    filterStatusId?: string
    viewOrderId?: number
    approveQuote: boolean
    showEditModal: boolean
    editOrderId?: number
    editMode: OrderEditMode
    timeStamp: Date
}

export const OrderList = () => {
    const { isAuthorizedForRole } = useContext<UserState>(UserContext)

    const [state, setState] = useState<State>({
        expandedKeys: undefined,
        filterPhaseId: localStorage.getItem('selectedPhaseId') ?? '1',
        filterStatusId: localStorage.getItem('selectedQuoteStatusId') ?? undefined,
        viewOrderId: undefined,
        approveQuote: false,
        showEditModal: false,
        editOrderId: undefined,
        editMode: OrderEditMode.Edit,
        timeStamp: new Date()
    })

    const orderFetcher = MultiFetcher('order')
    const quoteActionsFetcher = MultiFetcher('quoteActions')

    const getOrdersAsync = async (phaseId: string, statusId?: string) => orderFetcher.get({ queryParams: `phaseId=${phaseId}&statusId=${statusId ?? ''}` })

    useEffect(() => {
        window.scrollTo(0, 0)
 
        getOrdersAsync(state.filterPhaseId, state.filterStatusId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOrderStatusChange = (phaseId: string, statusId?: string) => {
        console.log(`statusChange: [${phaseId}, ${statusId}]`)
        setState(prevState => ({ ...prevState, filterPhaseId: phaseId, filterStatusId: statusId}))
        getOrdersAsync(phaseId, statusId)
    }   

    const viewOrder = (id: number) => setState(prevState => ({ ...prevState, viewOrderId: id, approveQuote: false }))
    const approveQuote = (id: number) => setState(prevState => ({ ...prevState, viewOrderId: id, approveQuote: true }))
    const addOrder = () => setState(prevState => ({ ...prevState, showEditModal: true, editMode: OrderEditMode.Add, editOrderId: 0 }))
    const editOrder = (id: number) => setState(prevState => ({ ...prevState, showEditModal: true, editMode: OrderEditMode.Edit, editOrderId: id }))
    const reOrder = (id: number) => setState(prevState => ({ ...prevState, showEditModal: true, editMode: OrderEditMode.ReOrder, editOrderId: id }))
    const reQuote = (id: number) => setState(prevState => ({ ...prevState, showEditModal: true, editMode: OrderEditMode.ReQuote, editOrderId: id }))
    

    const onDeleteOrder = async (id: number, orderNumber: string) => {
        orderFetcher.del(id).then(async (success) => {
            if (success === true) {
                refreshAll()
                message.info(`Order ${orderNumber} was deleted.`)
            }
        })
    }

    const onRequestQuote = (id: number, orderNumber: string) => {
        const data = {
            orderId: id
        }
        quoteActionsFetcher.post(data, 'RequestQuote').then(async (success) => {
            if (success) {
                await refreshAll()
                message.info(`A Quote Request for Order ${orderNumber} has been sent.`)
            }
        })
    }

    const onApproveQuote = async (id: number, orderNumber: string, paymentTypeId: number, deliveryTypeId: number) => {
        const data = {
            orderId: id,
            paymentTypeId,
            deliveryTypeId
        }
        quoteActionsFetcher.post(data, 'ApproveQuote').then(async (success) => {
            if (success) {
                setState(prevState => ({ ...prevState, viewOrderId: undefined  }))
                await refreshAll()
                message.info(`Order ${orderNumber} was approved.`)
            }
        })
    }

    const refreshAll = async () => {
        await getOrdersAsync(state.filterPhaseId, state.filterStatusId) 
        setState(prevState => ({ ...prevState, timeStamp: new Date() }))
    }

    return (
        <>
            <PageTitle>Orders</PageTitle>

            <OrderStatusTabs timeStamp={state.timeStamp} onStatusChange={onOrderStatusChange} />
                
            <Table
                loading={orderFetcher.isLoading}
                size='small'
                pagination={{ defaultPageSize: 12, size: 'default' }}
                rowKey={'id'}
                expandable={{  
                    expandRowByClick: true,
                    expandedRowRender: (record: any) => <OrderLinesList orderRevisionId={record.lastRevision.id} quoteStatusId={record.quoteStatusId} timeStamp={state.timeStamp} 
                        readOnly={ !isAuthorizedForRole(UserRole.SuperAdmin) && Number.parseInt(record.quoteStatusId) > QuoteStatus.New } />,
                    expandedRowKeys: state.expandedKeys,
                    onExpand(expanded, record) {
                        const keys = [] as Key[]
                        if (expanded) keys.push(record.id)
                        setState(prevState => ({ ...prevState, expandedKeys: keys }))
                    } }}
                showSorterTooltip={{ placement: 'bottom' }}
                columns={[
                    {
                        title: 'Customer OrderNr.',
                        dataIndex: 'fullCustomerOrderNumber',
                        key: 'fullCustomerOrderNumber',
                        sorter: (a:any,b:any) => a.fullCustomerOrderNumber.localeCompare(b.fullCustomerOrderNumber),
                        ...ColumnTextFilter('fullOrderNumber'),
                    },
                    ...isAuthorizedForRole(UserRole.SuperAdmin) ?
                    [{
                        title: 'Order Number',
                        dataIndex: 'fullOrderNumber',
                        key: 'ordernumber',
                        sorter: (a:any,b:any) => a.fullOrderNumber.localeCompare(b.fullOrderNumber),
                        //...ColumnTextFilter('fullOrderNumber'),==> BUG on refresh
                        responsive: ['xxl']
                    } as ColumnType<any>] : [],
                    ...(!state.filterStatusId && state.filterPhaseId === '1' ?
                    [{
                        title: 'Status',
                        dataIndex: 'quoteStatusName',
                        key: 'quoteStatus',
                        sorter: (a:any,b:any) => a.quoteStatusName.localeCompare(b.quoteStatusName),
                    }] : []),
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                        ellipsis: { showTitle: false },
                        render: (value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                        width: '25vw'
                    },
                    {
                        title: 'Contact',
                        dataIndex: 'contactName',
                        key: 'contact',
                        sorter: (a:any,b:any) => a.contactName.localeCompare(b.contactName),
                        ...ColumnTextFilter('contactName'),
                        responsive: ['xxl']
                    },
                    {
                        title: 'Purchaser',
                        dataIndex: 'purchaserName',
                        key: 'purchaser',
                        sorter: (a:any,b:any) => a.purchaserName.localeCompare(b.purchaserName),
                        ...ColumnTextFilter('purchaserName'),
                        responsive: ['xxl']
                    },
                    {
                        title: 'Supplier',
                        dataIndex: 'supplierName',
                        key: 'supplier',
                        sorter: (a:any,b:any) => a.supplierName.localeCompare(b.supplierName),
                        ...ColumnTextFilter('supplierName')
                    },
                    {
                        title: 'Target Date',
                        dataIndex: 'targetDate',
                        key: 'targetDate',
                        sorter: (a: any, b: any) => dateCompare(a.targetDate, b.targetDate),
                        render: (value: string) => <div>{FormatDate(value)}</div>,
                        responsive: ['xxl']
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        width: 250,
                        title: () => <TableRowButton buttontype='Add' onClick={() => addOrder()} entity='Order' />,
                        render: (record: any) => {
                            const phaseId = Number.parseInt(record.phaseId)
                            const statusId = Number.parseInt(record.quoteStatusId)
                            return <div>
                                        <Space>
                                            <TableRowButton buttontype='View' onClick={(e) => viewOrder(record.id)} entity='Order'/>
                                                {
                                                    (statusId === QuoteStatus.New || isAuthorizedForRole(UserRole.SuperAdmin)) &&
                                                        <TableRowButton buttontype='Edit' onClick={() => editOrder(record.id)} title="Edit Order"/>                                           
                                                }
                                                { phaseId === Phase.Quotation && 
                                                <>
                                                    {
                                                        (statusId === QuoteStatus.Quoted && isAuthorizedForRole(UserRole.CustomerAdmin)) &&
                                                            <TableRowButton buttontype='Undo' onClick={() => reQuote(record.id)} title="Edit for Re-Quote"/>
                                                    }
                                                    {
                                                        statusId === QuoteStatus.New && isAuthorizedForRole(UserRole.CustomerUser) &&
                                                            <TableRowButton buttontype='Mail' onClick={() => onRequestQuote(record.id, record.fullCustomerOrderNumber)} title='Request Quote' hasconfirm confirmmessage='Are you sure you want to Request a Quote for this Order?' />
                                                    }
                                                    {
                                                        statusId === QuoteStatus.Quoted && isAuthorizedForRole(UserRole.CustomerAdmin) &&
                                                            <TableRowButton buttontype='Approve' onClick={(e) => approveQuote(record.id)} entity='Quote' title='Approve this Quote' />
                                                    }
                                                </>
                                            }
                                            { isAuthorizedForRole(UserRole.CustomerUser) &&
                                                <TableRowButton buttontype='Reorder' onClick={(e) => reOrder(record.id)} title='Re-order' />
                                            }
                                            { ((phaseId === Phase.Quotation && statusId === QuoteStatus.New) || isAuthorizedForRole(UserRole.SuperAdmin)) &&
                                                <TableRowButton buttontype='Delete' onClick={() => onDeleteOrder(record.id, record.fullCustomerOrderNumber)} entity='Order' hasconfirm confirmmessage='Are you sure you want to Delete this Order?' />
                                            }
                                        </Space>
                                    </div>
                        }
                    }

                ]}
                dataSource={orderFetcher.data} 
                style={{ paddingBottom: '10px', paddingTop: 0, marginTop: 0}} 
                rowClassName={(record, index) => index % 2 === 1 ? 'table-striped-row' :  ''}
                />

            <OuterModal
                title="View Order Details" 
                open={state.viewOrderId !== undefined} 
                width='80vw'
                style={{ minWidth: 1000, maxWidth: '1440px'}}
                onClose={() => setState(prevState => ({ ...prevState, viewOrderId: undefined }))} 
                saveButtonProps={ state.approveQuote ? { 
                    buttontype: 'Approve',
                    children: 'Approve',
                    title: 'Approve this Quote',
                    hasConfirm: true,
                    confirmMessage: 'Are you sure you want to Approve this Quote?',
                    disabled: false
                } : { buttontype: 'Ok', style: { display: 'none'} }}>
                    { state.viewOrderId && 
                        <div style={{width: '100%'}}>
                            <OrderView 
                                orderId={state.viewOrderId}
                                approveQuote={state.approveQuote}
                                onClose={() => setState(prevState => ({ ...prevState, viewOrderId: undefined }))} 
                                onApprove={onApproveQuote}/>
                        </div>
                    }
            </OuterModal>

            <OuterModal
                title={`${state.editMode} Order`}
                open={state.showEditModal} 
                width='80vw'
                style={{ minWidth: 1000, maxWidth: '1440px'}}
                onClose={() => setState(prevState => ({ ...prevState, showEditModal: false, editOrderId: undefined }))} >
                    <div style={{width: '100%'}}>
                        <OrderDetails
                            orderId={state.editOrderId}
                            editMode={state.editMode}
                            onClose={() => setState(prevState => ({ ...prevState, showEditModal: false, editOrderId: undefined }))} 
                            onSubmitted={refreshAll} />
                        </div>
            </OuterModal>
        </>
    )
}