import { Button, ButtonProps, Input, Upload } from 'antd'
import React from 'react'
import { UploadChangeParam } from 'antd/es/upload'
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';

interface Props {
   value?: string
   onUpload?(file: UploadChangeParam): void
   onDelete?(): void
   accept?: string
}

export const FileUpload = (props:Props) => {
    return (
        <>
            <Input value={props.value} readOnly></Input>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ paddingTop: '4px'}}>
                    <Upload 
                        multiple={false} 
                        maxCount={1} 
                        beforeUpload={() => false} 
                        onChange={props.onUpload} 
                        showUploadList={false}
                        accept={props.accept}>
                        <UploadButton title='Upload New File' />
                    </Upload>
                </div>
                { props.onDelete && 
                    <div style={{ paddingTop: '4px', paddingLeft: '4px'}}>
                        <DeleteButton 
                            onClick={props.onDelete} 
                            title='Remove current File' />
                    </div>
                }
            </div>
        </>
    )
}

export const UploadButton = (props: ButtonProps) => {
    return (
        <Button {...props} type='default' size='small' style={{ fontSize: '12px' }} icon={<UploadOutlined style={{ fontSize: '12px', verticalAlign: 'middle', marginTop: '0px' }}/>} title={props.title ?? 'Upload File'}>Upload</Button>
    )
}

export const DeleteButton = (props: ButtonProps) => {
    return (
        <Button {...props} type='default' size='small' style={{ fontSize: '12px' }} danger icon={<DeleteOutlined style={{ fontSize: '12px', verticalAlign: 'middle', marginTop: '0px' }}/>} title={props.title ?? 'Delete'}>Remove</Button>
    )
}