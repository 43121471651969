import { Space, Table, Tooltip } from 'antd'
import { ColumnType } from 'antd/es/table/interface'
import { TableRowButton } from 'components/_common/CustomButtons'
import React, { useState } from 'react'
import { PackedBoxDetails } from './PackedBoxDetails'
import { InnerModal } from 'components/_common/InnerModal'
import _ from 'lodash'
import { WarningOutlined} from '@ant-design/icons';
import { RoundTo3Decimals } from 'components/_common/Formatters'

interface Props {
    orderLineDetailId: number
    packedBoxes: any[]
    qtyPlanned: number
    readonly?: boolean
    onUpdate(orderLineDetailId: number, packedBoxes: any[]): void
    timeStamp?: Date
}

interface State {
    showDetailsModal: boolean
    selectedIndex?: number
    packedBox: any
}

export const PackedBoxes = (props: Props) => {
    const [state, setState] = useState<State>({
        showDetailsModal: false,
        selectedIndex: undefined,
        packedBox: undefined
    })

    const onAdd = () => setState(prevState => ({ ...prevState, showDetailsModal: true, selectedIndex: undefined, packedBox: undefined }))
    const onEdit = (index: number) => setState(prevState => ({ ...prevState, showDetailsModal: true, selectedIndex: index, packedBox: props.packedBoxes[index] }))
    const onDelete = (index: number) => {
        const packedBoxes = props.packedBoxes.slice(0)
        packedBoxes.splice(index, 1)
        props.onUpdate(props.orderLineDetailId, packedBoxes)
    }

    const closeDetailsModal = () => setState(prevState => ({ ...prevState, showDetailsModal: false, selectedIndex: undefined, packedBox: undefined }))
    
    const onSave = (packedBox: any) => {
        const packedBoxes = props.packedBoxes?.slice(0) ?? []

        const recalculateTotals = (packedBox: any) => {
            packedBox.totalParts = packedBox.numberOfBoxes * packedBox.partsPerBox
            packedBox.totalWeight = packedBox.weightPerBox ? RoundTo3Decimals(packedBox.weightPerBox * packedBox.numberOfBoxes) : null
        }

        if (state.selectedIndex !== undefined) {
            const foundPackedBox = packedBoxes[state.selectedIndex]
            foundPackedBox.boxId = packedBox.boxId
            foundPackedBox.box = packedBox.box
            foundPackedBox.numberOfBoxes = packedBox.numberOfBoxes
            foundPackedBox.partsPerBox = packedBox.partsPerBox
            foundPackedBox.weightPerBox = packedBox.weightPerBox

            recalculateTotals(foundPackedBox)

        } else {
            packedBox.orderLineDetailId = props.orderLineDetailId
            recalculateTotals(packedBox)
            packedBoxes.push(packedBox)
        }

        props.onUpdate(props.orderLineDetailId, packedBoxes)
        closeDetailsModal()
    }

    return ( 
        <>
            <Table
                rowKey={record => props.packedBoxes.indexOf(record)}
                size='small'
                pagination={false}
                className='table-striped-rows' 
                dataSource={props.packedBoxes}
                columns={[
                    {
                        title: 'Length (cm)',
                        dataIndex: ['box', 'length'],
                        key: 'length',
                    },
                    {
                        title: 'Width (cm)',
                        dataIndex: ['box', 'width'],
                        key: 'width',
                    },
                    {
                        title: 'Height (cm)',
                        dataIndex: ['box', 'height'],
                        key: 'height',
                    },
                    {
                        title: 'Number of Boxes',
                        dataIndex: 'numberOfBoxes',
                        key: 'numberOfBoxes',
                        align: 'right',
                    },
                    {
                        title: 'Parts per Box',
                        dataIndex: 'partsPerBox',
                        key: 'partsPerBox',
                        align: 'right',
                    },
                    {
                        title: 'Total Parts',
                        dataIndex: 'totalParts',
                        key: 'totalParts',
                        align: 'right',
                    },
                    {
                        title: 'Weight per Box (kg)',
                        dataIndex: 'weightPerBox',
                        key: 'weightPerBox',
                        align: 'right',
                    },
                    {
                        title: 'Total Weight (kg)',
                        dataIndex: 'totalWeight',
                        key: 'totalWeight',
                        align: 'right',
                    },
                    ...(!props.readonly ?
                    [{
                        key: 'actions',
                        align: 'right',
                        width: 50,
                        title: () => 
                                <TableRowButton buttontype='Add' onClick={onAdd} entity='Packed Box' />,
                        render: (text, record:any, index: number) => 
                            <div>
                                <Space>
                                    <TableRowButton buttontype='Edit' onClick={() => onEdit(index)} entity='Packed Box' />
                                    <TableRowButton buttontype='Delete' onClick={() => onDelete(index)} entity='Packed Box' hasconfirm />
                                </Space>
                            </div>
                    } as ColumnType<any>] : []),
                ]}
                summary={(pageData) => {
                    let totalNumberOfBoxes = 0
                    let totalTotalParts = 0
                    let totalTotalWeight = 0
            
                    pageData.forEach(({ numberOfBoxes, totalParts, totalWeight }) => {
                        totalNumberOfBoxes += numberOfBoxes
                        totalTotalParts += totalParts
                        totalTotalWeight += totalWeight ?? 0
                    });
            
                    return (
                        <Table.Summary.Row style={{ height: 20 }}>
                            <Table.Summary.Cell index={1} colSpan={3}>Total</Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align='right' className='nowrap'>{totalNumberOfBoxes}</Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={1}>&nbsp;</Table.Summary.Cell>
                            <Table.Summary.Cell index={4} align='right' className='nowrap'>
                                { totalTotalParts > props.qtyPlanned
                                    ?
                                    <div>
                                        <Tooltip title='The quantity Packed is higher than the quantity Planned for this Batch!' placement='leftTop'>
                                            <WarningOutlined style={{ color: 'orange', fontSize: 16, marginRight: 5, paddingBottom: 4, verticalAlign: 'bottom' }}></WarningOutlined>{totalTotalParts}
                                        </Tooltip>
                                    </div>
                                    :
                                    <>{totalTotalParts}</>
                                }
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5} align='left' colSpan={1}>({props.qtyPlanned - totalTotalParts} to pack)</Table.Summary.Cell>
                            <Table.Summary.Cell index={6} align='right' className='nowrap'>{totalTotalWeight}</Table.Summary.Cell>
                            <Table.Summary.Cell index={7}>&nbsp;</Table.Summary.Cell>
                        </Table.Summary.Row>
                    )
                  }}
            />

            <InnerModal
                title={<div className='color-shipping'>Packed Box Details</div>}
                open={state.showDetailsModal} 
                width={600}
                centered
                className='progress-modal'
                onClose={closeDetailsModal}>
                    <PackedBoxDetails 
                        packedBox={state.packedBox}
                        qtyPlanned={props.qtyPlanned}
                        qtyPacked={_.sumBy(props.packedBoxes, p => p.totalParts)}
                        onSave={onSave}
                        onClose={closeDetailsModal} />
            </InnerModal>
        </>
    )
}