import { Form, message, Input, DatePicker, Tooltip, Table, Row, Col, InputNumber } from 'antd';
import { ColumnType, Key } from 'antd/es/table/interface';
import { CustomSelect } from 'components/_common/CustomSelect';
import { FormatDate } from 'components/_common/Formatters';
import { IsChangedInnerContext } from 'components/_common/InnerModal';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { PageLoader } from 'components/_common/PageLoader';
import dayjs from 'dayjs';
import _, { debounce } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { PackedBoxes } from './_common/PackedBoxes';
import { WarningOutlined} from '@ant-design/icons';
import { ColorDisplay } from 'components/_common/ColorDisplay';
import { TableRowButton } from 'components/_common/CustomButtons';

interface Props {
    batchId?: number
    orderId: number
    readonly?: boolean
    onClose(): void
    onRefresh(): void
}

interface State {
    packedBoxes: Record<number, any>
    expandedKeys?: Key[]
    timeStamp: Date
}

export const ShippingBatchDetails = (props: Props) => {
    const { setIsChanged, submitRef } = useContext(IsChangedInnerContext)
    const [form] = Form.useForm()

    const [state, setState] = useState<State>({
        packedBoxes: {},
        expandedKeys: undefined,
        timeStamp: new Date(),
    })

    const batchFetcher = MultiFetcher('shippingBatch')
    const batchStatusFetcher = MultiFetcher('enumValues/ShippingBatchStatus')
    const orderLineDetailFetcher = MultiFetcher('orderLineDetail')

    useEffect(() => {
        batchStatusFetcher.get({})
        orderLineDetailFetcher
            .get({ actionName: 'getAllForLatestRevision', queryParams: `orderId=${props.orderId}&mapProductVariation=true`})

        if (props.batchId) {
            //-- Edit --
            batchFetcher.get({ id: props.batchId?.toString() }).then(batch => {
                const packedBoxes = {} as Record<number, any>
                _.forEach(batch.batchLines, batchLine => {
                    form.setFieldValue(`batchLineId_${batchLine.orderLineDetailId}`, batchLine.id)
                    form.setFieldValue(`quantityPlanned_${batchLine.orderLineDetailId}`, batchLine.quantityPlanned)
                    form.setFieldValue(`initial_quantityPlanned_${batchLine.orderLineDetailId}`, batchLine.quantityPlanned)
                    form.setFieldValue(`initial_quantityPacked_${batchLine.orderLineDetailId}`, _.sumBy(batchLine.packedBoxes, (p:any) => (p.numberOfBoxes * p.partsPerBox)))

                    packedBoxes[batchLine.orderLineDetailId] = batchLine.packedBoxes
                })
                
                setState(prevState => ({ ...prevState, packedBoxes }))

                //_.forEach(batch.batchLines, batchLine => form.validateFields([`quantityPlanned_${batchLine.orderLineDetailId}`], { dirty: false}))
                form.validateFields()
            })
        } else {
            //-- Create --
            batchFetcher.get({ actionName: 'getMaxBatchNumber', queryParams: `orderId=${props.orderId}`}).then(maxBatchNumber => {
                form.resetFields()
                form.setFieldValue('orderId', props.orderId)
                form.setFieldValue('batchNumber', maxBatchNumber.result + 1)
                form.setFieldValue('statusId', 1)
            })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Submit = async (data: any) => {
        const submitAsync = async (data: any):Promise<number> => await props.batchId ? batchFetcher.put(data) : batchFetcher.post(data)

        const batchLines = [] as any[]

        _.forEach(orderLineDetailFetcher.data, (orderLineDetail: any) => {
            const batchLineId = form.getFieldValue(`batchLineId_${orderLineDetail.id}`)
            const quantityPlanned = getQtyPlanned(orderLineDetail.id)

            if (quantityPlanned){
                batchLines.push({
                    id: batchLineId,
                    orderLineDetailId: orderLineDetail.id,
                    quantityPlanned,
                    packedBoxes: state.packedBoxes[orderLineDetail.id] ?? []
                })
            }
        })

        data.batchLines = batchLines
        //console.log(`data: [${JSON.stringify(data)}]`)

        await submitAsync(data).then((result) => {
            if (result > 0) {
                props.onClose()
                message.info(`Batch ${data.batchNumber} was ${props.batchId ? 'updated.': 'created.'}`)
                props.onRefresh()
            }
        })
    }

    const updatePackedBoxes = (orderLineDetailId: number, packedBoxes: any[]) => {
        const data = state.packedBoxes
        data[orderLineDetailId] = packedBoxes
        setState(prevState => ({ ...prevState, packedBoxes: data }))
        setIsChanged(true)
    }

    const onQtyChanged = debounce(() => {
        setState(prevState => ({ ...prevState, timeStamp: new Date() }))
    }, 500)

    const compareLineNumberWithPrevious = (lineNumber: number, index: number) => index > 0 ? lineNumber === orderLineDetailFetcher.data[index-1].orderLineNumber : false

    const getInitialQtyPlanned = (id: number) => form.getFieldValue(`initial_quantityPlanned_${id}`) ?? 0
    const getInitialQtyPacked = (id: number) => form.getFieldValue(`initial_quantityPacked_${id}`) ?? 0
    const getQtyPlanned = (id: number) => form.getFieldValue(`quantityPlanned_${id}`) ?? 0
    const getQtyPacked = (id: number) => _.sumBy(state.packedBoxes[id], (p:any) => (p.numberOfBoxes * p.partsPerBox))

    const calculatePlanned = (record: any) => record.totalShippingBatchLinesQuantityPlanned - getInitialQtyPlanned(record.id) + getQtyPlanned(record.id)
    const calculatePacked = (record: any) => record.totalShippingBatchLinesQuantityPacked - getInitialQtyPacked(record.id) + getQtyPacked(record.id)
    const calculateStillToPlan = (record: any) => record.quantity - calculatePlanned(record)
    const calculateStillToPack = (record: any) => record.quantity - calculatePacked(record)

    const onComplementAll = () => {
        _.forEach(orderLineDetailFetcher.data, orderLineDetail => {
            if (!form.getFieldValue(`quantityPlanned_${orderLineDetail.id}`)) {
                const stillToPlan = calculateStillToPlan(orderLineDetail)
                form.setFieldValue(`quantityPlanned_${orderLineDetail.id}`, stillToPlan)
                setIsChanged(true)
            }
        }) 
    }

    const checkIfAllPlannedProductsArePacked = () : boolean => {
        var result = true
        _.forEach(orderLineDetailFetcher.data, orderLineDetail => {
            const qtyPlanned = getQtyPlanned(orderLineDetail.id)
            const qtyPacked = getQtyPacked(orderLineDetail.id)

            if (qtyPlanned && qtyPlanned > qtyPacked) {
                result = false
                return false
            }
        })

        return result
    }

    return (
        <PageLoader loading={batchFetcher.isLoading}>
            <Form 
                initialValues={batchFetcher.data} 
                form={form} 
                onFinish={Submit} 
                style={{ padding: '10px'}}
                labelCol={{ span: 4, xl: 6}}
                wrapperCol={{ span: 20, xl: 18}}
                onValuesChange={() => setIsChanged(true)}>
                <Form.Item name='id' hidden={true}><Input/></Form.Item>
                <Form.Item name='orderId' hidden={true}><Input/></Form.Item>
                <Row>
                    <Col xl={12} span={24}>  
                        <Form.Item label='Batch No' name='batchNumber'>
                            <Input style={{maxWidth: 150}} readOnly disabled />
                        </Form.Item>
                        <Form.Item label='Status' name='statusId' rules={[
                            { 
                                message: 'Not all planned products are packed.',
                                validator: (_, value) => (value === 1 || checkIfAllPlannedProductsArePacked()) ? Promise.resolve() : Promise.reject()
                            }]}>
                            <CustomSelect data={batchStatusFetcher.data} loading={batchStatusFetcher.isLoading} required style={{ width: 200 }} placeholder='select a status' disabled={props.readonly} />
                        </Form.Item>
                    </Col>
                    <Col xl={12} span={24}>  
                        <Form.Item label="Shipping Date" name="shippingDate" 
                            getValueProps={(value) => ({value: value ? dayjs(value) : ''})} 
                            getValueFromEvent={(value) => FormatDate(value)}>
                            <DatePicker format='YYYY-MM-DD' disabled={props.readonly} />
                        </Form.Item>
                        <Form.Item label="Shipping Ref." name="shippingReference">
                            <Input style={{maxWidth: 300}} maxLength={25} disabled={props.readonly} />
                        </Form.Item>
                    </Col>
                </Row> 
                <button ref={submitRef} type="submit" className='hide' />
                { orderLineDetailFetcher.data && 
                <Table
                    loading={orderLineDetailFetcher.isLoading}
                    dataSource={orderLineDetailFetcher.data}
                    rowKey='id'
                    size='small'
                    pagination={false}
                    bordered
                    rowClassName={(record: any, index) => record.orderLineNumber % 2 === 0 ? 'table-striped-row' :  ''}
                    expandable={{  
                        //showExpandColumn: false,
                        //expandedRowKeys: orderLineDetailFetcher.data?.map(p => p.id),
                        expandRowByClick: true,                        
                        expandedRowRender: (record: any) => <PackedBoxes orderLineDetailId={record.id} packedBoxes={state.packedBoxes[record.id]} qtyPlanned={getQtyPlanned(record.id)}  timeStamp={state.timeStamp} onUpdate={updatePackedBoxes} readonly={props.readonly}/>,
                        expandedRowKeys: state.expandedKeys,
                        onExpand(expanded, record) {
                            const keys = [] as Key[]
                            if (expanded) keys.push(record.id)
                            setState(prevState => ({ ...prevState, expandedKeys: keys }))
                        } 
                    }}
                    columns={[
                        {
                            title: '#',
                            dataIndex: 'orderLineNumber',
                            key: 'lineNumber',
                            align: 'right',
                            width: 40,
                            className: 'nowrap',
                            render: (value, record, index) => compareLineNumberWithPrevious(value, index) ? '' : value
                        },
                        {
                            title: 'Product',
                            dataIndex: ['productVariation', 'productName'],
                            key: 'product',
                            ellipsis: { showTitle: false },
                            render: (value, record, index) => compareLineNumberWithPrevious(record.orderLineNumber, index) ? '' : <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                            width: '12%',
                        },
                        {
                            title: 'Part Number',
                            dataIndex: ['productVariation', 'partNumber'],
                            key: 'partNumber',
                            ellipsis: { showTitle: false },
                            render: (value, record, index) => compareLineNumberWithPrevious(record.orderLineNumber, index) ? '' : <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                            responsive: ['lg'],
                            width: '12%',
                        },
                        {
                            title: 'Drawing Number',
                            dataIndex: ['productVariation', 'drawingNumber'],
                            key: 'drawingNumber',
                            ellipsis: { showTitle: false },
                            render: (value, record, index) => compareLineNumberWithPrevious(record.orderLineNumber, index) ? '' : <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                            responsive: ['lg'],
                            width: '12%',
                        },
                        {
                            title: 'Properties',
                            dataIndex: ['productVariation', 'properties'],
                            key: 'properties',
                            ellipsis: { showTitle: false },
                            render: (value, record, index) => compareLineNumberWithPrevious(record.orderLineNumber, index) ? '' : <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                            responsive: ['lg'],
                            width: '12%',
                        },
                        {
                            title: 'Color',
                            dataIndex: 'productColor', 
                            key: 'color',
                            className: 'nowrap',
                            width: 50,
                            render: (value) => <ColorDisplay productColor={value} />
                        },
                        { 
                            title: 'Order',
                            children: [
                                {
                                    title: 'Ordered',
                                    dataIndex: 'quantity',
                                    key: 'qtyOrdered',
                                    align: 'right',
                                    width: 80,
                                },
                                {
                                    title: <p className='color-blackish'>Produced</p>,
                                    dataIndex: 'quantityProduced',
                                    key: 'qtyProduced',
                                    align: 'right',
                                    width: 80,
                                    className: 'color-blackish'
                                },
                            ]
                        },
                        ...(!props.readonly ? [
                        {
                            title: 'Planning',
                            children: [
                                {
                                    title: 'Planned',
                                    key: 'qtyPlanned',
                                    align: 'right',
                                    width: 80,
                                    render: (value: any, record: any, index) => {
                                        const qtyPlanned = calculatePlanned(record)
                                        
                                        return qtyPlanned > record.quantity
                                        ?
                                        <div>
                                            <Tooltip title='The total quantity Planned is higher than the total quantity Ordered!' placement='leftTop'>
                                                <WarningOutlined style={{ color: 'orange', fontSize: 16, marginRight: 5, paddingBottom: 4, verticalAlign: 'bottom' }}></WarningOutlined>{qtyPlanned}
                                            </Tooltip>
                                        </div>
                                        :
                                        <>{qtyPlanned}</>
                                    }
                                } as ColumnType<any>,
                                {
                                    title: 'To plan',
                                    key: 'qtyToBatch',
                                    align: 'right',
                                    width: 80,
                                    render: (value: any, record: any) => calculateStillToPlan(record)
                                } as ColumnType<any>,
                            ]
                        }] : []),
                        ...(!props.readonly ? [
                        {
                            title: 'Packing',
                            children: [
                                {
                                    title: 'Packed',
                                    key: 'qtyPacked',
                                    align: 'right',
                                    width: 80,
                                    render: (value: any, record: any, index) => {
                                        const qtyPlanned = calculatePlanned(record)
                                        const qtyPacked = calculatePacked(record)
                                        
                                        return qtyPacked > qtyPlanned
                                        ?
                                        <div>
                                            <Tooltip title='The total quantity Packed is higher than the total quantity Planned!' placement='leftTop'>
                                                <WarningOutlined style={{ color: 'orange', fontSize: 16, marginRight: 5, paddingBottom: 4, verticalAlign: 'bottom' }}></WarningOutlined>{qtyPacked}
                                            </Tooltip>
                                        </div>
                                        :
                                        <>{qtyPacked}</>
                                    }
                                } as ColumnType<any>,
                                {
                                    title: 'To pack',
                                    key: 'qtyToPack',
                                    align: 'right',
                                    width: 80,
                                    render: (value: any, record: any) => calculateStillToPack(record)
                                } as ColumnType<any>
                            ]
                        }] : []),
                        { 
                            title: 'This Batch',
                            children: [
                                {
                                    title: <>
                                        <TableRowButton buttontype='Forward' onClick={() => onComplementAll()} title='Complement all Planned quantities' />
                                        &nbsp;
                                        Planned
                                    </>,
                                    key: 'quantityPlanned',
                                    align: 'right',
                                    width: 115,
                                    onCell: (record, index) => {
                                        return {
                                            onClick: (e) => e.stopPropagation()
                                        }
                                    },
                                    render: (value: any, record: any, index) => {
                                        return props.readonly 
                                        ? 
                                        form.getFieldValue(`quantityPlanned_${record.id}`)
                                        :
                                        <Row onClick={e => e.stopPropagation()}>
                                            <Form.Item name={`batchLineId_${record.id}`} hidden={true}><Input/></Form.Item>
                                            <Col span={6} style={{ float: 'right'}}>
                                                { getQtyPlanned(record.id) > record.quantity - record.totalShippingBatchLinesQuantityPlanned + getInitialQtyPlanned(record.id) &&
                                                    <div style={{ height: 20, margin: 0, padding: 0, marginTop: -7}}>
                                                        <Tooltip title='The total quantity planned is higher than the total quantity ordered!' placement='leftTop'>
                                                            <WarningOutlined style={{ color: 'orange', fontSize: 16, marginRight: 5, paddingBottom: 0, verticalAlign: 'bottom' }}></WarningOutlined>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </Col>
                                            <Col span={18} style={{ float: 'right'}}>
                                                <Form.Item name={`quantityPlanned_${record.id}`} style={{ height: 24, margin: 0, padding: 0, marginTop: -8, float: 'right'}} rules={[
                                                        { max: record.quantity - record.totalShippingBatchLinesQuantityPlanned + getInitialQtyPlanned(record.id),
                                                        message: '',
                                                        type: 'number',
                                                        warningOnly: true }]} > 
                                                    <InputNumber controls={false} min={0} max={99999999} precision={0} size='small' style={{ height: 24, maxWidth: 75 }} onChange={onQtyChanged}
                                                        title={getQtyPacked(record.id) > getQtyPlanned(record.id) ? 'The total quantity planned is higher than the total quantity ordered!' : ''} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                },
                                {
                                    title: 'Packed',
                                    key: 'quantityPacked',
                                    align: 'right',
                                    width: 90,
                                    render: (value: any, record: any, index) => {
                                        const qtyPacked = getQtyPacked(record.id)
                                        const qtyPlanned = getQtyPlanned(record.id)
                                        return qtyPacked > qtyPlanned
                                        ?
                                        <div>
                                            <Tooltip title='The quantity Packed is higher than then quantity Planned for this Batch!' placement='leftTop'>
                                                <WarningOutlined style={{ color: 'orange', fontSize: 16, marginRight: 5, paddingBottom: 4, verticalAlign: 'bottom' }}></WarningOutlined>{getQtyPacked(record.id)}
                                            </Tooltip>
                                        </div>
                                        : 
                                            qtyPacked < qtyPlanned 
                                            ?
                                            <div className='color-blue'>{getQtyPacked(record.id)}</div>
                                            :
                                            <div>{getQtyPacked(record.id)}</div>
                                    }
                                }
                            ]
                        }
                    ]}
                />
            }
            </Form> 
        </PageLoader> 
    )
}