import React from "react";

interface Props {
  children: any
}

export const PageTitle = (props: Props) => {
  return (
    <div style={{ width: '100%', textAlign: 'center', margin: '20px', color: '#5C5C5C' }} className={'h4'}>{props.children}</div>
  )
}

export const PageSubTitle = (props: Props) => {
  return (
    <div style={{ width: '100%', textAlign: 'left', margin: 5, paddingBottom: 10, paddingTop: 5, color: '#555555' }} className={'h6'}>{props.children}</div>
  )
}

interface TitleProps {
  children: any,
  className: string
}

export const Title = (props: TitleProps) => {
  return (
    <div style={{ width: '100%', textAlign: 'left', margin: 5, color: '#5C5C5C' }} className={props.className}>{props.children}</div>
  )
}