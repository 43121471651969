import React, { useEffect, useState } from 'react';
import { Space, Table, Tooltip, } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { FormatCurrency } from 'components/_common/Formatters';
import { OuterModal } from 'components/_common/OuterModal';
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter';
import { PaymentsViewer } from '../Orders/_common/PaymentsViewer';
import { Key } from 'antd/es/table/interface';
import { PaymentType } from 'components/_common/enums/PaymentType';

interface State {   
    orderId?: number
    orderNr?: string
    expandedKeys?: Key[]
}

export const PaymentOverview = () => {
    
    const [state, setState] = useState<State>({
        orderId: undefined,
        orderNr: undefined,
        expandedKeys: undefined
    })
    
    const fetcher = MultiFetcher('paymentOverview');  

    useEffect(() => {
        window.scrollTo(0, 0);
        fetcher.get({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const viewPayments = (orderId: number, orderNr: string) => setState(prevState => ({ ...prevState, orderId, orderNr }))

    return (
        <>
            <Table
                loading={fetcher.isLoading}
                rowKey={'id'}
                size='small'
                pagination={{ defaultPageSize: 12, size: 'default' }}
                showSorterTooltip={{ placement: 'bottom' }}
                columns={[
                    {
                        title: 'OrderNr.',
                        dataIndex: 'fullCustomerOrderNumber',
                        key: 'fullCustomerOrderNumber',
                        sorter: (a:any,b:any) => a.fullCustomerOrderNumber?.localeCompare(b.fullCustomerOrderNumber),
                        ...ColumnTextFilter('fullOrderNumber'),
                        className: 'nowrap',
                        //width: 150
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                        ellipsis: { showTitle: false },
                        render: (value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                        //width: '20vw'
                    },
                    {
                        title: 'Supplier',
                        dataIndex: 'supplierName',
                        key: 'supplier',
                        sorter: (a:any,b:any) => a.supplierName?.localeCompare(b.supplierName),
                        ...ColumnTextFilter('supplierName'),
                        className: 'nowrap',
                        //width: 120
                    },
                    {
                        title: 'Payment Type',
                        dataIndex: 'paymentTypeName',
                        key: 'paymentTypeName',
                        sorter: (a:any,b:any) => a.paymentTypeName?.localeCompare(b.paymentTypeName),
                        ...ColumnTextFilter('paymentTypeName'),
                        className: 'nowrap',
                        //width: 150
                    },
                    {
                        title: 'Total Price',
                        dataIndex: 'totalPrice',
                        key: 'totalPrice',
                        render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>,
                        align: 'right',
                        className: 'nowrap',
                        //width: 75
                    },
                    {
                        title: 'Term 1',
                        dataIndex: 'term1Price',
                        key: 'term1Price',
                        align: 'right',
                        className: 'nowrap',
                        render: (value: string, record: any) => {
                            const isPaid = record.paymentPaidProgress >= 40 
                            const isVerified = record.paymentVerifiedProgress >= 40
                            return <div className={`cell-fit-content-align-right ${isVerified ? 'color-payment' : (isPaid && 'color-yellow')}`}>{FormatCurrency(value)}</div>
                        }
                        //width: 75
                    },
                    {
                        title: 'Term 2',
                        dataIndex: 'term2Price',
                        key: 'term2Price',
                        align: 'right',
                        className: 'nowrap',
                        render: (value: string, record: any) => {
                            const isPaid = record.paymentPaidProgress >= 70 
                            const isVerified = record.paymentVerifiedProgress >= 70
                            return (record.paymentTypeId === PaymentType.Split)
                                ? <div className={`cell-fit-content-align-right ${isVerified ? 'color-payment' : (isPaid && 'color-yellow')}`}>{FormatCurrency(value)}</div>
                                : <>-</>
                        }
                        //width: 75
                    },
                    {
                        title: 'Term 3',
                        dataIndex: 'term3Price',
                        key: 'term3Price',
                        align: 'right',
                        className: 'nowrap',
                        render: (value: string, record: any) => {
                            const isPaid = record.paymentPaidProgress >= 100
                            const isVerified = record.paymentVerifiedProgress >= 100
                            return (record.paymentTypeId === PaymentType.Split)
                                ? <div className={`cell-fit-content-align-right ${isVerified ? 'color-payment' : (isPaid && 'color-yellow')}`}>{FormatCurrency(value)}</div>
                                : <>-</>
                        }
                       //width: 75
                    },
                    
                    {
                        key: 'actions',
                        align: 'right',
                        render: (record: any) =>
                            <div>
                                { record.fullCustomerOrderNumber &&
                                    <TableRowButton buttontype='View' onClick={(e) => viewPayments(record.id, record.fullCustomerOrderNumber)} entity='Payments'/>
                                }
                            </div>
                    }
                ]}
                dataSource={fetcher.data} 
                style={{ paddingBottom: '10px'}} 
                className='table-striped-rows' 
                expandable={{
                    expandRowByClick: true,
                    childrenColumnName: 'details',
                    expandedRowKeys: state.expandedKeys,
                    onExpand(expanded, record) {
                        const keys = [] as Key[]
                        if (expanded) keys.push(record.id)
                        setState(prevState => ({ ...prevState, expandedKeys: keys }))
                    },
                }}/> 

                <OuterModal
                    title={`View Payments for Order ${state.orderNr}`}
                    open={state.orderId !== undefined} 
                    width='80vw'
                    style={{ maxWidth: '1024px'}}
                    onClose={() => setState(prevState => ({ ...prevState, orderId: undefined, fullCustomerOrderNr: undefined }))} 
                    saveButtonProps={{ buttontype: 'Save', style: { display:'none'}}}>
                        { state.orderId && 
                            <div style={{width: '100%'}}>
                                <PaymentsViewer orderId={state.orderId} />
                            </div>
                        }
                </OuterModal>
        </> 
    )
}