import React, { useEffect } from 'react';
import { Col, Form, Input, Row, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLoader } from 'components/_common/PageLoader';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { PageTitle } from 'components/_common/PageTitle';
import { CustomButton } from 'components/_common/CustomButtons';

export const ContactRoleDetails = () => {
    
    const { id } = useParams()
    const navigate = useNavigate()
    const [ form ] = Form.useForm()

    const fetcher = MultiFetcher('contactrole')

    useEffect(() => {
        window.scrollTo(0, 0)
        if (id) fetcher.get({ id })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Submit = async (data: any) => {
        const submitAsync = async (data: any):Promise<number> => {
            return id ? await fetcher.put(data) : fetcher.post(data);
        }

        await submitAsync(data).then((result) => {
            if (result > 0) {
                navigate('/contactrole/list')
                message.info(`ContactRole was ${id ? 'updated' : 'created'}.`)
            }
        })
    }

    return (
        <>
            <PageTitle>ContactRole Details</PageTitle>

            <PageLoader loading={fetcher.isLoading}>
                <Form 
                    layout='horizontal'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={fetcher.data}
                    style={{ padding: '10px'}}
                    form={form}
                    onFinish={Submit}>
                    <Form.Item name='id' hidden={true}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required.' }]}>
                        <Input style={{maxWidth: 400}} maxLength={50}></Input>
                    </Form.Item>
                </Form>
            </PageLoader>
                
            <br/>

            <Row>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Cancel' onClick={() => navigate(-1)} />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Save' onClick={() => form.submit()} />
                </Col>
            </Row>

        </>
    )
}