/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Tooltip, Table, InputNumber, message } from 'antd';
import { ColorDisplay } from 'components/_common/ColorDisplay';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { IsChangedOuterContext } from 'components/_common/OuterModal';
import _ from 'lodash';
import React, { useContext, useEffect } from 'react';

interface Props {
    orderId: number
    orderNr?: string
    readonly?: boolean
    onClose(): void
    onRefresh(): void
}

export const ProductionProgressDetails = (props: Props) => {
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    const orderLineDetailFetcher = MultiFetcher('orderLineDetail')

    useEffect(() => {
        orderLineDetailFetcher
            .get({ actionName: 'getAllForLatestRevision', queryParams: `orderId=${props.orderId}&mapProductVariation=true`}).then(orderLineDetails => {
                _.forEach(orderLineDetails, orderLineDetail => {
                    form.setFieldValue(`qtyProduced_${orderLineDetail.id}`, orderLineDetail.quantityProduced)
                })
            })
    }, [])

    const Submit = async (data: any) => {
        data = orderLineDetailFetcher.data

        _.forEach(data, orderLineDetail => {
            orderLineDetail.quantityProduced = form.getFieldValue(`qtyProduced_${orderLineDetail.id}`)
        })

        //console.log(`data: [${JSON.stringify(data)}]`)

        await orderLineDetailFetcher.put(data, props.orderId).then((result) => {
            if (result > 0) {
                props.onClose()
                message.info(`Production progress for Order ${props.orderNr} was updated.`)
                props.onRefresh()
            }
        })
    }

    const compareLineNumberWithPrevious = (lineNumber: number, index: number) => index > 0 ? lineNumber === orderLineDetailFetcher.data[index-1].orderLineNumber : false

    return (
        <Form 
            form={form} 
            onFinish={Submit} 
            labelCol={{ span: 0}}
            wrapperCol={{ span: 24}}
            onValuesChange={() => setIsChanged(true)}>
            <Form.Item name='id' hidden={true}><Input/></Form.Item>
            <button ref={submitRef} type="submit" className='hide' />

            <Table
                loading={orderLineDetailFetcher.isLoading}
                dataSource={orderLineDetailFetcher.data}
                rowKey='id'
                size='small'
                pagination={false}
                bordered
                rowClassName={(record: any, index) => record.orderLineNumber % 2 === 0 ? 'table-striped-row' :  ''}
                columns={[
                    {
                        title: '#',
                        dataIndex: 'orderLineNumber',
                        key: 'lineNumber',
                        align: 'right',
                        width: 40,
                        className: 'nowrap',
                        render: (value, record, index) => compareLineNumberWithPrevious(value, index) ? '' : value
                    },
                    {
                        title: 'Product',
                        dataIndex: ['productVariation', 'productName'],
                        key: 'product',
                        ellipsis: { showTitle: false },
                        render: (value, record:any, index) => compareLineNumberWithPrevious(record.orderLineNumber, index) ? '' : <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                    },
                    {
                        title: 'Part Number',
                        dataIndex: ['productVariation', 'partNumber'],
                        key: 'partNumber',
                        ellipsis: { showTitle: false },
                        render: (value, record:any, index) => compareLineNumberWithPrevious(record.orderLineNumber, index) ? '' : <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                    },
                    {
                        title: 'Drawing Number',
                        dataIndex: ['productVariation', 'drawingNumber'],
                        key: 'drawingNumber',
                        ellipsis: { showTitle: false },
                        render: (value, record:any, index) => compareLineNumberWithPrevious(record.orderLineNumber, index) ? '' : <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                        responsive: ['lg'],
                    },
                    {
                        title: 'Properties',
                        dataIndex: ['productVariation', 'properties'],
                        key: 'properties',
                        ellipsis: { showTitle: false },
                        render: (value, record:any, index) => compareLineNumberWithPrevious(record.orderLineNumber, index) ? '' : <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                        responsive: ['lg'],
                    },
                    {
                        title: 'Color',
                        dataIndex: 'productColor',
                        key: 'color',
                        className: 'nowrap',
                        width: 50,
                        render: (value) => <ColorDisplay productColor={value} />
                    },
                    { 
                        title: 'Quantity',
                        children: [
                            {
                                title: 'Ordered',
                                dataIndex: 'quantity',
                                key: 'quantity',
                                align: 'right',
                                width: 90
                            },
                            {
                                title: 'Produced',
                                align: 'right',
                                width: 90,
                                className: 'v-align-top',
                                render: (record: any) => {
                                    return props.readonly 
                                    ? 
                                    form.getFieldValue(`qtyProduced_${record.id}`)
                                    :
                                    <Form.Item name={`qtyProduced_${record.id}`} style={{ height: 24, margin: 0, padding: 0, marginTop: -6}} 
                                        rules={[{  max: record.quantity, type: 'number', message: '', warningOnly: false, }]} >
                                        <InputNumber controls={false} min={0} max={99999999} precision={0} size='small' style={{ height: 24, maxWidth: 75 }} onChange={(value) => {}} />
                                    </Form.Item>
                                }
                            },
                        ]
                    }
                ]}
                />
        </Form> 
    )
}