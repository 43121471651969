import React, { useEffect, useState } from 'react';
import { Space, Table, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { PageTitle } from 'components/_common/PageTitle';
import { numberCompare } from 'components/_common/Generic';
import { OuterModal } from 'components/_common/OuterModal';
import { BoxDetails } from './BoxDetails';

interface State {
    showDetailsModal: boolean
    selectedBoxId?: number
}

export const BoxList = () => {
    const [state, setState] = useState<State>({
        showDetailsModal: false,
        selectedBoxId: undefined
    })
    
    const fetcher = MultiFetcher('box');  

    useEffect(() => {
        window.scrollTo(0, 0);
        fetcher.get({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onAdd = () => setState(prevState => ({ ...prevState, showDetailsModal: true }))
    const onEdit = (id: number) => setState(prevState => ({ ...prevState, showDetailsModal: true, selectedBoxId: id }))
    const onDelete = async (id: number) => {
        fetcher.del(id).then(async (success) => {
            if (success === true) {
                await fetcher.get({})
                message.info(`Box was deleted.`)
            }
        })
    }

    const onCloseDetailsModal = () =>  setState(prevState => ({ ...prevState, showDetailsModal: false, selectedBoxId: undefined }))
    const onRefresh = () => fetcher.get({})

    return (
        <>
            <PageTitle>Boxes</PageTitle>

            <Table
                loading={fetcher.isLoading}
                rowKey={'id'}
                size='small'
                pagination={{ defaultPageSize: 12, size: 'default' }}
                columns={[
                    {
                        title: 'Length',
                        dataIndex: 'length',
                        key: 'length',
                        sorter: (a:any,b:any) => numberCompare(a.length, b.length),
                    },
                    {
                        title: 'Width',
                        dataIndex: 'width',
                        key: 'width',
                        sorter: (a:any,b:any) => numberCompare(a.width, b.width),
                    },
                    {
                        title: 'Height',
                        dataIndex: 'height',
                        key: 'height',
                        sorter: (a:any,b:any) => numberCompare(a.height, b.height),
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        title: () => <TableRowButton buttontype='Add' onClick={onAdd} entity='Box' />,
                        render: (record: any) =>
                            <div>
                                <Space>
                                    <TableRowButton buttontype='Edit' recordid={record.id} onClick={onEdit} entity='Box' />
                                    <TableRowButton buttontype='Delete' recordid={record.id} onClick={onDelete} entity='Box' hasconfirm />
                                </Space>
                            </div>
                    }

                ]}
                dataSource={fetcher.data} 
                style={{ paddingBottom: '10px'}} 
                className='table-striped-rows' /> 

                <OuterModal
                    title={`${state.selectedBoxId ? 'Edit' : 'Add'} Box`} 
                    open={state.showDetailsModal} 
                    //width='400'
                    style={{ maxWidth: '400px'}}
                    onClose={onCloseDetailsModal}>
                        <BoxDetails boxId={state.selectedBoxId} onClose={onCloseDetailsModal} onRefreshAndSelect={onRefresh} />
                </OuterModal>
        </> 
    )
}