import React from 'react'
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'

interface Props {
    isLoading: boolean
    result?: boolean
}

export const OperationResult = (props: Props) => {

    return props.isLoading ? 
        <LoadingOutlined spin /> : 
            props.result === true ?
                <CheckOutlined style={{ color: 'green' }} /> :
                props.result === false ? 
                    <CloseOutlined style={{ color: 'red' }} /> :
                        <></>
}
