import React from 'react';
import { PageTitle } from 'components/_common/PageTitle';

export const Unauthorized = () => {

  return (
    <div style={{margin: 100, padding: 50,  textAlign: 'center', border: '1px solid silver'}}>

      <PageTitle>Unauthorized</PageTitle>
      <br/>
      You are not authorized to view this page. 
    </div>
  );
}
