import React, { useContext, useEffect, useState } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { CustomSelect } from 'components/_common/CustomSelect';
import TextArea from 'antd/es/input/TextArea';
import { UploadChangeParam } from 'antd/es/upload';
import * as _ from 'lodash';
import { FileUpload } from 'components/_common/FileUpload';
import { VariationImageType } from 'components/_common/enums/VariationImageType';
import { IsChangedOuterContext } from 'components/_common/OuterModal';

interface Props {
    productVariation: any
    sizes: any
    threadTypes: any
    temps: any
    onClose(): void
    onSave(value: any): void
}

interface State {
    technicalDrawing: any
    stepFile: any
    boxLabelImage: any
}

export const ProductVariationDetails = (props: Props) => {
    const [state, setState] = useState<State>({
        technicalDrawing: undefined,
        stepFile: undefined,
        boxLabelImage: undefined
    })

    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    useEffect(() => {
        window.scrollTo(0, 0)

        if (props.productVariation && props.productVariation.images){
            const technicalDrawing = _.find(props.productVariation.images, image => image.imageTypeId === VariationImageType.TechnicalDrawing)
            const stepFile = _.find(props.productVariation.images, image => image.imageTypeId === VariationImageType.StepFile)
            const boxLabelImage = _.find(props.productVariation.images, image => image.imageTypeId === VariationImageType.BoxLabelImage)
        
            setState(prevState => ({ ...prevState, technicalDrawing, stepFile, boxLabelImage }))
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFileNameWithoutExtension = (fileName: string): string => {
        const index = fileName.lastIndexOf('.')
        return fileName.substring(0, index)
    }

    const handleFileChange = (file: UploadChangeParam, imageType: VariationImageType) => {
        let image = _.find(props.productVariation.images, image => image.imageTypeId === imageType)

        if (!image) image = { imageTypeId: imageType, imageTypeName: VariationImageType[imageType] }

        image.fileName = file.file.name
        image.file = file.file

        //console.log(`image: ${JSON.stringify(image)}`)

        switch (imageType) {
            case VariationImageType.TechnicalDrawing: 
                setState(prevState => ({ ...prevState, technicalDrawing: image }))
                form.setFieldValue('drawingNumber', getFileNameWithoutExtension(image.fileName))
                break;
            case VariationImageType.StepFile: 
                setState(prevState => ({ ...prevState, stepFile: image }))
                break;
            case VariationImageType.BoxLabelImage: 
                setState(prevState => ({ ...prevState, boxLabelImage: image }))
                break;
        }
        setIsChanged(true)
    }

    const deleteImage = (imageType: VariationImageType) => {
        switch (imageType) {
            case VariationImageType.TechnicalDrawing: 
                setState(prevState => ({ ...prevState, technicalDrawing: undefined }))
                form.setFieldValue('drawingNumber', undefined)
                break;
            case VariationImageType.StepFile: 
                setState(prevState => ({ ...prevState, stepFile: undefined }))
                break;
            case VariationImageType.BoxLabelImage: 
                setState(prevState => ({ ...prevState, boxLabelImage: undefined }))
                break;
        }
        setIsChanged(true)
    }

    const onSubmit = (values: any) => {
        let images = props.productVariation.images ?? []

        //console.log(`PRE Submit images: [${JSON.stringify(images)}]`)
        
        const getImage = (type: VariationImageType) => _.find(images, image => image.imageTypeId === type)

        const technicalDrawing = getImage(VariationImageType.TechnicalDrawing)
        const stepFile = getImage(VariationImageType.StepFile)
        const boxLabelImage = getImage(VariationImageType.BoxLabelImage)

        if (technicalDrawing !== state.technicalDrawing){
            
            const index = images.indexOf(technicalDrawing, 0)

            if (!state.technicalDrawing)
                images.splice(index, 1)
            else {
                if (index > -1)
                    images[index] = state.technicalDrawing
                else
                    images.push(state.technicalDrawing)
            }
        }

        if (stepFile !== state.stepFile){
            const index = images.indexOf(stepFile, 0)

            if (!state.stepFile)
                images.splice(index, 1)
            else {
                if (index > -1)
                    images[index] = state.stepFile
                else
                    images.push(state.stepFile)
            }
        }

        if (boxLabelImage !== state.boxLabelImage){
            const index = images.indexOf(boxLabelImage, 0)

            if (!state.boxLabelImage)
                images.splice(index, 1)
            else {
                if (index > -1)
                    images[index] = state.boxLabelImage
                else
                    images.push(state.boxLabelImage)
            }
        }

        values.images = images

        //console.log(`POST Submit images: [${JSON.stringify(images)}]`)

        props.onSave(values)
    }

    return (
        <Form 
            layout='horizontal'
            labelCol={{ span: 6, xl: 7}}
            wrapperCol={{ span: 18, xl: 15}}
            initialValues={props.productVariation}
            style={{ padding: '10px'}}
            form={form}
            onFinish={onSubmit}
            onValuesChange={() => setIsChanged(true)} >
                <Form.Item name='id' hidden={true}><Input/></Form.Item>
                <Form.Item name='guptaPriceWithSetup' hidden={true}><Input/></Form.Item>
                <Form.Item name='guptaPriceWithoutSetup' hidden={true}><Input/></Form.Item>
                <Row>
                    <Col xl={12} span={24}>
                        <Form.Item label="Size 1 (mm)" name="size1Id">
                            <CustomSelect data={props.sizes} loading={!props.sizes} displayfieldname='sizeInMm' />
                        </Form.Item>
                    </Col>
                    <Col xl={12} span={24}>
                        <Form.Item label="Size 2 (mm)" name="size2Id">
                            <CustomSelect data={props.sizes} loading={!props.sizes} displayfieldname='sizeInMm' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} span={24}>
                        <Form.Item label="Threadtype 1" name="threadType1Id">
                            <CustomSelect data={props.threadTypes} loading={!props.threadTypes} />
                        </Form.Item>
                    </Col>
                    
                    <Col xl={12} span={24}>
                        <Form.Item label="Threadtype 2" name="threadType2Id" >
                            <CustomSelect data={props.threadTypes} loading={!props.threadTypes} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} span={24}>
                        <Form.Item label="Temperature" name="temperatureId" >
                            <CustomSelect data={props.temps} loading={!props.temps} />
                        </Form.Item>
                    </Col>
                    <Col xl={12} span={24}>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} span={24}>
                        <Form.Item label="Part Number" name="partNumber">
                            <Input maxLength={50}></Input>
                        </Form.Item>
                        <Form.Item label="Drawing Number" name="drawingNumber">
                            <Input maxLength={50}></Input>
                        </Form.Item>
                    </Col>
                    <Col xl={12} span={24}>
                        <Form.Item label="Extra Info" name="extraInfo">
                            <TextArea maxLength={250} rows={4}></TextArea>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} span={24}>
                        <Form.Item label="Technical Drawing" style={{ whiteSpace: 'normal'}} >
                            <FileUpload 
                                value={state.technicalDrawing?.fileName} 
                                onUpload={(value) => handleFileChange(value, VariationImageType.TechnicalDrawing)} 
                                onDelete={() => deleteImage(VariationImageType.TechnicalDrawing)} 
                                accept='.pdf' />
                        </Form.Item>
                    </Col>
                    <Col xl={12} span={24}>
                        <Form.Item label="Step File">
                            <FileUpload 
                                value={state.stepFile?.fileName} 
                                onUpload={(value) => handleFileChange(value, VariationImageType.StepFile)} 
                                onDelete={() => deleteImage(VariationImageType.StepFile)} 
                                accept='.step,.stp'/>
                        </Form.Item>
                    </Col>
                    <Col xl={12} span={24}>
                        <Form.Item label="Boxlabel Image">
                            <FileUpload 
                                value={state.boxLabelImage?.fileName} 
                                onUpload={(value) => handleFileChange(value, VariationImageType.BoxLabelImage)} 
                                onDelete={() => deleteImage(VariationImageType.BoxLabelImage)} 
                                accept='.jpg,.jpeg,.png,.bmp,.gif'/>
                        </Form.Item>
                    </Col>
                </Row>
            <button ref={submitRef} type="submit" className='hide' />
        </Form>
    )
}