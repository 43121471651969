import React from 'react'
import { InputNumber, InputNumberProps } from 'antd'

export const WeightInput = (props: InputNumberProps) => {
    return (
        <InputNumber 
            {...props}
            addonAfter='kg'
            style={{ width: '115px' }}
            min={0}
            max={9999999.999}
            precision={3}
            controls={false}
            size='middle'
        />
    )
}