import React, { useContext, useEffect } from 'react';
import { Form, Tabs } from 'antd';
import { OrderHeaderViewer } from 'components/_common/OrderHeaderViewer';
import { OrderLinesList } from './OrderLinesList';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import * as _ from 'lodash';
import { CustomSelect } from 'components/_common/CustomSelect';
import { IsChangedOuterContext } from 'components/_common/OuterModal';
import { PaymentsViewer } from './_common/PaymentsViewer';
import { PageLoader } from 'components/_common/PageLoader';
import { InvoicesViewer } from './_common/InvoicesViewer';

interface Props {
    orderId: number
    approveQuote?: boolean
    onClose(): void
    onApprove(id: number, orderNumber: string, paymentTypeId: number, deliveryTypeId: number): void
}

export const OrderView = (props: Props) => {
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    const orderFetcher = MultiFetcher('order');  
    const paymentTypeFetcher = MultiFetcher('enumValues/PaymentType');  
    const deliveryTypeFetcher = MultiFetcher('enumValues/DeliveryType');  

    useEffect(() => {
        if (props.approveQuote){
            paymentTypeFetcher.get({})
            deliveryTypeFetcher.get({})
        }
        
        orderFetcher.get({ id: props.orderId.toString() })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <OrderHeaderViewer order={orderFetcher.data} loading={orderFetcher.isLoading} />

            <br/>

            <PageLoader loading={orderFetcher.isLoading}>
                { orderFetcher.data && 
                <>
                    <Tabs 
                        type='card' 
                        size='small' 
                        tabBarStyle={{ marginBottom: 2 }} 
                        items={[
                            { 
                                label: 'Order Lines',
                                key: '1',
                                children: (!props.approveQuote && orderFetcher.data.revisions.length > 1) ?
                                            <Tabs 
                                                type="card" 
                                                size='small' 
                                                tabBarStyle={{ marginBottom: 2 }} 
                                                items={_.map(orderFetcher.data.revisions, (revision) => {
                                                    return { 
                                                        key: revision.revisionNumber,
                                                        label: `Rev. ${revision.revisionNumber}`,
                                                        children: <OrderLinesList orderRevisionId={revision.id} quoteStatusId={orderFetcher.data.quoteStatusId} readOnly/>
                                                    }
                                                })} />
                                            :
                                            <OrderLinesList orderRevisionId={orderFetcher.data.lastRevision.id} quoteStatusId={orderFetcher.data.quoteStatusId} readOnly/>
                            },
                            ...(props.approveQuote ? [] : [
                            {
                                label: 'Invoices',
                                key: '2',
                                children: <InvoicesViewer orderId={props.orderId} />,
                            },
                            {
                                label: 'Payments',
                                key: '3',
                                children: <PaymentsViewer orderId={props.orderId} />,
                            }])
                        ]}/>
                    
                    { props.approveQuote && (
                        <div >
                            <Form 
                                form={form}
                                initialValues={orderFetcher.data}
                                onFinish={(values: any) => props.onApprove(props.orderId, orderFetcher.data.fullCustomerOrderNumber, values.paymentTypeId, values.deliveryTypeId)} 
                                style={{ float: 'right', marginRight: 0}}
                                onValuesChange={() => setIsChanged(true)} >
                                    <Form.Item label="Payment Type" name='paymentTypeId' rules={[{ required: true, message: 'Payment Type is required.' }]}
                                        style={{ display: 'flex', justifyContent: 'right', marginBottom: 10 }}>
                                        <CustomSelect data={paymentTypeFetcher.data} style={{ width: 175 }} required onChange={()=> setIsChanged(true)}/>
                                    </Form.Item>
                                    <Form.Item label="Delivery Type" name='deliveryTypeId' rules={[{ required: true, message: 'Delivery Type is required.' }]}
                                        style={{ display: 'flex', justifyContent: 'right', marginBottom: 10 }}>
                                        <CustomSelect data={deliveryTypeFetcher.data} style={{ width: 175 }} required onChange={()=> setIsChanged(true)}/>
                                    </Form.Item>
                                    <button ref={submitRef} type="submit" className='hide' />
                            </Form>
                        </div>
                    )}
                </>
                }
            </PageLoader>
        </>
    )
}