import React, { useContext, useEffect, useState } from 'react';
import { Form, message, Checkbox } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { PricedQuoteLineList } from './PricedQuoteLineList';
import { OrderHeaderViewer } from 'components/_common/OrderHeaderViewer';
import { UserRole, UserState } from 'components/_common/UserState';
import { UserContext } from 'components/AppLayout';
import { Unauthorized } from '../Security/Unauthorized';
import * as _ from 'lodash';
import { PageLoader } from 'components/_common/PageLoader';
import { IsChangedOuterContext } from 'components/_common/OuterModal';

interface State {
    finalize: boolean
}

interface Props {
    id: number|undefined
    onClose(): void
    onRefresh(): void
}

export const PricedQuoteDetails = (props: Props) => {
    const { isAuthorizedForRole } = useContext<UserState>(UserContext)
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()
    
    const [state, setState] = useState<State>({
        finalize: false,
    });

    const quoteFetcher = MultiFetcher('pricedquote');   
       
    useEffect(() => {
        window.scrollTo(0, 0)
        if (props.id) quoteFetcher.get({ id: props.id.toString() })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async () => {
        let data = quoteFetcher.data

        data.lastRevision.orderLines = updateOrderLinesWithFormData(data.lastRevision.orderLines)

        const submitAsync = async (data: any):Promise<number> => {
            return state.finalize ? await quoteFetcher.post(data) : quoteFetcher.put(data);
        }

        await submitAsync(data).then((result) => {
            if (result > 0) { 
                props.onClose()
                props.onRefresh()
                message.info(`Quote margins have been ${state.finalize ? 'saved. The Quote was finalized.' : 'saved.'}`)
            }
        })
    }

    const updateOrderLinesWithFormData = (orderLines: any[]) : any[] => {
        _.forEach(orderLines, orderLine => {
            if (!orderLine.quotePrice) orderLine.quotePrice = {}

            orderLine.quotePrice.unitPriceMargin = form.getFieldValue(`unitPriceMargin_${orderLine.id}`)
            orderLine.quotePrice.setupCostMargin = form.getFieldValue(`setupCostMargin_${orderLine.id}`)
            orderLine.quotePrice.moldCostMargin = form.getFieldValue(`moldCostMargin_${orderLine.id}`)
        })

        return orderLines
    }

    return (
        <>
            { !isAuthorizedForRole(UserRole.SuperAdmin)
                ? <Unauthorized /> 
                : <>
                    <OrderHeaderViewer order={quoteFetcher.data} loading={quoteFetcher.isLoading} />

                    <PageLoader loading={quoteFetcher.isLoading}>
                        { quoteFetcher.data && 
                            <>
                                <Form form={form} onFinish={onSubmit} onValuesChange={() => setIsChanged(true)} >
                                    <PricedQuoteLineList
                                        orderLines={quoteFetcher.data?.lastRevision.orderLines}
                                        isLoading={quoteFetcher.isLoading}
                                        finalize={state.finalize}
                                        form={form}
                                        supplierId={quoteFetcher.data?.supplierId ?? 0} 
                                        supplierName={quoteFetcher.data?.supplierName ?? ''} />
                                    <button ref={submitRef} type="submit" className='hide' />
                                </Form>
                                        
                                <Checkbox 
                                    title='Finalize Quote' 
                                    checked={state.finalize} 
                                    onChange={(e) => {
                                        setState(prevState => ({ ...prevState, finalize: e.target.checked }))
                                        setIsChanged(true)
                                    }}
                                    style={{ display: 'flex', justifyContent: 'right'}}
                                    >Finalize Quote</Checkbox>
                            </>
                        }
                    </PageLoader>
                </>
            }
        </>
    )
}