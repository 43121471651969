import React, { useCallback, useEffect } from 'react';
import { Space, Table, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { PageTitle } from 'components/_common/PageTitle';
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter';

export const ContactList = () => {
    const contactFetcher = MultiFetcher('contact');   

    const fetchContactsAsync = useCallback(async () => {
        await contactFetcher.get({ })
        //console.log(`contacts: ${JSON.stringify(contacts)}`);
    }, [contactFetcher])

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchContactsAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const deleteItem = async (id: number) => {
        contactFetcher.del(id).then(async (success) => {
            if (success === true) {
                await fetchContactsAsync()
                message.info(`Contact was deleted.`)
            }
        });
    }

    return (
        <>
            <PageTitle>Contacts</PageTitle>

            <Table
                loading={contactFetcher.isLoading}
                rowKey={'id'}
                size='small'
                pagination={{ defaultPageSize: 12, size: 'default' }}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: (a:any,b:any) => a.name.localeCompare(b.name),
                        ...ColumnTextFilter('name')
                    },
                    {
                        title: 'Customer',
                        dataIndex: 'customerName',
                        key: 'customerName',
                        sorter: (a:any,b:any) => a.customerName.localeCompare(b.customerName),
                        ...ColumnTextFilter('customerName')
                    },
                    {
                        title: 'Role',
                        dataIndex: 'contactRoleName',
                        key: 'contactrolename',
                        sorter: (a:any,b:any) => a.contactRoleName?.localeCompare(b.contactRoleName),
                        ...ColumnTextFilter('contactRoleName')
                    },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                        sorter: (a:any,b:any) => a.email?.localeCompare(b.email),
                        ...ColumnTextFilter('email')
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        title: () => <TableRowButton buttontype='Add' url='/contact/edit/' entity='Contact' />,
                        render: (record: any) =>
                            <div>
                                <Space>
                                    <TableRowButton buttontype='Edit' recordid={record.id} url={`/contact/edit`} entity='Contact' />
                                    <TableRowButton buttontype='Delete' recordid={record.id} onClick={deleteItem} entity='Contact' hasconfirm />
                                </Space>
                            </div>
                    }
                ]}
                dataSource={contactFetcher.data} 
                style={{ paddingBottom: '10px'}} 
                className='table-striped-rows' />
        </>
    )
}