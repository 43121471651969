/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Col, Form, FormInstance, Row, Switch, Table, Tooltip } from 'antd'
import { TableRowButton } from 'components/_common/CustomButtons'
import * as _ from 'lodash';
import { FormatCurrency, FormatPercentage, FormatVariationProperties } from 'components/_common/Formatters'
import { ColumnType, Key } from 'antd/es/table/interface';
import { PageSubTitle } from 'components/_common/PageTitle';
import { MarginHistory } from './MarginHistory';
import { IsChangedOuterContext } from 'components/_common/OuterModal';
import { EnterMarginForm } from './_common/EnterMarginForm';
import { MoneyDisplay } from 'components/_common/MoneyDisplay';
import { PercentageInput } from 'components/_common/PercentageInput';
import { InnerModal } from 'components/_common/InnerModal';
import { calculateCustomerPrice } from 'components/_common/Calculators';
import { ColorDisplay } from 'components/_common/ColorDisplay';

interface Props {
    isLoading: boolean
    finalize: boolean
    orderLines: any
    form: FormInstance<any>
    supplierId: number
    supplierName: string
}

interface State {
    expandedKeys?: Key[]
    showLast: boolean
    showGupta: boolean
    showEllipsis: boolean
    showHistoryModal: boolean
    showMarginAllModal: boolean
    selectedVariationId?: number
    selectedPartNumber?: string
    showMarginModal: boolean
    marginModalTitle?: string
    marginModalOnSave(value: number): void
}

export const PricedQuoteLineList = (props:Props) => {
    const [state, setState] = useState<State>({
        expandedKeys: undefined,
        showLast: false,
        showGupta: false,
        showEllipsis: true,
        showHistoryModal: false,
        showMarginAllModal: false,
        selectedVariationId: undefined,
        selectedPartNumber: undefined,
        showMarginModal: false,
        marginModalTitle: undefined,
        marginModalOnSave: () => {}
    })
    const { setIsChanged } = useContext(IsChangedOuterContext)

    useEffect(() => {
        props.orderLines?.forEach(orderLine => {
            props.form.setFieldValue(`unitPriceMargin_${orderLine.id}`, orderLine.quotePrice?.unitPriceMargin)
            props.form.setFieldValue(`setupCostMargin_${orderLine.id}`, orderLine.quotePrice?.setupCostMargin)
            props.form.setFieldValue(`moldCostMargin_${orderLine.id}`, orderLine.quotePrice?.moldCostMargin)

            calcRowTotals(orderLine.id)
        })
        calcGrandTotalParts()
        calcGrandTotalSetup()
        calcGrandTotalMold()
        calcGrandTotal()
    }, [props.form, props.orderLines]) //TODO: check finalize checkbox

    const calcRowTotals = (id: number) =>
    {
        const orderLine = _.find(props.orderLines, p => p.id === id)

        const unitPriceMargin = props.form.getFieldValue(`unitPriceMargin_${orderLine.id}`)
        const setupCostMargin = props.form.getFieldValue(`setupCostMargin_${orderLine.id}`)
        const moldCostMargin = props.form.getFieldValue(`moldCostMargin_${orderLine.id}`)

        const customerUnitPrice = calculateCustomerPrice(orderLine.quotePrice?.supplierUnitPrice, unitPriceMargin ?? 0) 
        props.form.setFieldValue(`customerUnitPrice_${orderLine.id}`, customerUnitPrice)

        const customerPartsTotal = orderLine.totalQuantity * customerUnitPrice
        props.form.setFieldValue(`customerPartsTotal_${orderLine.id}`, customerPartsTotal)

        const customerSetupCost = calculateCustomerPrice(orderLine.quotePrice?.supplierSetupCost, setupCostMargin ?? 0)
        props.form.setFieldValue(`customerSetupCost_${orderLine.id}`, customerSetupCost)

        const customerMoldCost = calculateCustomerPrice(orderLine.quotePrice?.supplierMoldCost, moldCostMargin ?? 0)
        props.form.setFieldValue(`customerMoldCost_${orderLine.id}`, customerMoldCost)

        const customerTotal = customerPartsTotal + customerSetupCost + customerMoldCost
        props.form.setFieldValue(`customerTotal_${orderLine.id}`, customerTotal)
    }

    const calcGrandTotalParts = () => {
        let grandTotal = 0
        props.orderLines?.forEach(orderLine => {
            grandTotal += props.form.getFieldValue(`customerPartsTotal_${orderLine.id}`)
        })
        props.form.setFieldValue(`grandTotalParts`, grandTotal)
    }

    const calcGrandTotalSetup = () => {
        let grandTotal = 0
        props.orderLines?.forEach(orderLine => {
            grandTotal += props.form.getFieldValue(`customerSetupCost_${orderLine.id}`)
        })
        props.form.setFieldValue(`grandTotalSetup`, grandTotal)
    }

    const calcGrandTotalMold = () => {
        let grandTotal = 0
        props.orderLines?.forEach(orderLine => {
            grandTotal += props.form.getFieldValue(`customerMoldCost_${orderLine.id}`)
        })
        props.form.setFieldValue(`grandTotalMold`, grandTotal)
    }

    const calcGrandTotal = () => {
        let grandTotal = 0
        props.orderLines?.forEach(orderLine => {
            grandTotal += props.form.getFieldValue(`customerTotal_${orderLine.id}`)
        })
        props.form.setFieldValue(`grandTotal`, grandTotal)
    }

    const calcGrandTotalAll = () => {
        let grandTotalParts = 0
        let grandTotalSetup = 0
        let grandTotalMold = 0
        let grandTotal = 0
        props.orderLines?.forEach(orderLine => {
            grandTotalParts += props.form.getFieldValue(`customerPartsTotal_${orderLine.id}`)
            grandTotalSetup += props.form.getFieldValue(`customerSetupCost_${orderLine.id}`)
            grandTotalMold += props.form.getFieldValue(`customerMoldCost_${orderLine.id}`)
            grandTotal += props.form.getFieldValue(`customerTotal_${orderLine.id}`)
        })
        props.form.setFieldValue(`grandTotalParts`, grandTotalParts)
        props.form.setFieldValue(`grandTotalSetup`, grandTotalSetup)
        props.form.setFieldValue(`grandTotalMold`, grandTotalMold)
        props.form.setFieldValue(`grandTotal`, grandTotal)
    }

    //-- Copy Buttons --
    const onCopySinglePrevious = async(id: number) => {
        const orderLine = _.find(props.orderLines, p => p.id === id)
        const lastMargin = orderLine.productVariation.lastMargin

        props.form.setFieldValue(`unitPriceMargin_${id}`, lastMargin?.unitPriceMargin)
        props.form.setFieldValue(`setupCostMargin_${id}`, lastMargin?.setupCostMargin)
        props.form.setFieldValue(`moldCostMargin_${id}`, lastMargin?.moldCostMargin)

        calcRowTotals(id)
        calcGrandTotalAll()
        setIsChanged(true)
    }

    const onCopyAllPrevious = async() => {
        _.forEach(props.orderLines, orderLine => {
            const lastMargin = orderLine.productVariation.lastMargin

            props.form.setFieldValue(`unitPriceMargin_${orderLine.id}`, lastMargin?.unitPriceMargin)
            props.form.setFieldValue(`setupCostMargin_${orderLine.id}`, lastMargin?.setupCostMargin)
            props.form.setFieldValue(`moldCostMargin_${orderLine.id}`, lastMargin?.moldCostMargin)
            calcRowTotals(orderLine.id)
        })
        calcGrandTotalAll()
        setIsChanged(true)
    }

    const showVariationHistory = (variationId: number, partNumber: string) => {
        setState(prevState => ({ ...prevState, showHistoryModal: true, selectedVariationId: variationId, selectedPartNumber: partNumber }))
    }

    //-- Enter Value Modal --
    const showMarginModal = (show: boolean) => setState(prevState => ({ ...prevState, showMarginModal: show }))
    const onEnterMarginAll = () => setState(prevState => ({ ...prevState, showMarginModal: true, marginModalTitle: 'Enter Margin for ALL prices/costs', marginModalOnSave: onMarginAllSave }))
    const onEnterMarginUnitPrice = () => setState(prevState => ({ ...prevState, showMarginModal: true, marginModalTitle: 'Enter Margin for ALL Unit Prices', marginModalOnSave: onMarginUnitPriceSave }))
    const onEnterMarginSetupCost = () => setState(prevState => ({ ...prevState, showMarginModal: true, marginModalTitle: 'Enter Margin for ALL Setup Costs', marginModalOnSave: onMarginSetupCostSave }))
    const onEnterMarginMoldCost = () => setState(prevState => ({ ...prevState, showMarginModal: true, marginModalTitle: 'Enter Margin for ALL Mold Costs', marginModalOnSave: onMarginMoldCostSave }))

    const onMarginAllSave = (value: number) => {
        _.forEach(props.orderLines, orderLine => {
            props.form.setFieldValue(`unitPriceMargin_${orderLine.id}`, value)
            props.form.setFieldValue(`setupCostMargin_${orderLine.id}`, value)
            props.form.setFieldValue(`moldCostMargin_${orderLine.id}`, value)
            calcRowTotals(orderLine.id)
        })

        calcGrandTotalAll()
        showMarginModal(false)
        setIsChanged(true)
    }

    const onMarginUnitPriceSave = (value: number) => {
        _.forEach(props.orderLines, orderLine => {
            props.form.setFieldValue(`unitPriceMargin_${orderLine.id}`, value)
            calcRowTotals(orderLine.id)
        })
        calcGrandTotalAll()
        showMarginModal(false)
        setIsChanged(true)
    }

    const onMarginSetupCostSave = (value: number) => {
        _.forEach(props.orderLines, orderLine => {
            props.form.setFieldValue(`setupCostMargin_${orderLine.id}`, value)
            calcRowTotals(orderLine.id)
        })
        calcGrandTotalAll()
        showMarginModal(false)
        setIsChanged(true)
    }

    const onMarginMoldCostSave = (value: number) => {
        _.forEach(props.orderLines, orderLine => {
            props.form.setFieldValue(`moldCostMargin_${orderLine.id}`, value)
            calcRowTotals(orderLine.id)
        })
        calcGrandTotalAll()
        showMarginModal(false)
        setIsChanged(true)
    }

    return (
        <div style={{minWidth: 1400}}>
            <Row>
                <Col xxl={15} xl={12} span={9}>
                    <PageSubTitle>Order Lines</PageSubTitle>
                </Col>
                <Col xxl={3} xl={4} span={5}>
                    <div style={{ position: 'absolute', bottom: 0, right: 5 }}>
                        <Form.Item label="Show Gupta prices" name='showGupta' style={{ margin: 5}}>
                            <Switch size='small' checked={state.showGupta} title='Show Gupta Prices' onChange={() => setState(prevState => ({ ...prevState, showGupta: !state.showGupta }))} />
                        </Form.Item>
                    </div>
                </Col>
                <Col xxl={3} xl={4} span={5}>
                    <div style={{ position: 'absolute', bottom: 0, right: 5 }}>
                        <Form.Item label="Show Last Margin" name='showLast' style={{ margin: 5}}>
                            <Switch size='small' checked={state.showLast} title='Show Last Margin' onChange={() => setState(prevState => ({ ...prevState, showLast: !state.showLast }))} />
                        </Form.Item>
                    </div>
                </Col>
                <Col xxl={3} xl={4} span={5}>
                    <div style={{ position: 'absolute', bottom: 0, right: 5 }}>
                        <Form.Item label="Compressed Text" name='showEllipsis' style={{ margin: 5}}>
                            <Switch size='small' checked={state.showEllipsis} title='Show Elliipsis' onChange={() => setState(prevState => ({ ...prevState, showEllipsis: !state.showEllipsis }))} />
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            <Table
                loading={props.isLoading}
                rowKey={record => record.lineNumber ?? `color_${record.id}`}
                size='small'
                pagination={false}     
                style={{ width: 'auto', paddingBottom: '10px'}}
                className='table-striped-rows' 
                bordered
                expandable={{
                    expandRowByClick: false,
                    childrenColumnName: 'orderLineDetails',
                    expandedRowKeys: state.expandedKeys,
                    onExpand(expanded, record) {
                        const keys = [] as Key[]
                        if (expanded) keys.push(record.lineNumber)
                        setState(prevState => ({ ...prevState, expandedKeys: keys }))
                    },
                }}
                columns={[
                    {
                        title: '#',
                        dataIndex: 'lineNumber',
                        key: 'linenumber',
                        align: 'right',
                        width: 50,
                        className: 'nowrap'
                    },
                    {
                        title: 'Product',
                        dataIndex: ['productVariation', 'productName'],
                        key: 'product',
                        ellipsis: state.showEllipsis ? { showTitle: false} : false,
                        render: (value) => state.showEllipsis ? <Tooltip placement="topLeft" title={value}>{value}</Tooltip> : value,
                        //width: '10%',
                    },
                    {
                        title: 'Part Number',
                        dataIndex: ['productVariation', 'partNumber'],
                        key: 'partNumber',
                        ellipsis: state.showEllipsis ? { showTitle: false} : false,
                        render: (value) => state.showEllipsis ? <Tooltip placement="topLeft" title={value}>{value}</Tooltip> : value,
                        //width: '10%'
                    },
                    {
                        title: 'Properties',
                        key: 'properties',
                        ellipsis: state.showEllipsis ? { showTitle: false} : false,
                        render: (value) => state.showEllipsis ? <Tooltip placement="topLeft" title={FormatVariationProperties(value)}>{FormatVariationProperties(value)}</Tooltip> : FormatVariationProperties(value),
                        //width: '10%',
                    },
                    ...((state.expandedKeys && state.expandedKeys.length > 0) ?
                    [{
                        title: 'Color',
                        dataIndex: 'productColor',
                        key: 'color',
                        className: 'nowrap',
                        width: 50,
                        render: (value) => <ColorDisplay productColor={value} />
                    }] : []),
                    {
                        title: <Tooltip title='Quantity'>Qty</Tooltip>,
                        key: 'quantity',
                        align: 'right',
                        //width: 55,
                        render: (record: any) => <div  className='nowrap'>{'totalQuantity' in record ? record.totalQuantity : record.quantity}</div>
                    },
                    {
                        title: <Tooltip title='Minimum Order Quantity'>MOQ</Tooltip>,
                        key: 'minimumOrderQuantity',
                        dataIndex: ['quotePrice', 'minimumOrderQuantity'],
                        align: 'right',
                        //width: 55,
                    },
                    ...(state.showGupta ?
                    [{
                        title: 'Gupta Price',
                        className: 'bg-light-red',
                        children: [
                            {
                                title: <Tooltip title='Without Setup Cost'>w/o SC</Tooltip>,
                                dataIndex: ['productVariation', 'guptaPriceWithoutSetup'],
                                key: 'guptaPriceWithoutSetup',
                                align: 'right',
                                className: 'bg-light-red nowrap',
                                //width: 75,
                                render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>
                            },
                            {
                                title: <Tooltip title='With Setup Cost'>w/ SC</Tooltip>,
                                dataIndex: ['productVariation', 'guptaPriceWithSetup'],
                                key: 'guptaPriceWithSetup',
                                align: 'right',
                                className: 'bg-light-red nowrap',
                                //width: 75,
                                render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>
                            }
                        ]
                    } as ColumnType<any>] : []),
                    { 
                        title: 'Unit Price',
                        children: [
                            {
                                title: 'Supplier',
                                dataIndex: ['quotePrice', 'supplierUnitPrice'],
                                key: 'supplierUnitPrice',
                                align: 'right',
                                className: 'nowrap',
                                //width: 75,
                                render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>
                            },
                            {
                                title: <>
                                    Margin
                                    &nbsp;
                                    <TableRowButton buttontype='Percentage' onClick={onEnterMarginUnitPrice} title='Enter Margin for all Unit Prices' />
                                </>,
                                dataIndex: ['quotePrice', 'unitPriceMargin'],
                                key: 'unitPriceMargin',
                                width: 125,
                                align: 'center',
                                render: (value: any, record: any, index) => {
                                    if (record.productVariationId) {
                                        return (
                                            <div onClick={e => e.stopPropagation()} >
                                                <Form.Item name={`unitPriceMargin_${record.id}`} style={{ margin: 0 }} rules={ props.finalize ? [{ required: true, message: 'Margin is required.' }] : []}>
                                                    <PercentageInput
                                                        onChange={(value) => {
                                                            calcRowTotals(record.id)
                                                            calcGrandTotalParts()
                                                            calcGrandTotal()
                                                        }} />
                                                </Form.Item>
                                                { state.showLast && 
                                                    <div className='last'>
                                                        Last: {FormatPercentage(record.productVariation.lastMargin?.unitPriceMargin)}
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                }
                            },
                            {
                                title: 'Customer',
                                key: 'customerUnitPrice',
                                align: 'right',
                                className: 'nowrap',
                                //width: 75,
                                render: (record: any) => <div className='nowrap'>
                                    {record.quotePrice && 
                                        <Form.Item name={`customerUnitPrice_${record.id}`} style={{ margin: 0 }} >
                                            <MoneyDisplay />
                                        </Form.Item>
                                    }
                                </div>
                            }
                        ]
                    },
                    {
                        title: 'Parts Total',
                        key: 'customerPrice',
                        align: 'right',
                        width: 110,
                        render: (record: any) => <div className='nowrap'>
                                    {record.quotePrice && 
                                        <Form.Item name={`customerPartsTotal_${record.id}`} style={{ margin: 0 }} >
                                            <MoneyDisplay />
                                        </Form.Item>
                                    }
                                </div>
                    },
                    { 
                        title: 'Setup Cost',
                        children: [
                            {
                                title: 'Supplier',
                                dataIndex: ['quotePrice', 'supplierSetupCost'],
                                key: 'supplierSetupCost',
                                align: 'right',
                                className: 'nowrap',
                                //width: 75,
                                render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>
                            },
                            {
                                title: <>
                                    Margin
                                    &nbsp;
                                    <TableRowButton buttontype='Percentage' onClick={onEnterMarginSetupCost} title='Enter Margin for all Setup Costs' />
                                </>,
                                dataIndex: ['quotePrice', 'setupCostMargin'],
                                key: 'setupCostMargin',
                                width: 125,
                                align: 'center',
                                render: (value: any, record: any, index) => {
                                    if (record.productVariationId) {
                                        return (
                                        <div onClick={e => e.stopPropagation()}>
                                            <Form.Item name={`setupCostMargin_${record.id}`} style={{ margin: 0 }} >
                                                <PercentageInput
                                                    onChange={(value) => {
                                                        calcRowTotals(record.id)
                                                        calcGrandTotalSetup()
                                                        calcGrandTotal()
                                                    }} />
                                            </Form.Item>
                                            { state.showLast && 
                                                <div className='last'>
                                                    Last: {FormatPercentage(record.productVariation.lastMargin?.setupCostMargin)}
                                                </div>
                                            }
                                        </div>
                                        )
                                    }
                                }
                            },
                            {
                                title: 'Customer',
                                key: 'customerSetupCost',
                                align: 'right',
                                className: 'nowrap',
                                width: 75,
                                render: (record: any) => <div className='nowrap'>
                                    {record.quotePrice && 
                                        <Form.Item name={`customerSetupCost_${record.id}`} style={{ margin: 0 }} >
                                            <MoneyDisplay />
                                        </Form.Item>
                                    }
                                </div>
                            }
                        ]
                    },
                    {
                        title: 'Mold Cost',
                        children: [
                            {
                                title: 'Supplier',
                                dataIndex: ['quotePrice', 'supplierMoldCost'],
                                key: 'supplierMoldCost',
                                align: 'right',
                                className: 'nowrap',
                                //width: 75,
                                render: (value: string) => <div className='nowrap'>{FormatCurrency(value)}</div>
                            },
                            {
                                title: <>
                                    Margin
                                    &nbsp;
                                    <TableRowButton buttontype='Percentage' onClick={onEnterMarginMoldCost} title='Enter Margin for all Mold Costs' />
                                </>,
                                dataIndex: ['quotePrice', 'moldCostMargin'],
                                key: 'moldCostMargin',
                                width: 125,
                                align: 'center',
                                render: (value: any, record: any, index) => {
                                    if (record.productVariationId) {
                                        return (
                                            <div onClick={e => e.stopPropagation()}>
                                                <Form.Item name={`moldCostMargin_${record.id}`} style={{ margin: 0 }}>
                                                    <PercentageInput
                                                        onChange={(value) => {
                                                            calcRowTotals(record.id)
                                                            calcGrandTotalMold()
                                                            calcGrandTotal()
                                                        }} />
                                                </Form.Item>
                                                { state.showLast && 
                                                    <div className='last'>
                                                        Last: {FormatPercentage(record.productVariation.lastMargin?.moldCostMargin)}
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                }
                            },
                            {
                                title: 'Customer',
                                key: 'customerMoldCost',
                                align: 'right',
                                className: 'nowrap',
                                //width: 75,
                                render: (record: any) => <div className='nowrap'>
                                    {record.quotePrice && 
                                        <Form.Item name={`customerMoldCost_${record.id}`} style={{ margin: 0 }} >
                                            <MoneyDisplay />
                                        </Form.Item>
                                    }
                                </div>
                            }
                        ]
                    },
                    {
                        title: 'Total Customer Price',
                        key: 'totalCustomerPrice',
                        align: 'right',
                        width: 110,
                        render: (record: any) => <div className='nowrap'>
                                    {record.quotePrice && 
                                        <Form.Item name={`customerTotal_${record.id}`} style={{ margin: 0 }} >
                                            <MoneyDisplay />
                                        </Form.Item>
                                    }
                                </div>
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        //width: 75,
                        title: () => <Row>
                                        <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                                            <TableRowButton buttontype='Copy' onClick={onCopyAllPrevious} title='Copy ALL previous margins for ALL orderlines' />
                                        </Col>
                                        <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                                            <TableRowButton buttontype='Percentage' onClick={() => onEnterMarginAll()} title='Enter margin for all prices/costs' />
                                        </Col>
                                    </Row>,
                        render: (text, record, index: number) =>
                            ( record.productVariationId &&
                                <Row>
                                    <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                                        <TableRowButton buttontype='Copy' onClick={() => onCopySinglePrevious(record.id)} title='Copy previous margin' />
                                    </Col>
                                    <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                                        <TableRowButton buttontype='History' onClick={() => showVariationHistory(record.productVariation.id, record.productVariation.partNumber)} title='View History' />
                                    </Col>
                                </Row>
                            ),
                    }
                ]}
                rowClassName={(record: any) => (!record.orderId && record.quantity === 0 && 'hide') || ''}
                dataSource={props.orderLines}
                summary={(pageData) => (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}>&nbsp;</Table.Summary.Cell>
                          <Table.Summary.Cell index={1} colSpan={state.expandedKeys && state.expandedKeys.length > 0 ? (state.showGupta ? 11 : 9) : (state.showGupta ? 10 : 8)}>TOTAL</Table.Summary.Cell>
                          <Table.Summary.Cell index={2} align='right' className='nowrap'>
                            <div className='nowrap'>
                                <Form.Item name={`grandTotalParts`} style={{ margin: 0 }} >
                                <MoneyDisplay />
                                </Form.Item>
                            </div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3} colSpan={2}></Table.Summary.Cell>
                          <Table.Summary.Cell index={4} align='right' className='nowrap'>
                            <div className='nowrap'>
                                <Form.Item name={`grandTotalSetup`} style={{ margin: 0 }} >
                                <MoneyDisplay />
                                </Form.Item>
                            </div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5} colSpan={2}></Table.Summary.Cell>
                          <Table.Summary.Cell index={6} align='right' className='nowrap'>
                            <div className='nowrap'>
                                <Form.Item name={`grandTotalMold`} style={{ margin: 0 }} >
                                <MoneyDisplay />
                                </Form.Item>
                            </div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7} align='right' className='nowrap'>
                          <div className='nowrap'>
                                <Form.Item name={`grandTotal`} style={{ margin: 0 }} >
                                <MoneyDisplay />
                                </Form.Item>
                            </div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={8}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    )}
            />

            <InnerModal
                title="Margin History" 
                open={state.showHistoryModal} 
                width={800} 
                onClose={() => setState(prevState => ({ ...prevState, showHistoryModal: false }))} >
                <MarginHistory
                    supplierId={props.supplierId} 
                    variationId={state.selectedVariationId ?? 0} 
                    supplierName={props.supplierName}
                    partNumber={state.selectedPartNumber ?? ''} 
                    onClose={() => setState(prevState => ({ ...prevState, showHistoryModal: false }))} />
            </InnerModal>

            <InnerModal
                title={state.marginModalTitle}
                open={state.showMarginModal} 
                width={400} 
                onClose={() => showMarginModal(false)} 
                saveButtonProps={{ buttontype: 'Ok', children: 'Set'}}>
                    <EnterMarginForm
                        onClose={() => showMarginModal(false)}
                        onSave={state.marginModalOnSave} />
            </InnerModal>
        </div>       
    )
}