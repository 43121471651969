import React, { useEffect } from 'react'
import { Col, Descriptions,  Row, Table } from 'antd'
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { CustomButton } from 'components/_common/CustomButtons';
import { FormatDateTime, FormatPercentage } from 'components/_common/Formatters';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { PageLoader } from 'components/_common/PageLoader';
import { dateCompare } from 'components/_common/Generic';

interface Props {
    variationId: number
    supplierId: number
    partNumber: string
    supplierName: string
    onClose?(): void
}

export const MarginHistory = (props: Props) => {  
    const variationMarginFetcher = MultiFetcher('variationmargin')
    
    useEffect(() => {
        variationMarginFetcher.get({ queryParams: `variationId=${props.variationId}&supplierId=${props.supplierId}` })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs:1 }} >
                <DescriptionsItem label='Part Number'>{props.partNumber}</DescriptionsItem>
                <DescriptionsItem label='Supplier'>{props.supplierName}</DescriptionsItem>
            </Descriptions>

            <PageLoader loading={variationMarginFetcher.isLoading}>
                <Table 
                    loading={variationMarginFetcher.isLoading}
                    dataSource={variationMarginFetcher.data} 
                    style={{ paddingBottom: '10px'}}
                    className='table-striped-rows'
                    rowKey={'id'}
                    size='small'
                    columns={[
                        {
                            title: 'Timestamp',
                            dataIndex: 'timeStamp',
                            key: 'timeStamp',
                            render: (value) => FormatDateTime(value),
                            sorter: (a: any, b: any) => dateCompare(a.timeStamp, b.timeStamp),
                        },
                        {
                            title: 'Unit Price Margin',
                            dataIndex: 'unitPriceMargin',
                            key: 'unitpricemargin',
                            render: (value) => FormatPercentage(value),
                            sorter: (a:any,b:any) => Number.parseInt(a.unitPriceMargin) > Number.parseInt(b.unitPriceMargin) ? 1 : 0,
                        },
                        {
                            title: 'Setup Cost Margin',
                            dataIndex: 'setupCostMargin',
                            key: 'setupCostMargin',
                            render: (value) => FormatPercentage(value),
                            sorter: (a:any,b:any) => Number.parseInt(a.setupCost) > Number.parseInt(b.setupCost) ? 1 : 0,
                        },
                        {
                            title: 'Mold Cost Margin',
                            dataIndex: 'moldCostMargin',
                            key: 'moldCostMargin',
                            render: (value) => FormatPercentage(value),
                            sorter: (a:any,b:any) => Number.parseInt(a.moldCost) > Number.parseInt(b.moldCost) ? 1 : 0,
                        },
                    ]}
                />
            </PageLoader>

            <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center'}}>
                    <CustomButton buttontype='Close' onClick={props.onClose} />
                </Col>
            </Row>
        </>
    )
}