/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Descriptions, Table } from 'antd'
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { FormatCurrency, FormatDateTime } from 'components/_common/Formatters';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { PageLoader } from 'components/_common/PageLoader';
import { dateCompare } from 'components/_common/Generic';

interface Props {
    variationId: number
    supplierId: number
    partNumber: string
    supplierName: string
    onClose?(): void
}

export const PriceHistory = (props: Props) => {     
    const variationPriceFetcher = MultiFetcher('variationprice')
    
    useEffect(() => {
        variationPriceFetcher.get({ queryParams: `variationId=${props.variationId}&supplierId=${props.supplierId}` })
    }, [])

    return (
        <>
            <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs:1 }} >
                <DescriptionsItem label='Part Number'>{props.partNumber}</DescriptionsItem>
                <DescriptionsItem label='Supplier'>{props.supplierName}</DescriptionsItem>
            </Descriptions>

            <PageLoader loading={variationPriceFetcher.isLoading}>
                <Table 
                    loading={variationPriceFetcher.isLoading}
                    dataSource={variationPriceFetcher.data} 
                    style={{ paddingBottom: '10px'}}
                    className='table-striped-rows'
                    rowKey={'id'}
                    size='small'
                    columns={[
                        {
                            title: 'Timestamp',
                            dataIndex: 'timeStamp',
                            key: 'timeStamp',
                            render: (value) => FormatDateTime(value),
                            sorter: (a: any, b: any) => dateCompare(a.timeStamp, b.timeStamp),
                        },
                        {
                            title: 'Price',
                            dataIndex: 'supplierUnitPrice',
                            key: 'price',
                            render: (value) => FormatCurrency(value),
                            sorter: (a:any,b:any) => Number.parseFloat(a.price) > Number.parseFloat(b.price) ? 1 : 0,
                        },
                        {
                            title: 'Setup Cost',
                            dataIndex: 'supplierSetupCost',
                            key: 'setupCost',
                            render: (value) => FormatCurrency(value),
                            sorter: (a:any,b:any) => Number.parseFloat(a.setupCost) > Number.parseFloat(b.setupCost) ? 1 : 0,
                        },
                        {
                            title: 'Mold Cost',
                            dataIndex: 'supplierMoldCost',
                            key: 'moldCost',
                            render: (value) => FormatCurrency(value),
                            sorter: (a:any,b:any) => Number.parseFloat(a.moldCost) > Number.parseFloat(b.moldCost) ? 1 : 0,
                        },
                    ]}
                />
            </PageLoader>
        </>
    )
}