import dayjs from "dayjs";

export const delay = (ms: number) => {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

export const numberCompare = (a: number, b: number): number => {
    if (a > b) return 1
    if (a === b) return 0
    return -1
}

export const dateCompare = (a: string, b: string) => {
    if (dayjs(a).isBefore(dayjs(b))){
        return -1
    } else if (dayjs(a).isAfter(dayjs(b))) {
        return 1
    } 

    return 0
}