import React, { useEffect } from 'react';
import { Space, Table, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { PageTitle } from 'components/_common/PageTitle';
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter';
import { numberCompare } from 'components/_common/Generic';

export const ProductSizeList = () => {   
    const fetcher = MultiFetcher('productsize');   

    useEffect(() => {
        window.scrollTo(0, 0)
        fetcher.get({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteItem = async (id: number) => {
        fetcher.del(id).then(async (success) => {
            if (success === true) {
                await fetcher.get({})
                message.info(`ProductSize was deleted.`)
            }
        })
    }

    return (
        <>
            <PageTitle>ProductSizes</PageTitle>

            <Table
                loading={fetcher.isLoading}
                size='small'
                pagination={{ defaultPageSize: 12, size: 'default' }}
                rowKey={'id'}
                columns={[
                    {
                        title: 'Size in mm',
                        dataIndex: 'sizeInMm',
                        key: 'sizeInMm',
                        sorter: (a:any,b:any) => numberCompare(a.sizeInMm, b.sizeInMm),
                            ...ColumnTextFilter('name')
                    },
                    {
                        title: 'PartNumber Code',
                        dataIndex: 'partNumberCode',
                        key: 'partNumberCode',
                        sorter: (a:any,b:any) => a.partNumberCode?.localeCompare(b.partNumberCode),
                            ...ColumnTextFilter('name')
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        title: () => <TableRowButton buttontype='Add' url='/productsize/edit/' entity='ProductSize' />,
                        render: (record: any) =>
                            <div>
                                <Space>
                                    <TableRowButton buttontype='Edit' recordid={record.id} url={`/productsize/edit`} entity='ProductSize' />
                                    <TableRowButton buttontype='Delete' recordid={record.id} onClick={deleteItem} entity='ProductSize' hasconfirm />
                                </Space>
                            </div>
                    }

                ]}
                dataSource={fetcher.data} 
                style={{ paddingBottom: '10px'}} 
                className='table-striped-rows' />
        </>
    )
}