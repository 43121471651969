/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Table, Tooltip, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import React, { useContext, useEffect, useState } from 'react'
import { Phase } from 'components/_common/enums/Phase';
import { QuoteStatus } from 'components/_common/enums/QuoteStatus';
import { OrderView } from 'components/Pages/Orders/OrderView';
import { FormatDate } from 'components/_common/Formatters';
import { OuterModal } from 'components/_common/OuterModal';
import { TableRowButton } from 'components/_common/CustomButtons';
import { UserRole, UserState } from 'components/_common/UserState';
import { UserContext } from 'components/AppLayout';
import { OrderEditMode } from 'components/Pages/Orders/_common/OrderEditMode';
import { OrderDetails } from 'components/Pages/Orders/OrderDetails';

interface State {
    viewOrderId?: number
    showEditModal: boolean
    editOrderId?: number
    editMode: OrderEditMode
}

interface Props {
    onRefresh(): void
    timeStamp: Date
}
 
export const OrdersForApproval = (props: Props) => {
    const [state, setState] = useState<State>({
        viewOrderId: undefined,
        showEditModal: false,
        editOrderId: undefined,
        editMode: OrderEditMode.Edit,
    })

    const { isAuthorizedForRole } = useContext<UserState>(UserContext)

    const orderFetcher = MultiFetcher('order')
    const quoteActionsFetcher = MultiFetcher('quoteActions')

    const getOrdersAsync = async () => orderFetcher.get({ queryParams: `phaseId=${Phase.Quotation}&statusId=${QuoteStatus.Quoted}` })
    
    useEffect(() => {
        window.scrollTo(0, 0)
        getOrdersAsync()
    }, [props.timeStamp])

    const viewOrder = (orderId: number) => setState(prevState => ({ ...prevState, viewOrderId: orderId }))
    const reQuote = (id: number) => setState(prevState => ({ ...prevState, showEditModal: true, editMode: OrderEditMode.ReQuote, editOrderId: id }))

    const onApproveQuote = async (id: number, orderNumber: string, paymentTypeId: number) => {
        const data = {
            orderId: id,
            paymentTypeId: paymentTypeId
        }
        quoteActionsFetcher.post(data, 'ApproveQuote').then(async (success) => {
            if (success) {
                setState(prevState => ({ ...prevState, viewOrderId: undefined  }))
                props.onRefresh()
                message.info(`Order ${orderNumber} was approved.`)
            }
        })
    }

    const refreshAll = async () => {
        await getOrdersAsync() 
    }

    return (
        <>
            <Table
                loading={orderFetcher.isLoading}
                dataSource={orderFetcher.data}
                size='small'
                rowKey={'id'}
                showHeader={false}
                pagination={false}
                bordered={false}
                style={{ cursor: 'pointer'}}
                locale= {{ emptyText: 'No data'}}
                className='table-striped-rows' 
                columns={[
                    {
                        dataIndex: 'fullCustomerOrderNumber',
                        key: 'fullCustomerOrderNumber',
                        className: 'nowrap',
                        width: 125,
                        render: (value) => <Tooltip placement="topLeft" title={`Customer OrderNr: ${value}`}>{value}</Tooltip>,
                    },
                    {
                        dataIndex: 'description',
                        key: 'description',
                        ellipsis: { showTitle: false },
                        width: '50%',
                        render: (value) => <Tooltip placement="topLeft" title={`Description: ${value}`}>{value}</Tooltip>,
                    },
                    {
                        title: 'Supplier',
                        dataIndex: 'supplierName',
                        key: 'supplier',
                        responsive: ['xl'],
                        render: (value) => <Tooltip placement="topLeft" title={`Supplier: ${value}`}>{value}</Tooltip>,
                    },
                    {
                        title: 'Target Date',
                        dataIndex: 'targetDate',
                        key: 'targetDate',
                        className: 'nowrap',
                        responsive: ['xxl'],
                        render: (value) => <Tooltip placement="topLeft" title={`Target Date: ${FormatDate(value)}`}>{FormatDate(value)}</Tooltip>,
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        width: 65,
                        render: (record: any) => {
                            const statusId = Number.parseInt(record.quoteStatusId)
                            return <div>
                                        <Space>
                                            {
                                                (statusId === QuoteStatus.Quoted && isAuthorizedForRole(UserRole.CustomerAdmin)) &&
                                                    <TableRowButton buttontype='Edit' onClick={() => reQuote(record.id)} title="Edit for Re-Quote"/>
                                            }
                                            {
                                                statusId === QuoteStatus.Quoted && isAuthorizedForRole(UserRole.CustomerAdmin) &&
                                                    <TableRowButton buttontype='Approve' onClick={(e) => viewOrder(record.id)} entity='Quote' title='Approve this Quote'/>
                                            }
                                        </Space>
                                    </div>
                        }
                    }
                ]}
            />

            <OuterModal
                title="View Order Details" 
                open={state.viewOrderId !== undefined} 
                width='80vw'
                style={{ minWidth: 1000, maxWidth: '1440px'}}
                onClose={() => setState(prevState => ({ ...prevState, viewOrderId: undefined }))} 
                saveButtonProps={{  
                    buttontype: 'Approve',
                    title: 'Approve this Quote',
                    hasConfirm: true,
                    confirmMessage: 'Are you sure you want to Approve this Quote?',
                    disabled: false

                }}>
                    { state.viewOrderId && 
                        <div style={{width: '100%'}}>
                            <OrderView 
                                orderId={state.viewOrderId}
                                approveQuote={true}
                                onClose={() => setState(prevState => ({ ...prevState, viewOrderId: undefined }))} 
                                onApprove={onApproveQuote}/>
                        </div>
                    }
            </OuterModal>

            <OuterModal
                title={`${state.editMode} Order`}
                open={state.showEditModal} 
                width='80vw'
                style={{ minWidth: 1000, maxWidth: '1440px'}}
                onClose={() => setState(prevState => ({ ...prevState, showEditModal: false, editOrderId: undefined }))} >
                    <div style={{width: '100%'}}>
                        <OrderDetails
                            orderId={state.editOrderId}
                            editMode={state.editMode}
                            onClose={() => setState(prevState => ({ ...prevState, showEditModal: false, editOrderId: undefined }))} 
                            onSubmitted={refreshAll} />
                        </div>
            </OuterModal>
        </>
    )
}
