import React, { useContext, useEffect, useState } from 'react';
import { Col, Layout, Row, Space, Table, message } from 'antd';
import { MultiFetcher } from 'components/_common/MultiFetcher';
import { TableRowButton } from 'components/_common/CustomButtons';
import { PageTitle } from 'components/_common/PageTitle';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import { CategoryTreeMenu } from 'components/_common/CategoryTreeMenu';
import { ColumnTextFilter } from 'components/_common/ColumnTextFilter';
import { UserRole, UserState } from 'components/_common/UserState';
import { UserContext } from 'components/AppLayout';
import { ImportFiles } from './ImportFiles';
import { OuterModal } from 'components/_common/OuterModal';

interface State {
    filterCategoryId?: string
    showImportFilesModal: boolean
}

export const ProductList = () => {
    const { isAuthorizedForRole } = useContext<UserState>(UserContext)
    const [state, setState] = useState<State>({
        filterCategoryId: localStorage.getItem('selectedCategoryId') ?? undefined,
        showImportFilesModal: false
    });

    const productFetcher = MultiFetcher('product');   
    const categoryFetcher = MultiFetcher('productcategory')

    const fetchProductsAsync = async (categoryId: string | undefined) => {
        productFetcher.get({ queryParams: categoryId ? `categoryId=${categoryId}` : '' })
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        categoryFetcher.get({ actionName: 'getallwithproductcount' })
        fetchProductsAsync(state.filterCategoryId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCategoryChange = (value: string) => {
        fetchProductsAsync(value)
        setState(prevState => ({ ...prevState, filterCategoryId: value }));
        localStorage.setItem('selectedCategoryId', value);
    }

    const deleteItem = async (id: number) => {
        productFetcher.del(id).then(async (success) => {
            if (success === true) {
                await fetchProductsAsync(state.filterCategoryId)
                message.info(`Product was deleted.`)
            }
        })
    }

    const showImportFilesModal = (visible: boolean) => setState(prevState => ({ ...prevState, showImportFilesModal: visible }))

    return (
        <>
            <Row>
                <Col span={4}></Col>
                <Col span={16}><PageTitle>Products</PageTitle></Col>
                <Col span={4}>
                    { isAuthorizedForRole(UserRole.SuperAdmin) && 
                        <div style={{ position: 'absolute', bottom: 10, right: 8 }}>
                            <TableRowButton buttontype='Import' onClick={() => showImportFilesModal(true)} title='Import Files' />
                        </div>
                    }
                </Col>
            </Row>

            <Layout>
                <Sider width='20%' style={{ backgroundColor: '#F5F5F5' }}>
                    <CategoryTreeMenu 
                        categories={categoryFetcher.data} 
                        loading={categoryFetcher.isLoading} 
                        onChange={onCategoryChange}  />
                </Sider>
                <Content style={{ marginLeft: '20px', width: '80%'}}>
                  
                    <Table
                        loading={productFetcher.isLoading}
                        rowKey={'id'}
                        size='small'
                        pagination={{ defaultPageSize: 12, size: 'default' }}
                        columns={[
                            {
                                title: 'Category',
                                dataIndex: 'categoryName',
                                key: 'category',
                                className: state.filterCategoryId ? 'hide' : 'show'
                            },
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                                sorter: (a:any,b:any) => a.name.localeCompare(b.name),
                                ...ColumnTextFilter('name')
                            },
                            {
                                title: 'Description',
                                dataIndex: 'description',
                                key: 'description',
                                sorter: (a:any,b:any) => a.description?.localeCompare(b.description),
                                ...ColumnTextFilter('description')
                            },
                            {
                                title: 'ItemNumber',
                                dataIndex: 'itemNumber',
                                key: 'itemnumber',
                                sorter: (a:any,b:any) => a.itemnumber?.localeCompare(b.itemnumber),
                                ...ColumnTextFilter('itemnumber')
                            },
                            {
                                key: 'actions',
                                align: 'right',
                                title: () => <TableRowButton buttontype='Add' url='/product/edit/' entity='Product' />,
                                render: (record: any) =>
                                    <div>
                                        <Space>
                                            <TableRowButton buttontype='Edit' recordid={record.id} url={`/product/edit`} entity='Product' />
                                            <TableRowButton buttontype='Delete' recordid={record.id} onClick={deleteItem} entity='Product' hasconfirm />
                                        </Space>
                                    </div>
                            }

                        ]}
                        dataSource={productFetcher.data} 
                        style={{ paddingBottom: '10px'}} 
                        className='table-striped-rows' />
                </Content>
            </Layout>

            <OuterModal
                title="Import Files" 
                open={state.showImportFilesModal} 
                width={750} 
                onClose={() => showImportFilesModal(false)} 
                saveButtonProps={{ buttontype: 'Ok', style: { padding: 0, display: 'none' }}}
                closeButtonProps={{ buttontype: 'Close', style: { padding: 0, display: 'none' }}}>
                <ImportFiles 
                    onClose={() => showImportFilesModal(false)} />
            </OuterModal>

        </>
    )
}