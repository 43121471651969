import React, { useState } from 'react'
import { Space, Table, Tooltip } from 'antd'
import { TableRowButton } from 'components/_common/CustomButtons'
import { PageLoader } from 'components/_common/PageLoader'
import { Key } from 'antd/es/table/interface'
import _ from 'lodash'
import { AddMultipleOrderLines } from '../_common/AddMultipleOrderLines'
import { OrderLineDetails } from './OrderLineDetails'
import { InnerModal } from 'components/_common/InnerModal'
import { ColorDisplay } from 'components/_common/ColorDisplay'

interface Props {
    orderLines: any
    isLoading: boolean
    onChange(orderLines: []): void
}

interface State {
    expandedKeys?: Key[]
    selectedIndex?: number,
    selectedOrderLine: any,
    showOrderLineDetailsModal: boolean,
    showAddMultipleModal: boolean
}

export const OrderLines = (props:Props) => {
    //console.log(`orderLines: ${JSON.stringify(props.data)}`)
    const [state, setState] = useState<State>({
        expandedKeys: undefined,
        selectedIndex: undefined,
        selectedOrderLine: undefined,
        showOrderLineDetailsModal: false,
        showAddMultipleModal: false
    })

    //== Order Lines ==
    const showOrderLineDetailsModel = (visible: boolean) => { 
        setState(prevState => ({ ...prevState, showOrderLineDetailsModal: visible }))
    }

    const onAddOrderLine = () => {
        setState(prevState => ({ ...prevState, selectedIndex: undefined, selectedOrderLine: {} }))
        showOrderLineDetailsModel(true)
    }

    const onEditOrderLine = (index: number) => {
        const orderLine = props.orderLines[index]
        setState(prevState => ({ ...prevState, selectedIndex: index, selectedOrderLine: orderLine }))
        showOrderLineDetailsModel(true)
    }

    const onDeleteOrderLine = (index: number) => {
        const orderLines = props.orderLines.slice(0);
        orderLines.splice(index, 1)
        ResetLineNumbers(orderLines)
        props.onChange(orderLines)
    }

    //== Add Multiple ==
    const showAddMultipleModal= (visible: boolean) => {
        setState(prevState => ({ ...prevState, showAddMultipleModal: visible }))
    }

    const onAddMultipleSave = async (newOrderLines: any[]) => {
        //console.log(`value: ${JSON.stringify(value)}`)
        let orderLines = props.orderLines.slice(0)

        _.forEach(newOrderLines, newOrderLine => {
            addOrUpdateOrderLine(newOrderLine, orderLines)
        })

        ResetLineNumbers(orderLines)
        setState(prevState => ({ ...prevState, showAddMultipleModal: false }))
        props.onChange(orderLines)
    }

    const onSaveOrderLine = (orderLine: any) => {
        //console.log(`orderLine: ${JSON.stringify(orderLine)}`)

        let orderLines = props.orderLines.slice(0);

        if (state.selectedIndex !== undefined) {
            // Direct Update
            orderLines[state.selectedIndex] = orderLine
        } else {
            // Add or Update Quantity
            addOrUpdateOrderLine(orderLine, orderLines)
        }
        
        ResetLineNumbers(orderLines)
        props.onChange(orderLines)
        showOrderLineDetailsModel(false)
    }

    const addOrUpdateOrderLine = (orderLine: any, orderLines: any[]) => {
        const foundOrderLine = _.find(orderLines, p => p.productVariationId === orderLine.productVariationId)

        //console.log(`found Orderline: ${JSON.stringify(foundOrderLine)}`)
        if (foundOrderLine) {
            
            _.forEach(orderLine.orderLineDetails, (orderLineDetail: any) => {
                const foundOrderLineDetail = _.find(foundOrderLine.orderLineDetails, p => p.productColorId === orderLineDetail.productColorId)

                if (foundOrderLineDetail) {
                    foundOrderLineDetail.quantity += (orderLineDetail.quantity ?? 0)
                } else {
                    foundOrderLine.orderLineDetails.push(orderLineDetail)
                }
            })
            foundOrderLine.totalQuantity = _.sum(foundOrderLine.orderLineDetails.map((p:any) => p.quantity))
        } else {
            orderLine.lineNumber = 1
            orderLines.push(orderLine)
        }
    }

    const ResetLineNumbers = (orderLines: any) => {
        _.each(orderLines, (item: any, index: number) => {
            item.lineNumber = index + 1
        })

        return orderLines
    }
    
    return (
        <>
            <PageLoader loading={props.isLoading}>
                <Table
                    loading={props.isLoading}
                    rowKey={record => record.lineNumber ?? `color_${record.productColorId}`}
                    size='small'
                    pagination={false}
                    tableLayout='auto'
                    expandable={{
                        expandRowByClick: true,
                        childrenColumnName: 'orderLineDetails',
                        expandedRowKeys: state.expandedKeys,
                        columnWidth: 40,
                        onExpand(expanded, record) {
                            const keys = [] as Key[]
                            if (expanded) keys.push(record.lineNumber)
                            setState(prevState => ({ ...prevState, expandedKeys: keys }))
                        },
                    }}
                    columns={[
                        {
                            title: '#',
                            dataIndex: 'lineNumber',
                            key: 'linenumber',
                            align: 'right',
                            width: '60px',
                            className: 'nowrap'
                        },
                        {
                            title: 'Product',
                            dataIndex: ['productVariation', 'productName'],
                            key: 'product',
                            ellipsis: true,
                            width: '17%',
                        },
                        {
                            title: 'partNumber',
                            dataIndex: ['productVariation', 'partNumber'],
                            key: 'partNumber',
                            ellipsis: true,
                            responsive: ['md'],
                            width: '17%',
                        },
                        {
                            title: 'Drawing Number',
                            dataIndex: ['productVariation', 'drawingNumber'],
                            key: 'drawingNumber',
                            ellipsis: true,
                            responsive: ['sm'],
                            width: '17%',
                        },
                        {
                            title: 'Properties',
                            dataIndex: ['productVariation', 'properties'],
                            key: 'properties',
                            ellipsis: { showTitle: false },
                            render: (value) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
                            responsive: ['sm'],
                            width: '22%',
                        },
                        ...((state.expandedKeys && state.expandedKeys.length > 0) ?
                        [{
                            title: 'Color',
                            dataIndex: 'productColor',
                            key: 'color',
                            width: 50,
                            render: (value) => <ColorDisplay productColor={value} />
                        }] : []),
                        {
                            title: 'Qty',
                            key: 'quantity',
                            align: 'right',
                            width: 80,
                            render: (record: any) => ('totalQuantity' in record) ? record.totalQuantity : record.quantity
                        },
                        {
                            key: 'actions',
                            align: 'right',
                            width: 100,
                            title: () => 
                                <Space>
                                    <TableRowButton buttontype='Build' onClick={() => showAddMultipleModal(true)} title='Add Multiple OrderLines' />
                                    <TableRowButton buttontype='Add' onClick={onAddOrderLine} entity='OrderLine' />
                                </Space>,
                            render: (text, record:any, index: number) => !record.productVariationId || (
                                <div>
                                    <Space>
                                        <TableRowButton buttontype='Edit' recordid={index} onClick={onEditOrderLine} entity='OrderLine' />
                                        <TableRowButton buttontype='Delete' recordid={index} onClick={onDeleteOrderLine} entity='OrderLine' hasconfirm />
                                    </Space>
                                </div>
                            )
                        }
                    ]}
                    dataSource={props.orderLines}
                    rowClassName={(record: any) => (!record.productVariationId && record.quantity === 0 && 'hide') || ''}
                    style={{ paddingBottom: '10px'}} 
                    className='table-striped-rows' 
                />
            </PageLoader>

            <InnerModal
                title="OrderLine Details" 
                open={state.showOrderLineDetailsModal} 
                width={800} 
                onClose={() => showOrderLineDetailsModel(false)}
                saveButtonProps={{ buttontype: 'Ok', children: state.selectedOrderLine?.id ? 'Update': 'Add' }}>
                <OrderLineDetails 
                    orderLine={state.selectedOrderLine} 
                    onClose={() => showOrderLineDetailsModel(false)} 
                    onSave={onSaveOrderLine} />
            </InnerModal>

            <InnerModal
                title="Add Multiple OrderLines" 
                open={state.showAddMultipleModal} 
                width='70vw'
                style={{ maxWidth: '800px'}}
                onClose={() => showAddMultipleModal(false)} 
                saveButtonProps={{ buttontype: 'Build', children: 'Add' }}>
                <AddMultipleOrderLines
                    onClose={() => showAddMultipleModal(false)}
                    onAddMultiple={onAddMultipleSave} 
                    new />
            </InnerModal>
        </>
    )
}