import React, { useEffect, useState } from "react";
import { Image, Spin } from "antd";

interface State {
    blobAsText?: string
}

interface Props {
    blob: Blob
    style?: React.CSSProperties
}

export const ImageFromBlob = (props: Props) => {
    const [state, setState] = useState<State>({
        blobAsText: undefined
      });

    useEffect(() => {
        const blobToText = async (blob: Blob) => {
            var reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {        
                setState(prevState => ({ ...prevState, blobAsText: reader.result as string }));
            }
        }
        
        if (props.blob) {
            blobToText(props.blob)
        }

    }, [props.blob]); 

    return (
        state.blobAsText ?
            <Image src={state.blobAsText} fallback='/images/default.png' style={{ ...props.style, cursor: 'pointer' }} loading='lazy' />
            :
            <Spin />
    )
}
