import { Form, Input, Slider, message } from 'antd'
import { IsChangedOuterContext } from 'components/_common/OuterModal'
import { MultiFetcher } from 'components/_common/MultiFetcher'
import { PageLoader } from 'components/_common/PageLoader'

import React, { useContext, useEffect } from 'react'

interface Props {
    orderId: number
    onClose(): void
    onRefresh(): void
}

export const QualifiedDetails = (props: Props) => {
    const { setIsChanged, submitRef } = useContext(IsChangedOuterContext)
    const [form] = Form.useForm()

    const orderProgressFetcher = MultiFetcher('orderprogress')

    useEffect(() => {
        orderProgressFetcher.get({ id: props.orderId.toString() })   
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Submit = async (data: any) => {
        const submitAsync = async (data: any):Promise<number> => await orderProgressFetcher.put(data, undefined, 'UpdateQcQualified' )
        //console.log(`data: [${JSON.stringify(data)}]`)

        const requestModel = {
            orderId: props.orderId,
            progress: data.qcQualifiedProgress,
        }

        await submitAsync(requestModel).then((result) => {
            if (result > 0) {
                props.onClose()
                message.info(`QC Qualified progress for ${orderProgressFetcher.data.fullCustomerOrderNumber} was updated.`)
                props.onRefresh()
            }
        })
    }

    return (
        <PageLoader loading={orderProgressFetcher.isLoading}>
            <Form 
                initialValues={orderProgressFetcher.data} 
                form={form} 
                onFinish={Submit} 
                style={{ padding: '10px'}}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                onValuesChange={() => setIsChanged(true)}>
                <Form.Item name='id' hidden={true}><Input/></Form.Item>
                <Form.Item name='qcQualifiedProgress' label='Qualified' style={{marginBottom: 10}}>
                    <Slider
                        min={0}
                        max={100}
                        step={1}
                        tooltip={{ placement: 'bottom',  formatter: (value) => `${value}%`}} />
                </Form.Item>
                <button ref={submitRef} type="submit" className='hide' />
            </Form> 
        </PageLoader> 
    )
} 